/**
 * Course routes
 */
export default [
  {
    path: "course/:id",
    name: "course",
    component: () => import("./index"),
    meta: {
      visible: false,
    },
  },
  {
    path: "course/:slug/certificate",
    name: "course.certificate",
    component: () => import("./certificate"),
    meta: {
      visible: false,
    },
  },
];
