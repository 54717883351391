export default {
  canceled_order_message: "Este pedido teve uma troca ou cancelamento parcial",
  title: "Minhas vendas",
  unitValue: "Valor Unit:",
  today: "Hoje",
  yesterday: "Ontem",
  lasted_n_days: "Últimos {n} dias",
  period_alert:
    "O filtro por período é baseado na data de criação ou atualização do status dos pedidos.",
};
