import state from "./state";
import mutations from "./mutations";
import * as actions from "./actions/index";
import * as getters from "./getters/index";

/**
 * export all modules from store
 */
export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
