/**
 * Verifica se um objeto está vazio.
 * Um objeto é considerado vazio se:
 * - Não é um objeto ou é `null`/`undefined`
 * - Não possui nenhuma propriedade (chaves)
 * - Todas as propriedades são arrays vazias ou objetos vazios
 *
 * @param {Object} obj - O objeto a ser verificado.
 * @returns {boolean} - Retorna `true` se o objeto estiver vazio, caso contrário `false`.
 */
export const isEmptyObject = (obj) => {
  if (!obj || typeof obj !== 'object') return true;

  if (Object.keys(obj).length === 0) return true;

  // Verifica se todas as propriedades do objeto estão vazias
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      continue; // Se for uma array vazia, ignora
    } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      continue; // Se for um objeto vazio, ignora
    } else {
      return false; // Se encontrar qualquer valor que não esteja vazio
    }
  }

  return true; // Retorna true se todas as verificações acima passarem
};