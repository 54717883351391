/**
 Export all getters from state
*/

/**
 * this method will return total courses that's published to show 'Cursos' menu
 * @param courses_list
 * @returns {*[]|*}
 */
export const getCoursesTotal = ({ courses_list }) => {
  const courses = courses_list.filter((course) => course.status == "Published");
  return courses.length;
};

/**
 * @param courses
 * @returns {*[]|*}
 */
export const getCourses = ({ courses }) => {
  if (courses.data) {
    return courses.data;
  }

  return [];
};

/**
 * @param courses
 * @returns {*}
 */
export const getPaginationResource = ({ courses }) => {
  let pagination = Object.assign({ current_page: 1 }, courses);
  delete pagination.data;
  return pagination;
};

/**
 * @param courses_finished
 * @returns {*}
 */
export const getFinishedPaginationResource = ({ courses_finished }) => {
  let pagination = Object.assign({ current_page: 1 }, courses_finished);
  delete pagination.data;
  return pagination;
};

/**
 * @param courses_finished
 * @returns {*[]|*}
 */
export const getCoursesFinished = ({ courses_finished }) => {
  if (courses_finished.data) {
    return courses_finished.data;
  }

  return [];
};

/**
 * @param courses_in_process
 * @returns {*[]|*}
 */
export const getCoursesInProgress = ({ courses_in_process }) => {
  if (courses_in_process) {
    return courses_in_process;
  }

  return [];
};

/**
 * @param in_request
 */
export const getInRequest = ({ in_request }) => in_request;

/**
 * @param badges_changed
 */
export const getBadgeChanges = ({ badges_changed }) => badges_changed;

/**
 * @param categories
 */
export const getCategories = ({ categories }) => categories;

/**
 * @param filter
 */
export const getFilter = ({ filter }) => filter;

/**
 * @param tab_mode
 */
export const getTabMode = ({ tab_mode }) => tab_mode;

/**
 * badges list
 *
 * @param filter
 * @param getters
 * @returns {*[]}
 */
export const inFilterBadges = ({ filter }, getters) => {
  let badges = [];
  if (filter.value) {
    badges.push({
      type: "value",
      label: `Valor até ${filter.value} créditos`,
      value: filter.value,
    });
  } else if (filter.value === 0) {
    badges.push({
      type: "value",
      label: "Apenas Gratuitos",
      value: filter.value,
    });
  }

  if (filter.q) {
    badges.push({
      type: "q",
      label: filter.q.slice(0, 40) + "...",
      value: filter.q,
    });
  }
  if (filter.categories) {
    getters.getCategories.forEach((category) => {
      if (filter.categories.indexOf(category.id) !== -1) {
        badges.push({
          type: "categories",
          label: category.category,
          value: category.id,
        });
      }
    });
  }
  return badges;
};
