import { baseUrlAdmin, baseURL } from "@/shared/http/api";

/**
 * @me_store-services
 */
export default {
  /**
   * ecomm index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getEcomm: () => ({ method: "GET", url: "api/v1/ecomm-store" }),

  /**
   * ecomm model update
   *
   * @return {{method: string, url: string}}
   */
  putEcomm: () => ({ method: "PUT", url: "api/v1/ecomm-store" }),

  /**
   * route used to make upload of ecomm photos
   *
   * @return {{method: string, url: string}}
   */
  uploadEcommPhoto: () => ({ method: "POST", url: "api/v1/ecomm-store/photo" }),

  /**
   * route used to destroy a ecomm photo
   *
   * @return {{method: string, url: string}}
   */
  deleteEcommPhoto: () => ({
    method: "DELETE",
    url: "api/v1/ecomm-store/photo",
  }),

  /**
   * route used to destroy a ecomm photo
   *
   * @return {{method: string, url: string}}
   */
  params: () => ({
    method: "GET",
    url: "api/v1/parameters/byKeys",
    baseURL: baseUrlAdmin,
  }),

/**
   * @returns {{method: string, url: string, baseURL: string}}
   */
 getAssetsByPartnerStore: () => ({
  method: "GET",
  url: `api/v1/assets`,
  baseURL: baseURL,
}),
};
