import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param banners
   */
  [types.SET_AVAILABLE_BANNERS](state, banners) {
    state.available_banners = banners;
  },
  /**
   * @param state
   * @param banners
   */
  [types.SET_SELECTED_BANNERS](state, banners) {
    state.selected_banners = banners;
  },
  /**
   * @param state
   * @param lp
   */
  [types.SET_LP_PRODUCTS](state, lp) {
    state.lp_products = lp;
  },
};
