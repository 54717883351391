export default {
    modal: {
        title: 'Instale o Flip como APP',
        apple: {
            installation_steps: {
                one: 'Clique em',
                two: "Clique em 'Adicionar à tela inicial'"
            }
        },
        message: 'Este app não ocupa espaço no seu dispositivo.',
    }
}