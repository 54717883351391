import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param banks
   */
  [types.SET_BANKS](state, banks) {
    state.banks = banks;
  },

  /**
   * @param state
   * @param filter
   */
  [types.SET_BANK_FILTER](state, filter) {
    state.bank_filter_need = filter;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_DOCUMENTS](state, payload) {
    state.documents = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_DOC_TYPES](state, payload) {
    state.doc_types = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_PARAMS](state, payload) {
    state.params = Object.assign(payload, state.params);
  },

  [types.SET_PARTNER_CHALLENGES](state, payload) {
    state.partner_challenges = payload;
  },

  [types.SET_CHALLENGE_CODE](state, payload) {
    state.challenge_hash = payload;
  },
  [types.SET_CHALLENGE_EXT_SECRET](state, payload) {
    state.challenge_ext_secret = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_PARAMETERIZED_REGISTRATION_PARAM](state, payload) {
    state.parameterized_registration_param = payload;
  },
};
