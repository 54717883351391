/**
 * Map name for mutations
 * @types {string}
 */

export const SET_ORDERS = "SALES/SET_ORDERS";

export const SET_IN_REQUEST = "SALES/SET_IN_REQUEST";

export const SET_PERIOD = "SALES/PERIOD";

export const SET_ORDER_CYCLE = "SALES/SET_ORDER_CYCLE";
