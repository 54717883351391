import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_ECOMM](state, payload) {
    state.ecomm = payload;
  },

  /**
   * @param state
   * @param in_request
   */
  [types.SET_IN_REQUEST](state, in_request) {
    state.in_request = in_request;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_ME_STORE_PARAMS](state, payload) {
    state.me_store_params = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_PARTNER_ASSETS](state, payload) {
    state.assets = payload;
  },
};
