/**
 * all states from store
 */
export default {
  missions: [],
  subs_last: 1,
  not_subs_missions: [],
  not_subs_last: 1,
  finished_last: 1,
  mission: {},
  subs_questions: {},
  finished_missions: [],
  finished_details: {},
  public_missions: {},
  public_finished: {},
  show_public_mission: {},
  public_header: [],
  mission_terms: {},
  avaible_missions: [],
  temporary_upload_links: {},
};
