import { prefixes } from "./cachePrefixes";
import { getLocalUserId } from "@/modules/partner/storage";

const COOKIE_EXPIRATION_TIME = 15 * 60 * 1000;

// Utility function to set a cookie
const setCookie = (name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + COOKIE_EXPIRATION_TIME);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Utility function to get a cookie by name
const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Utility function to delete a cookie by name
const eraseCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

// Function to build the cache key
const buildCacheKey = (prefix) => {
  const cachePrefix = prefixes[prefix];
  return cachePrefix + getLocalUserId();
};

export const verifyCache = (prefix) => {
  return getCookie(buildCacheKey(prefix));
};

export const setCache = (prefix, data) => {
  const cacheKey = buildCacheKey(prefix);
  setCookie(cacheKey, JSON.stringify(data));
};

export const removeCache = (prefix) => {
  eraseCookie(buildCacheKey(prefix));
};

export const getCache = (prefix) => {
  try {
    const cacheItem = getCookie(buildCacheKey(prefix));
    return cacheItem ? JSON.parse(cacheItem) : null;
  } catch (error) {
    console.error("Error parsing JSON from cache:", error);
    return null;
  }
};

export const clearAllCache = () => {
  const prefixValues = Object.values(prefixes);
  document.cookie.split(";").forEach((cookie) => {
    const [key] = cookie.split("=");
    if (prefixValues.some((prefix) => key.trim().startsWith(prefix))) {
      eraseCookie(key.trim());
    }
  });
};

/**
 * Handles cache operations based on the provided prefix and dispatchers.
 *
 * @param {string} prefix - The cache prefix to use for the operation.
 * @param {object} dispatchers - An object containing callback functions for cache hits.
 * @param {boolean} [isCached=true] - Whether to check for cached data.
 * @return {Promise<object>} A promise resolving to an object containing cache hit status and data.
 */
export const cacheHandler = async (prefix, dispatchers, isCached = true) => {
  const cache = verifyCache(prefix);
  if (cache && isCached) {
    const parsedCache = getCache(prefix);
    if (parsedCache) {
      dispatchers.onCacheHit(parsedCache);
      return { isCacheHit: true, cache: parsedCache };
    }
  }
  return { isCacheHit: false };
};
