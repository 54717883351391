import { each } from "lodash";

/**
 * @param token
 * @returns {boolean}
 */
export const hasPartner = ({ partner }) => partner && partner.slug;

/**
 * @param partner
 * @returns {*}
 */
export const getPartner = ({ partner }) => partner;

/**
 * @param partner
 * @param getters
 * @param rootState
 * @param rootGetter
 * @return {*}
 */
export const getPartnerParentLogo = (
  { partner },
  getters,
  rootState,
  rootGetter
) => {
  function isValidImageURL(str) {
    if (typeof str !== "string") return false;
    return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
  }

  let url = partner.logo_foto_link || partner.logo || partner.logo_foto;

  if (isValidImageURL(url)) {
    return url;
  } else {
    return rootGetter["assents/logo"];
  }
};

/**
 * @param partner
 * @returns {*}
 */
export const getPartnerSlug = ({ partner }) => partner.slug;

/**
 * @param partner
 * @returns {*}
 */
export const isFlipnetCompany = ({ partner }) => partner.slug == "flipnet";

/**
 * @return {string}
 */
export const getPartnerSuffixParam = () => {
  return "parceiro";
};

/**
 * @param state
 * @param getters
 * @param rootState
 * @param rootGetter
 * @returns {*[]}
 */
export const getStoreLinks = (state, getters, rootState, rootGetter) => {
  const overview = rootGetter["home/getOverview"];

  if (Object.keys(overview).length) {
    // Usando flatMap para extrair todos os link_store
    return overview.linkStores.flatMap((store) => store.link_store || []);
  } else {
    return [];
  }
};

/**
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetter
 * @returns {{}}
 */
export const getStoreLink = (state, getters, rootState, rootGetter) => {
  const overview = rootGetter["home/getOverview"];

  if (Object.keys(overview).length) {
    // Usando flatMap como acima para pegar todos os links
    const allLinks = overview.linkStores.flatMap(
      (store) => store.link_store || []
    );
    // Retornar o primeiro link_store do array consolidado, ou um objeto vazio
    return allLinks[0] || {};
  } else {
    return {};
  }
};

/**
 * export getter to skeleton sign up params
 *
 * @param parameters
 * @return {number}
 */
export const getSlugIsReadyOnly = ({ parameters }) => {
  let status = false;
  if (parameters) {
    each(parameters, (p) => {
      if (p.var === "slug_readonly") {
        status = p.value.toString() === "true";
      }
    });
  }
  return status;
};

export const getPartnerBonusInviteHelp = ({ parameters }) => {
  let desc = "";
  if (parameters) {
    each(parameters, (p) => {
      if (p.var === "bonus_invite_help") {
        desc = p.value;
      }
    });
  }
  return desc;
};

export const getPartnerVoucherHelp = ({ parameters }) => {
  let desc = "";
  if (parameters) {
    each(parameters, (p) => {
      if (p.var === "voucher_helper") {
        desc = p.value;
      }
    });
  }
  return desc;
};

export const getParamsProductHelp = ({ parameters }) => {
  let desc = "";
  if (parameters) {
    each(parameters, (p) => {
      if (p.var === "product_help") {
        desc = p.value;
      }
    });
  }
  return desc;
};

export const getCustomerService = ({ customer_service }) =>
  customer_service || {};
