/**
 * all states from store
 */
export default {
    unfilledDocs: [],
    opened: {},
    openedInRequest: false,
    closed: {},
    closedInRequest: false,
    history: {},
    historyInRequest: false,
    pdfLink:"",
}