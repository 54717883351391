import { baseURL } from "@/shared/http/api";

export default {
  getFilters: () => ({
    method: "GET",
    url: "/api/v1/products/filterBarByParent/{parent_id}{params}",
    baseURL: baseURL,
    noPersist: true,
  }),

  getProducts: () => ({
    method: "POST",
    url: "/api/v1/products/queryProd",
    baseURL: baseURL,
    noPersist: true,
  }),

  getProductsByCompany: () => ({
    method: "POST",
    url: "/api/v1/products/queryProdCompany",
    baseURL: baseURL,
    noPersist: true,
  }),

  getProductsByCategory: () => ({
    method: "POST",
    url: "/api/v1/products/queryProdCat",
    baseURL: baseURL,
    noPersist: true,
  }),

  getPartnerLandingLink: () => ({
    method: "GET",
    url: "/api/v1/parameters/partnerLandingLink",
    baseURL: baseURL,
    noPersist: true,
  }),

  getAllCompanies: () => ({
    method: "GET",
    url: "/api/v1/partner/list-parent-registered?only_approved=1",
    baseURL: baseURL,
  }),
};
