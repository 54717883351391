/**
 Export all getters from state
*/

/**
 * @param state
 * @return {[]}
 */
export const getAvailableBanners = (state) => state.available_banners;

/**
 * @param state
 * @return {[]}
 */
export const getSelectedBanners = (state) => state.selected_banners;

/**
 * @param state
 * @return {[]}
 */
export const getLpProducts = (state) => state.lp_products;
