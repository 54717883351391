import services from "@/shared/http";
import * as types from "./mutation-types";

/**
 * set checklists
 *
 * @param commit
 * @param payload
 */
export const setChecklistAction = async ({ commit }, payload) => {
  await commit(types.SET_CHECKLISTS, payload);
};

/**
 * set checklists
 *
 * @param commit
 * @param payload
 */
export const setShowLatterChecklistAction = ({ commit }, payload) => {
  commit(types.SET_SHOW_LATTER_CHECKLIST, payload);
};

/**
 * Action to fetch checklists data.
 *
 * @param dispatch
 * @param {boolean} isCached - Whether to check for cached data.
 * @returns {Promise<unknown>}
 */
export const fetchChecklistsAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.components
      .list()
      .then((checklists) => {
        // Despachar as ações com os dados obtidos da API
        dispatch("setChecklistAction", checklists).finally(resolve); // Resolver a Promise após dispatchar as ações
      })
      .catch((error) => reject(error)); // Rejeitar a Promise se a requisição falhar
  });
};

export const checkUpChecklistAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.components
      .verify({ id: payload.id })
      .then(async () => {
        await dispatch("fetchChecklistsAction");
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const undoChecklistAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.components
      .undoVerify({ id: payload.id })
      .then(async () => {
        await dispatch("fetchChecklistsAction");
        resolve();
      })
      .catch((error) => reject(error));
  });
};
export const seenChecklistAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.components
      .seen({ id: payload.id })
      .then(async () => {
        await dispatch("fetchChecklistsAction");
        resolve();
      })
      .catch((error) => reject(error));
  });
};
