import * as urls from "./urls";
import * as notify from "./notify";
import * as networks from "./notify";
import * as utmHelper from "./utmHelper";
import * as validationHelper from "./validationHelper";
import * as stringManipulations from "./stringManipulations";
import * as numbersManipulation from "./numbersManipulation";
import * as customLayout from "./customLayoutHelper";

export default {
  urls,
  notify,
  networks,
  utmHelper,
  validationHelper,
  numbersManipulation,
  stringManipulations,
  customLayout,
};
