import * as types from "./mutation-types";

export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_INFLU_PRODUCTS_BY_CATEGORIES](state, payload) {
    state.productsByCategories = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_ALL_PRODUCTS](state, payload) {
    state.all_products = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_INFLU_DATA](state, payload) {
    state.influ_data = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_COMPANIE_DETAILS](state, payload) {
    state.company_details = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_SIDEBAR_STATUS](state, payload) {
    state.sidebar = payload;
  },

  [types.SET_LP_CENTRALIZED](state, payload) {
    state.lp_centralized.is_lp_centralized = payload;
  },

  [types.SET_COMPANIES_LINKS_LP_CENTRALIZED](state, payload) {
    state.lp_centralized.companies_links = payload;
  },

  [types.SET_INFLUENCER_VOUCHERS_LP_CENTRALIZED](state, payload) {
    state.lp_centralized.vouchers = payload;
  },

  [types.SET_ONLY_COMPANIES_WITH_PRODUCTS_LP_CENTRALIZED](state, payload) {
    state.lp_centralized.only_companies_with_products = payload;
  },
};
