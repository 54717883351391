import services from "@/shared/http";
import * as types from "./mutation-types";

/**
 * set categories action
 *
 * @param commit
 * @param payload
 */
export const setCategories = ({ commit }, payload) => {
  commit(types.SET_CATEGORIES, payload);
};
/**
 * set category filter action
 *
 * @param commit
 * @param payload
 */
export const setCategoryFilterId = ({ commit }, id) => {
  commit(types.SET_CATEGORY_FILTER_ID, id);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setPoints = ({ commit }, payload) => {
  commit(types.SET_POINTS, payload);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setCommission = ({ commit }, payload) => {
  commit(types.SET_COMMISSION, payload);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setSplitExceptions = ({ commit }, payload) => {
  commit(types.SET_SPLIT_EXCEPTIONS, payload);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setPartner = ({ commit }, payload) => {
  commit(types.SET_PARTNER, payload);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setSplitClusters = ({ commit }, payload) => {
  commit(types.SET_SPLIT_CLUSTERS, payload);
};

/**
 * set points action
 *
 * @param commit
 * @param payload
 */
export const setFaqQuestions = ({ commit }, payload) => {
  commit(types.SET_FAQ_QUESTIONS, payload);
};
/**
 * set total points action
 *
 * @param commit
 * @param total
 */
export const setTotalPoints = ({ commit }, total) => {
  commit(types.SET_TOTAL_POINTS, total);
};

/**
 * set points action on request
 *
 * @param commit
 * @param in_request
 */
export const setPointsInRequest = ({ commit }, in_request) => {
  commit(types.SET_POINTS_IN_REQUEST, in_request);
};

/**
 * set points action on request
 *
 * @param commit
 * @param payload
 */
export const setSplitClustersRules = ({ commit }, payload) => {
  commit(types.SET_SPLIT_CLUSTERS_RULES, payload);
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchCategory = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getCategories()
      .then((resources) => {
        dispatch("setCategories", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
/**
 * action to fetch total points
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchTotalPoints = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getTotalPoint()
      .then((resources) => {
        dispatch("setTotalPoints", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
/**
 * action to fetch total points
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchFaqQuestions = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getFaqQuestions()
      .then((resources) => {
        dispatch("setFaqQuestions", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchPoints = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    let filterId = payload && payload.filter_id ? payload.filter_id : null,
      query = { filter_id: filterId };

    dispatch("setCategoryFilterId", filterId);
    dispatch("setPointsInRequest", true);

    services.my_points
      .getPoints(query)
      .then((resources) => {
        dispatch("setPoints", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setPointsInRequest", false));
  });
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchCommission = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getCommission()
      .then((resources) => {
        dispatch("setCommission", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setCommission", false));
  });
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchSplitExceptions = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getSplitExceptions()
      .then((resources) => {
        dispatch("setSplitExceptions", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setSplitExceptions", false));
  });
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchPartner = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getPartner()
      .then((resources) => {
        dispatch("setPartner", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setPartner", false));
  });
};

/**
 * action to fetch category
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchSplitClusters = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getSplitClusters()
      .then((resources) => {
        dispatch("setSplitClusters", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setSplitClusters", false));
  });
};

/**
 * get split clusters rules to partners
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchSplitClustersRules = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.my_points
      .getClusterInfos(payload)
      .then(({ partner_cluster_rules }) => {
        dispatch("setSplitClustersRules", partner_cluster_rules.value);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
