// import { checkIfAppleSystem } from "../shared/helpers/checkIfIsAppleDevice";
// import {
//   useActions,
// } from "vuex-composition-helpers";

import dialog from "./dialog.vue";

// const { fetchDownloadApp } = useActions({
//   fetchDownloadApp: "pwa/fetchDownloadApp",
// });

export class flipPWA {
  static promptInstall = null;
  static slugCanInstallPwa = false;

  constructor($q, store, platforms, fetchDownloadApp) {
    this.events = ["mousemove", "touchmove"];
    this.$q = $q;
    this.store = store;
    this.platform = platforms;
    this.fetchDownloadApp = fetchDownloadApp;

  }

  isApple() {
    return (
      this.platform.is.ipad ||
      this.platform.is.iphone ||
      this.platform.is.ipod
    );
  }

  async appDownload() {
    try {
      const output = await this.fetchDownloadApp({ 
        system: "child",
        event: "APP_DOWNLOAD"
      });

      console.log('APP Download SUCCESS', output)
    } catch (error) {
      console.log('APP Download ERROR:', error)
    }
  }

  static siteOpenedByPwa() {
    return (
      window.navigator.standalone == true ||
      document.referrer.includes("android-app://") ||
      ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) =>
          window.matchMedia("(display-mode: " + displayMode + ")").matches
      )
    );
  }

  static checkStatusIconInLocalStorage() {
    const status = localStorage.getItem("not-display-pwa-icon");

    if (!status) return undefined;
    if (status == "false") return undefined;

    return true;
  }

  addListeners() {
    return new Promise((resolve, reject) => {
      if (!flipPWA.promptInstall) {
        reject();
        return;
      }

      this.events.forEach((event) => {
        window.addEventListener(event, () => {
          resolve();
        });
      });
    });
  }

  async install() {
    if (this.isApple() || flipPWA.promptInstall) {
      const position = this.isApple() && this.platform.is.mobile ? "bottom" : undefined;

      setTimeout(() => {
        this.$q.dialog({
          component: dialog,
          persistent: true,
          componentProps: {
            position: position,
            isAndroidOrWindows: position == "bottom" ? false : true,
            install: async () => {
              try {
                const { outcome } = await this.nativeInstalation();

                if (outcome == "accepted") {
                  this.store.commit("pwa/SET_INSTALLATION_STATUS", null);
                  this.appDownload()
                }
              } catch (error) {
                console.log("INSTALL PWA Error: ", error);
              }
            },
          }
        });
      }, 10);
    }
  }

  async nativeInstalation() {
    const output = await flipPWA.promptInstall.prompt();

    return output;
  }
}
