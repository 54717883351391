import { baseUrlMissions, baseURL } from "@/shared/http/api";

/**
 * @mission-services
 */

export default {
  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  getMissions: () => ({
    method: "GET",
    url: "/api/v1/partners/missions",
    baseURL: baseUrlMissions,
    noPersist: true,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  getMissionsFinished: () => ({
    method: "GET",
    url: "/api/v1/partners/missions",
    baseURL: baseUrlMissions,
    noPersist: true,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  getNotSubMissions: () => ({
    method: "GET",
    url: "/api/v1/partners/missions/not-subscribed",
    baseURL: baseUrlMissions,
    noPersist: true,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  createContent: () => ({
    method: "POST",
    url: "/api/v1/contents",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  showMission: () => ({
    method: "GET",
    url: "/api/v1/missions/{slug}",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  subscribe: () => ({
    method: "POST",
    url: "/api/v1/subscribe/{slug}",
    baseURL: baseUrlMissions,
  }),

  unsubscribe: () => ({
    method: "POST",
    url: "/api/v1/subscribe/unsubscribe/{id}",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  sendAnswers: () => ({
    method: "POST",
    url: "/api/v1/behaviors/participant/answer",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  finishedDetails: () => ({
    method: "GET",
    url: "/api/v1/missions/finished-details/{slug}/{id}",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  publicMissions: () => ({
    method: "GET",
    url: "/api/v1/missions/public?",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  finishedMissions: () => ({
    method: "GET",
    url: "/api/v1/missions/public?status=finished",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  showPublicMission: () => ({
    method: "GET",
    url: "/api/v1/missions/public/{slug}",
    baseURL: baseUrlMissions,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  showPublicHeader: () => ({
    method: "GET",
    url: "/api/v1/assets/public/list-by-company",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  showPublicHeaderCompany: () => ({
    method: "GET",
    url: "/api/v1/assets/public/list-by-company/{slug}?{target}",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   * @returns {{method: string, url: string, baseURL: string}}
   */
  getTermsByMission: () => ({
    method: "GET",
    url: "/api/v1/terms/byMission/{slug}",
    baseURL: baseUrlMissions,
  }),

  avaibleMissions: () => ({
    method: "GET",
    url: "/api/v1/missions/available",
    baseURL: baseUrlMissions,
  }),

  getTemporaryUploadLinks: () => ({
    method: "POST",
    url: "/api/v1/generate-temporary-upload-url",
    baseURL: baseURL,
  }),
};
