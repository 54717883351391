<template>
  <router-view></router-view>
</template>

<script setup>
// import store from './pwa/store/index.js';
import { flipPWA } from "./pwa/index.js";
import { useStore, useActions } from "vuex-composition-helpers";
import { Cookies, useQuasar, Platform } from "quasar";
import { getParams } from "./shared/helpers/getParams.js";

const store = useStore();
const $q = useQuasar();
const { fetchDownloadApp } = useActions({
  fetchDownloadApp: "pwa/fetchDownloadApp",
});

const pwa = new flipPWA($q, store, Platform);

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  flipPWA.promptInstall = event;
  store.commit("pwa/SET_INSTALLATION_STATUS", event);
});

if (getParams("pwa")) {
  // Checa se o FLIPNET foi aberto a partir do PWA
  store.commit("pwa/IS_PWA", true);

  if (pwa.isApple()) {
    if (!Cookies.has("pwa-installed")) {
      // Qnd o usuário abrir a 1ª vez o PWA pelo iphone, contabiliza o download
      fetchDownloadApp({
        system: "child",
        event: "APP_DOWNLOAD",
      })
        .then((success) => {
          console.log("APP Download SUCCESS:", success);
        })
        .catch((error) => {
          console.log("APP Download ERROR:", error);
        });
    }

    Cookies.set("pwa-installed", true, {
      // Esse cookie informa ao site que o PWA foi instalado
      expires: 7,
    });
  }
}
</script>
