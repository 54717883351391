import Cookies from "js-cookie";

/**
 * set of routes that can have areas to be accessed directly when redirecting
 */
const ROUTES_WITH_TABS = ["commission"];

/**
 * @returns {string}
 */
export const getLocalSlug = () => Cookies.get("slug");

/**
 * Deleting slug from localStorage
 */
export const deleteLocalSlug = () => Cookies.remove("slug");

/**
 * putting slug in localStorage
 * @param slug
 */
export const setLocalSlug = (slug) => Cookies.set("slug", slug, { expires: 7 });

/**
 * get user id in localStorage
 */
export const getLocalUserId = () => Cookies.get("user_id");

/**
 * Deleting user id from localStorage
 */

export const deleteLocalUserId = () => Cookies.remove("user_id");
/**
 * putting user id in localStorage
 * @param id
 */
export const setLocalUserId = (id) =>
  Cookies.set("user_id", id, { expires: 7 });

// *****************************************************************************

/**
 * get route redirect in localStorage
 */
export const getLoginRoute = () => Cookies.get("route-to-redirect");

/**
 * Deleting route redirect from localStorage
 */
export const deleteLoginRoute = () => Cookies.remove("route-to-redirect");

/**
 * putting route redirect in localStorage
 * @param routeName
 */
export const setLoginRoute = (routeName) => {
  if (routeName.includes(ROUTES_WITH_TABS)) {
    const routeData = routeName.split("#");
    Cookies.set("route-to-redirect", routeData.shift(), {
      expires: 7,
    });
    setCurrentTab(routeData.pop());
  } else {
    const routeData = routeName.split("#");

    Cookies.set("route-param-search", routeData.pop(), { expires: 7 });
    Cookies.set("route-to-redirect", routeName, { expires: 7 });
  }
};

/**
 * get current tab to app redirects in localStorage
 */
export const getCurrentTab = () => Cookies.get("currentTab");

/**
 * set current tab to app redirects in localStorage
 * @param tab
 */
export const setCurrentTab = (tab) =>
  Cookies.set("currentTab", tab, { expires: 7 });

/**
 * Deleting tab to app redirects from localStorage
 */
export const deleteCurrentTab = () => Cookies.remove("currentTab");
