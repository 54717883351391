/**
 * check if is local mode
 */
import Cookies from "js-cookie";

let hasLcmSet = new URL(location.href).searchParams.get("lcm");

if (hasLcmSet) {
  Cookies.remove("set");
  Cookies.set("lcm", hasLcmSet, { expires: 99999 });
}

//const mode = Cookies.get('lcm') === '1' ? 'local': (  process.env.NODE_ENV === 'development' ? 'development': 'production' )

const mode = process.env.VUE_APP_ENVIROMENT;
const urls = {
  mode,
  development: {
    host: "https://homapi.flip.net.br",
    baseURL: "https://homapi.flip.net.br/flip-child",
    baseUrlAdmin: "https://homapi.flip.net.br/flip-child",
    baseUrlReward: "https://homapi.flip.net.br/flip-reward",
    baseUrlOMS: "https://homapi.flip.net.br/flip-oms",
    baseUrlCC: "https://homapi.flip.net.br/flip-cc",
    baseUrlLearning: "https://homapi.flip.net.br/flip-learning",
    baseUrlMissions: "https://homapi.flip.net.br/missions",
    baseUrlXmailing: "https://homapi.flip.net.br/flip-xmailing",
    baseFrontUrl: "https://develop.dixc7qaj2h2xn.amplifyapp.com",
  },
  production: {
    host: "https://api.flip.net.br",
    baseURL: "https://api.flip.net.br/new-flip",
    baseUrlAdmin: "https://api.flip.net.br/new-flip",
    baseUrlReward: "https://api.flip.net.br/flip-rewards",
    baseUrlOMS: "https://api.flip.net.br/oms",
    baseUrlCC: "https://api.flip.net.br/cc",
    baseUrlLearning: "https://api.flip.net.br/flip-learning",
    baseUrlMissions: "https://api.flip.net.br/flip-missions",
    baseUrlXmailing: "https://api.flip.net.br/xmailing",
    baseFrontUrl: "https://go.flip.net.br",
  },
  local: {
    host: "http://localhost:8090/",
    baseURL: "http://localhost:8090",
    baseUrlAdmin: "http://localhost:8090",
    baseUrlOMS: "http://localhost:8091",
    baseUrlReward: "http://localhost:8068",
    baseUrlCC: "http://localhost:8110",
    baseUrlLearning: "http://localhost:8111",
    baseUrlMissions: "http://localhost:8050",
    baseUrlXmailing: "http://127.0.0.1:8070",
  },
};

export default {
  ...urls[mode],
  urls,
};
