/**
 * @sales-services
 */
import { baseUrlOMS } from "@/shared/http/api";

export default {
  /**
   * orders index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  orders: () => ({
    method: "POST",
    url: "v2/orders/getOrders",
    baseURL: baseUrlOMS,
    persistRequest: false,
  }),
};
