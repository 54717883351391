import StringMask from "string-mask/src/string-mask";

export function toPhone(value) {
  return new StringMask("+00 (00) 0000-0000").apply(value);
}

export function hashFnv32a(str, asString, seed) {
  /*jshint bitwise:false */
  let i,
    l,
    h_val = seed === undefined ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    h_val ^= str.charCodeAt(i);
    h_val +=
      (h_val << 1) + (h_val << 4) + (h_val << 7) + (h_val << 8) + (h_val << 24);
  }
  if (asString) {
    // Convert to 8 digit hex string
    return ("0000000" + (h_val >>> 0).toString(16)).substr(-8);
  }
  return h_val >>> 0;
}

export function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) return true;

  return false;
}

export function removeCharsCpf(cpf) {
  return cpf.replace(/[^\d]/g, "");
}

export function formatCNPJ(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}
