import Cookies from "js-cookie";

const CUSTOM_LAYOUT = "customLayout";

export const setCustomLayout = (layout) => {
  Cookies.set(CUSTOM_LAYOUT, layout, {
    expires: 365,
    path: "/",
    sameSite: "strict",
  });

  defineCSSVariables(layout);
};

export const setCustomLayoutLP = (layout) => {
  Cookies.set(CUSTOM_LAYOUT + "_LP", layout, {
    expires: 365,
    path: "/",
    sameSite: "strict",
  });

  defineCSSVariables(layout);
};

export const deleteCustomLayout = () => {
  Cookies.remove(CUSTOM_LAYOUT);
};

export const defineCSSVariables = (layout) => {
  document.documentElement.style.setProperty(
    "--primary-custom-color",
    layout.menu_itens_background
  );
};

export const getCustomLayout = (sufix = "") => {
  let data = Cookies.get(CUSTOM_LAYOUT + sufix);

  data = data
    ? JSON.parse(data)
    : {
        body_background: "#E0E0E0",
        navbar_background: "#FFFFFF",
        menu_top_background: "#2F3235",
        menu_itens_background: "#222222",
        menu_itens_text_color: "#FFFFFF",
      };

  defineCSSVariables(data);

  return data;
};

export const getCustomLayoutLP = (sufix = "") => {
  let data = Cookies.get(CUSTOM_LAYOUT + sufix);

  data = data
    ? JSON.parse(data)
    : {
        body_background: "#FFFFFF",
        navbar_background: "#FFFFFF",
        menu_top_background: "#2F3235",
        menu_itens_background: "#222222",
        menu_itens_text_color: "#FFFFFF",
      };

  defineCSSVariables(data);

  return data;
};
