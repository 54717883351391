export default {
  tabs: {
    notEnrolled: "Inicio",
    inProgress: "Meus Cursos",
    finished: "Finalizados",
  },

  card: {
    win: "Ganhe {value} créditos",
    code: "Cod: <b>{value}</b>",
  },

  finish_buttom: "Marcar como concluído",
  view_certificate: "Ver certificado",

  finish_message: {
    title: "Parabéns!",
    congratulations: "Parabéns!! Você concluiu o curso.",
    now_u_can: "Agora você pode obter o certificado do curso.",
    my_courses: "Meus cursos",
  },

  class: {
    next: "Próxima aula",
  },

  no_class_selected: "Nenhuma aula selecionada.",

  no_answers_to_question: "Sem respostas cadastradas para a pergunta.",

  download_certificate: "Baixar Certificado",

  finished_at: "Concluído em: { value }",

  quiz: {
    congratulations: "Parabéns!! Você passou.",
    message_1:
      "Agora é muito importante que você salve o questionário para concluir esta etapa.",
    message_2:
      "Você também pode revisar o questionário agora, observe caso tenha alguma anotação para você.",
    message_3:
      "Infelizmente você não obteve o numero de respostas corretas necessárias para prosseguir, revise o questionário e tente novamente.",

    min_points: "Pontuação mínima: <b>{ value }<b>",
    right_answers: "Respostas corretas: <b>{ value }<b>",
    wrong_answers: "Respostas incorretas: <b>{ value }<b>",
    total_answers: "Pontuação: <b>{ value }<b>",

    review: "Revisar perguntas",
    ok: "Entendi",
    save: "Salvar",
  },
  continue: "Continuar",

  get_certificate: "Obter certificado",
  cod: "COD: { value }",

  credit: " Créditos",
  free: "Assista agora",
  no_subtitle: "Sem subtítulo",
  category: "Categoria",
  certificate: "Certificado",

  only_free_courses: "Apenas cursos gratuitos",

  no_courses: "Nenhum curso aqui, por enquanto...  ",
};
