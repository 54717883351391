import services from "@/shared/http";
import * as types from "./mutation-types";
import {
  showFailMessages,
  showNotifyFailToResponses,
  notifyError,
} from "@/shared/helpers/notify";

/**
 * set reward info action
 *
 * @param commit
 * @param payload
 */
export const setRewardInfo = ({ commit }, payload) => {
  commit(types.SET_REWARD_INFO, payload);
};

export const setPrePaidCardInfo = ({ commit }, payload) => {
  commit(types.SET_PRE_PAID_CARD_INFO, payload);
};

/**
 * set suppliers action
 *
 * @param commit
 * @param payload
 */
export const setGiftCards = ({ commit }, payload) => {
  commit(types.SET_GIFT_CARDS, payload);
};

/**
 * set suppliers action
 *
 * @param commit
 * @param payload
 */
export const setGiftCardSuppliers = ({ commit }, payload) => {
  commit(types.SET_GIFT_CARD_SUPPLIERS, payload);
};

/**
 * set categories action
 *
 * @param commit
 * @param payload
 */
export const setGiftCardCategories = ({ commit }, payload) => {
  commit(types.SET_GIFT_CARD_CATEGORIES, payload);
};

/**
 * set departments action
 *
 * @param commit
 * @param payload
 */
export const setGiftCardDepartments = ({ commit }, payload) => {
  commit(types.SET_GIFT_CARD_DEPARTMENTS, payload);
};

/**
 * set reward closed action
 *
 * @param commit
 * @param payload
 */
export const setClosedRewards = ({ commit }, payload) => {
  commit(types.SET_CLOSED_REWARDS, payload);
};

/**
 * set rewards statuses
 *
 * @param commit
 * @param payload
 */
export const setAllRewards = ({ commit }, payload) => {
  commit(types.SET_ALL_REWARDS, payload);
};

/**
 * set rewards statuses
 *
 * @param commit
 * @param payload
 */
export const setRewardsStatuses = ({ commit }, payload) => {
  commit(types.SET_REWARDS_STATUSES, payload);
};

/**
 * set reward opened action
 *
 * @param commit
 * @param payload
 */
export const setOpenedRewards = ({ commit }, payload) => {
  commit(types.SET_OPENED_REWARDS, payload);
};

/**
 * set reward opened action
 *
 * @param commit
 * @param payload
 */
export const setLastOpenedRewards = ({ commit }, payload) => {
  commit(types.SET_LAST_OPENED_REWARDS, payload);
};

/**
 * set reward action on request
 *
 * @param commit
 * @param in_request
 */
export const setInRequest = ({ commit }, in_request) => {
  commit(types.SET_IN_REQUEST, in_request);
};

/**
 * set reward action on request
 *
 * @param commit
 * @param in_request
 */
export const setTradeInRequest = ({ commit }, in_request) => {
  commit(types.SET_TRADE_IN_REQUEST, in_request);
};

/**
 * @param commit
 * @param in_request
 */
export const setCanRescuePrePaidCard = ({ commit }, response) => {
  commit(types.SET_REWARD_ELEGIBLE, response);
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchReward = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);
    services.rescue_award
      .getRewardInfo()
      .then(async (resources) => {
        dispatch("setRewardInfo", resources);
        await dispatch("fetchRewardsOpened");
        await dispatch("fetchLastRewardsOpened");
        resolve();
      })
      .catch((error) => {
          if (error.message){
              notifyError(error.message, false);
          }
          if (error.errors){
              showFailMessages(error.errors);
              reject(error);
          }
      })
      .finally(() => dispatch("setInRequest", false));
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchRewardsClosed = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);
    services.rescue_award
      .rewardsClosed()
      .then((resources) => {
        dispatch("setClosedRewards", resources);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      })
      .finally(() => dispatch("setInRequest", false));
  });
};

/**
 * action to fetch rewards statuses
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchRewardsStatuses = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .rewardsStatuses()
      .then(async (resources) => {
        await dispatch("setRewardsStatuses", resources);
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch all rewards
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchAllRewards = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .allRewards()
      .then(async (resources) => {
        await dispatch("setAllRewards", resources);
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch filter rewards
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchFilterRewards = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .filterRewards({ status: payload })
      .then((resources) => {
        dispatch("setAllRewards", resources);
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch paginate rewards
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchFilterRewardsPaginate = ({ dispatch }, payload) => {
  let data = { status: payload[0], page: payload[1] };
  if (payload[0] == "") {
    delete data.status;
  }
  return new Promise((resolve, reject) => {
    services.rescue_award
      .filterRewardsPaginate(data)
      .then((resources) => {
        dispatch("setAllRewards", resources);
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchRewardsOpened = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .rewardsOpened()
      .then((resources) => {
        dispatch("setOpenedRewards", resources);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchLastRewardsOpened = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .rewardsOpened()
      .then((resources) => {
        let data =
          resources.length >= 1 ? [resources[resources.length - 1]] : [];
        dispatch("setLastOpenedRewards", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const rescueReward = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    dispatch("setTradeInRequest", true);
    services.rescue_award
      .rescueReward(payload)
      .then(() => {
        resolve();
        dispatch("fetchReward");
      })
      .catch((error) => {
        reject(error);
        showNotifyFailToResponses(error);
      })
      .finally(() => dispatch("setTradeInRequest", false));
  });
};

/**
 * action to fetch reward more items
 *
 * @param context
 * @param payload
 * @returns {*}
 */
export const fetchRewardOptionItems = (context, payload) => {
  return new Promise((resolve, reject) => {
    let query = payload.search ? { search: payload.search } : {},
      bindUrl = { option_id: payload.option_id };
    query.page = payload.page || 1;
    services.rescue_award
      .rewardMoreItems(query, bindUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param filters
 * @returns {*}
 */
export const fetchGiftCards = (
  { dispatch },
  options = {
    supplier_id: "",
    category_id: "",
    department_id: "",
    price_up_to: "",
    search: "",
  }
) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .giftCards(options)
      .then(({ data }) => {
        console.log(data);
        dispatch("setGiftCards", data);
        resolve();
      })
      .catch((error) => {
        notifyError();
        reject(error);
      });
    // .finally(() => dispatch("setGiftCards", false));
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchGiftCardSuppliers = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .giftCardSupplyers()
      .then((resources) => {
        dispatch("setGiftCardSuppliers", resources);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
    //.finally(() => dispatch("setGiftCardSuppliers", false));
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchGiftCardCategories = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .giftCardCategories()
      .then((resources) => {
        dispatch("setGiftCardCategories", resources);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
    // .finally(() => dispatch("setGiftCardCategories", false));
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchGiftCardDepartments = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .giftCardDepartments()
      .then((resources) => {
        dispatch("setGiftCardDepartments", resources);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
    //.finally(() => dispatch("setGiftCardDepartments", false));
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const prePaidCardRescue = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .prePaidCardRescue(payload)
      .then((response) => {
        dispatch("setPrePaidCardInfo", response);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * action to fetch reward
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchCanRescuePaidCard = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.rescue_award
      .canRescuePrePaidCard()
      .then((response) => {
        dispatch("setCanRescuePrePaidCard", response);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};
