import "./styles/quasar.scss";
import iconSet from "quasar/icon-set/material-icons";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import lang from "quasar/lang/pt-BR.js";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/notify/Notify.js';import LocalStorage from 'quasar/src/plugins/local-storage/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/session-storage/SessionStorage.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';import BottomSheet from 'quasar/src/plugins/bottom-sheet/BottomSheet.js';;
// To be used on app.use(Quasar, { ... })
export default {
  animations: "all",
  config: {},
  plugins: {
    Notify,
    LocalStorage,
    SessionStorage,
    Loading,
    Cookies,
    Dialog,
    BottomSheet
  },
  lang: lang,
  iconSet: iconSet,
};
