/**
 * all states from store
 */
export default {
  categories: [],
  points: [],
  commission: [],
  split_exceptions: [],
  cluster: [],
  partner: [],
  total_points: 0,
  category_filter_id: null,
  points_in_request: false,
  faq_questions: [],
  split_clusters_rules: {},
};
