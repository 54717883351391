import * as types from "./mutations-types";

export default {
  [types.SET_LOADING](state, payload) {
    state.loading_searching = payload;
  },

  [types.SET_LOADING_ADDING_ONE_PRODUCT](state, payload) {
    if (payload) {
      // Iniciar uma nova operação de carregamento
      state.loading_adding_one_product.activeOperationsCount++;
      state.loading_adding_one_product.status = true;
    } else {
      // Concluir uma operação de carregamento
      if (state.loading_adding_one_product.activeOperationsCount > 0) {
        state.loading_adding_one_product.activeOperationsCount--;
      }
      // Se não houver mais operações em andamento, definir como não carregando
      if (state.loading_adding_one_product.activeOperationsCount === 0) {
        state.loading_adding_one_product.status = false;
      }
    }
  },

  [types.SET_DRAWER](state, payload) {
    state.drawer = payload;
  },

  [types.SET_RESEARCHING_PRODUCT](state, payload) {
    state.researching_product = payload;
  },

  [types.SET_CURRENT_PAGE](state, payload) {
    state.current_page = payload;
  },

  [types.SET_FETCHING_FILTERS](state, payload) {
    state.is_fetching_filters = payload;
  },

  [types.SET_PRODUCTS](state, payload) {
    state.products = payload;
  },

  [types.SET_SELECTED_FAVORITOS_LIST](state, payload) {
    state.selected_favorites.products = payload;
  },

  [types.SET_CURRENT_PAGE_FAVORITE_PRODUCTS_SELECTED](state, payload) {
    state.selected_favorites.current_page = payload;
  },

  [types.SET_SELECTED_FAVORITE_PRODUCTS_PAGINATION](state, payload) {
    let pagination = Object.assign({ current_page: 1 }, payload);
    
    delete pagination.data;

    state.selected_favorites.pagination = pagination;
  },

  [types.SET_SELECTED_ALL_PRODUCTS](state, payload) {
    state.select_all_products = payload;
  },
  
  [types.SET_FILTERS](state, payload) {
    console.log('Filtros', payload)
    state.filters = payload;
  },

  [types.SET_PRICE_RANGE](state, payload) {
    state.filter_price_range = payload;
  },
  
  [types.SET_PARTNER_LANDING_LINK](state, payload) {
    state.partner_landing_link = payload;
  },

  [types.SET_SELECTED_FILTERS](state, payload) {
    state.selectedFilters = payload;
  },

  [types.SET_PARENTS_ID](state, payload) {
    state.parent_ids = payload;
  },

  [types.SET_INFLUENCER_ID](state, payload) {
    state.influencer_id = payload;
  },

  [types.SET_FILTER_LIKES](state, payload) {
    state.filter_likes = payload;
  },

  [types.SET_FILTER_KEYS](state, payload) {
    state.filter_keys = payload;
  },

  [types.SET_ORDER_BY](state, payload) {
    state.order_by = payload;
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    state.text_search = payload;
  },

  [types.SET_PAYLOAD_PRODUCTS](state, payload) {
    state.payloadProducts = payload;
  },

  [types.SET_IS_ADDING_PRODUCT_TO_FAVORITES](state, payload) {
    state.is_adding_product_to_favorites = payload;
  },

  [types.SET_ALL_COMPANIES_IN_CENTRALIZED](state, payload) {
    state.dashboard_centralized.all_companies = payload;
  },

  [types.SET_DASHBOARD_CENTRALIZED](state, payload) {
    state.dashboard_centralized.is_dashboard_centralized = payload;
  },

  [types.SET_COMPANIES_IDS_IN_CENTRALIZED](state, payload) {
    state.dashboard_centralized.only_companies_ids = payload;
  },

  [types.SET_PARENT_ID_FETCH_FILTERS](state, payload) {
    state.parent_id_to_fetch_filters = payload;
  },

  [types.SET_COMPANIES_FILTER_IN_CENTRALIZED](state, payload) {
    state.dashboard_centralized.companies_filter = payload;
  },

  [types.SET_REQUEST_CONFIG_IN_CENTRALIZED](state, payload) {
    state.selected_favorites.endpoint_type = payload;
  },

  [types.SET_LOADING_IN_SELECTED_FAVORITES_LIST](state, payload) {
    state.selected_favorites.is_loading = payload;
  },  

  [types.SET_COMPANIES_IDS_IN_SELECTED_FAVORITES_LIST](state, payload) {
    state.selected_favorites.parent_ids = payload
  },
}