export * from './lp-centralized';

export const getProducts = ({ productsByCategories }) => productsByCategories

export const getInfluData = ({ influ_data }) => {
    return influ_data.ecomm_customization || {};
};

export const getInfluEcomCustomization = ({ influ_data }) => {
    return influ_data || {};
};

export const getCompanieDetails = ({ company_details }) => {
    return company_details || {};
};

export const getSidebarStatus = ({ sidebar }) => sidebar

export const getAllProducts = ({ all_products }) => all_products