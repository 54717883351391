import i18n from "@/i18n/wrappers/i18n";
import moment from "moment";

const t = i18n.global.t;

export const formatDate = (date) => moment(date).format("DD/MM/YY");

export const formatDateWithHours = (date) =>
  moment(date).format("DD/MM/YY HH:mm");

export const formatFullDate = (date) => moment(date).format("DD/MM/YYYY");

export const formatPeriod = (period) => {
  const startDate = moment(),
    dataFormatType = "YYYY-MM-DD";

  const created_at_end = startDate.format(dataFormatType),
    created_at_begin = startDate
      .subtract(period, "days")
      .format(dataFormatType);

  return { created_at_end, created_at_begin };
};

export const formatPeriodByMonths = (period) => {
  const startDate = moment();
  const dateFormat = "YYYY-MM-DD";

  let created_at_begin, created_at_end;

  if (period === "current_month") {
    created_at_end = startDate.format(dateFormat);
    created_at_begin = startDate.startOf("month").format(dateFormat);
  } else if (period === "last_month") {
    created_at_begin = startDate
      .subtract(1, "month")
      .startOf("month")
      .format(dateFormat);
    created_at_end = moment(created_at_begin).endOf("month").format(dateFormat);
  } else {
    throw new Error(
      'Invalid period type. Supported values are "current_month" or "last_month".'
    );
  }

  return { created_at_end, created_at_begin };
};

export const americanFormat = (date) => moment(date).format('YYYY-MM-DD');

export const isDateValid = ( date, format ) => moment(date, format, true).isValid();

/**
 * Calcula a diferença em dias entre duas datas.
 *
 * @param {string} date1 - A primeira data no formato 'YYYY-MM-DD'.
 * @param {string} date2 - A segunda data no formato 'YYYY-MM-DD'.
 * @returns {number} - A diferença em dias entre as duas datas.
*/
export const calculateDaysBetweenDates = (date1, date2) => {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);
  return momentDate2.diff(momentDate1, 'days') + 1 // +1 para incluir o dia inicial
}

export const formatTimestamp = (date) =>
  moment(date).format("YYYY-MM-DD HH:mm:ss");

export function toMoney(value) {
  return parseFloat(value)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export function toMoneyLabel(value) {
  return `${t("global.moneyTypeLabel")} ${toMoney(value)}`;
}

export const validateRepeatedNumbers = (value) => {
  let validateRepeatedNumbers = /^(?!(\d)\1{3})\d{4}$/;
  return validateRepeatedNumbers.test(value);
};

export const calculateSum = (number, alternate) => {
  let sum = 0;
  for (let i = number.length - 1; i >= 0; i--) {
    let n = parseInt(number.charAt(i), 10);
    if (alternate) {
      n *= 2;
      if (n > 9) n = (n % 10) + 1;
    }
    sum += n;
    alternate = !alternate;
  }
  return sum;
};

export const validateLuhn = (numberMasked) => {
  let alternate = false;

  const number = numberMasked.replace(/\s/g, ""),
    sum = calculateSum(number, alternate);

  return sum % 10 === 0;
};
