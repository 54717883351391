export default {
  search_product: "Pesquise por um produto",
  search_custom:
    "Pesquise por um produto (Você pode usar a cor para facilitar a busca)",
  no_products_title: "Ops, sem produtos por aqui.... por enquanto",
  no_products_subtitle: "Em breve voltaremos com novidades, aguarde!",
  product_marked_as_favorite: "O produto foi adicionado aos favoritos",
  product_removed_from_favorite: "O produto foi removido dos favoritos",
  my_favorites: "Meus Favoritos",
  my_products: "Produtos",
  alert_label:
    "* Em caso de divergência de preços, é válido o preço do produto no momento em que o mesmo é adicionado ao carrinho.",
  no_price_avaible: "Preço indisponível",
};
