/**
 * Sales routes
 */
export default [
  {
    path: "sales",
    name: "sales",
    component: () => import("./index"),
    meta: {
      sort: 5,
      visible: true,
      label: "menu.sales",
      icon: "shopping_cart",
    },
  },
];
