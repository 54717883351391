import services from "@/shared/http";
import * as types from "./mutation-types";

/**
 * set products action
 *
 * @param commit
 * @param payload
 */
export const setStoreProducts = ({ commit }, payload) => {
  commit(types.SET_STORE_PRODUCTS, payload);
};

/**
 * set products action
 *
 * @param commit
 * @param payload
 */
export const setFavoriteProducts = ({ commit }, payload) => {
  commit(types.SET_FAVORITE_PRODUCTS, payload);
};

/**
 * set filter to store products
 *
 * @param commit
 * @param filter
 */
export const setStoreFilterValue = ({ commit }, { filter }) => {
  commit(types.SET_STORE_SEARCH_FILTER, filter);
};
/**
 * set filter to favorite products
 *
 * @param commit
 * @param filter
 */
export const setFavoriteFilterValue = ({ commit }, { filter }) => {
  commit(types.SET_FAVORITE_SEARCH_FILTER, filter);
};
/**
 * landing page link
 *
 * @param commit
 * @param filter
 */
export const setLandingPageLink = ({ commit }, link) => {
  commit(types.SET_LANDING_PAGE_LINK, link);
};

/**
 * action to fetch store products
 *
 * @param dispatch
 * @param state
 * @param payload
 * @returns {*}
 */
export const fetchStoreProductsAction = ({ dispatch, state }, payload) => {
  return new Promise((resolve, reject) => {
    let noPersist = false;
    if (payload && payload.noPersist) {
      noPersist = true;
      delete payload.noPersist;
    }
    services.products
      .storeProducts(
        { ...payload, ...{ q: state.store_search_filter } },
        null,
        noPersist ? { noPersist } : {}
      )
      .then((resources) => {
        dispatch("setStoreProducts", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch favorites products
 *
 * @param dispatch
 * @param state
 * @param payload
 * @returns {*}
 */
export const fetchFavoriteProductsAction = ({ dispatch, state }, payload) => {
  return new Promise((resolve, reject) => {
    let noPersist = false;
    if (payload && payload.noPersist) {
      noPersist = true;
      delete payload.noPersist;
    }
    services.products
      .favoriteProducts(
        { ...payload, ...{ q: state.favorite_search_filter } },
        null,
        noPersist ? { noPersist } : {}
      )
      .then((resources) => {
        dispatch("setFavoriteProducts", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * set product as favorite action
 *
 * @param dispatch
 * @param prod_id
 */
export const setProductsAsFavorite = async ({ dispatch }, { prod_ids }) => {
  try {
    // Chama o serviço para salvar os produtos favoritos
    await services.products.saveFavorite({ prod_ids: prod_ids });

    // Atualiza os produtos após salvar
    await dispatch("refreshProducts");
  } catch (error) {
    console.error('Erro ao salvar produtos como favoritos:', error);
    throw error
  }
};

/**
 * remove product from favorite action
 *
 * @param dispatch
 * @param prod_id
 */
export const removeProductsFromFavorite = ({ dispatch }, { prod_id }) => {
  new Promise((resolve, reject) => {
    services.products
      .removeFavorite({ prod_ids: [prod_id] })
      .then(() => {
        dispatch("refreshProducts").finally(() => resolve());
      })
      .catch(() => reject());
  });
};

/**
 * @param state
 * @param dispatch
 */
export const refreshProducts = async ({ state, dispatch }) => {
  let storeParams = { noPersist: true },
    favoriteParams = { noPersist: true };
  if (state.store_products) {
    storeParams.page = state.store_products.current_page;
  }
  if (state.favorites_products) {
    favoriteParams.page = state.favorites_products.current_page;
  }

  return Promise.all([
    dispatch("fetchFavoriteProductsAction", favoriteParams),
    dispatch("fetchStoreProductsAction", storeParams),
  ]);
};

/**
 * get split clusters rules to partners
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchVerifyLandingPageLink = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.products
      .landingPageLink(payload)
      .then(({ partner_landing_page_link }) => {
        dispatch("setLandingPageLink", partner_landing_page_link.value);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

// CHECKOUT ACTIONS
export const setCheckoutData = ({ commit }, payload) =>
  commit(types.SET_CHECKOUT_DATA, payload);

export const setCheckoutDataToEmpty = ({ commit }) =>
  commit(types.SET_CHECKOUT_DATA_TO_EMPTY);

export const setReplaceProduct = ({ commit }, payload) =>
  commit(types.SET_REPLACE_PRODUCT_QUANTITY, payload);

export const setCheckoutLink = ({ commit }, payload) =>
  commit(types.SET_CHECKOUT_LINK, payload);

export const setOpenCheckoutSide = ({ commit }, payload) =>
  commit(types.SET_OPEN_CHECKOUT_SIDE, payload);

export const setCheckoutParams = ({ commit }, payload) =>
  commit(types.SET_CHECKOUT_PARAMS, payload);

export const checkoutAction = ({ dispatch }, payload) =>
  dispatch("setCheckoutData", payload);

export const setHascheckout = ({ commit }, payload) =>
  commit(types.SET_HAS_CHECKOUT, payload);

export const clearCheckoutData = ({ dispatch }) =>
  dispatch("setCheckoutDataToEmpty");

export const replaceProductById = ({ dispatch }, payload) =>
  dispatch("setReplaceProduct", payload);

export const openCheckoutSide = ({ dispatch }) =>
  dispatch("setOpenCheckoutSide", true);

export const closeCheckoutSide = ({ dispatch }) =>
  dispatch("setOpenCheckoutSide", false);

export const fetchGenerateCheckoutLink = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.products
      .generateCheckoutLink(payload)
      .then((response) => {
        dispatch("setCheckoutLink", response.link);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const fetchCheckoutParam = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .params(payload)
      .then((response) => {
        dispatch("setCheckoutParams", response.checkout_rules.value);
        dispatch("setHascheckout", response.generate_checkout_link.value);
      })
      .catch((error) => reject(error));
  });
};
