/**
 * Partner validation file, as a kind of middleware,
 * to check if the slug exists and allow action
 */

import store from "@/store";

/**
 * @param slug
 * @returns {Promise<*>}
 */
export default async (slug) => {
  try {
    if (!store.getters["partner/hasPartner"]) {
      await store.dispatch("partner/fetchPartnerBySlugAction", { slug: slug });
      return store.getters["partner/hasPartner"];
    }

    return true;
  } catch (e) {
    return false;
  }
};
