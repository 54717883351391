import Cookies from "js-cookie";

const UTM_PARAMS = "utm_params";

export const generateUtmUrl = (obj) =>
  Cookies.set(UTM_PARAMS, JSON.stringify(obj), { expires: 60 });

export const getUtmParams = () => Cookies.get(UTM_PARAMS);

export const deleteUtmParams = () => {
  if (Cookies.get(UTM_PARAMS)) Cookies.remove(UTM_PARAMS);
};
