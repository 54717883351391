/**
 * @Home-services
 */
import {
  baseUrlCC,
  baseUrlOMS,
  baseUrlReward,
  baseURL,
} from "@/shared/http/api";

export default {
  overview: () => ({
    method: "GET",
    url: "api/v1/dash/overview",
    noPersist: true,
  }),

  companyScripts: () => ({
    method: "GET",
    url: "api/v1/scripts/parent-to-child",
  }),

  showUp: () => ({ method: "GET", url: "api/v1/user/show-up" }),

  showUpWatched: () => ({ method: "PATCH", url: "api/v1/user/watched/1" }),

  partnerAcceptedTotal: () => ({
    method: "POST",
    url: "api/v1/dash/totals/partners-accepted",
  }),

  orderTotals: () => ({
    method: "POST",
    url: "v2/dashboard/totals/orders",
    baseURL: baseUrlOMS,
  }),

  visitorsTotal: () => ({
    method: "POST",
    url: "v2/dashboard/totals/visitors",
    baseURL: baseUrlOMS,
  }),

  visitorsGraph: () => ({
    method: "POST",
    url: "v2/dashboard/visitors",
    baseURL: baseUrlOMS,
  }),

  commissionOpened: () => ({
    method: "POST",
    url: "api/v3/totals/commissions/open",
    baseURL: baseUrlCC,
  }),

  commissionPaid: () => ({
    method: "GET",
    url: "api/v3/totals/commissions/paid",
    baseURL: baseUrlCC,
  }),

  voucher: () => ({ method: "GET", url: "api/v1/dash/myvouchers" }),

  drives: () => ({
    method: "GET",
    url: "api/v1/parameters/byKeys",
    baseUrl: baseURL,
  }),

  invites: () => ({ method: "GET", url: "api/v1/dash/inviteXofY" }),

  ecomm: () => ({ method: "GET", url: "api/v1/ecomm/show" }),

  spread: () => ({ method: "GET", url: "api/v1/dash/spread" }),

  rewardTotalSales: () => ({
    method: "POST",
    url: "v2/dashboard/totals/ordersByStatus",
    baseURL: baseUrlOMS,
  }),

  rewardCreditsUnderAnalysis: () => ({
    method: "GET",
    url: "/api/v1/points/to-be-released",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardPointsReceived: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/points-received",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardPointsRescued: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/points-rescued",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardBalance: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/balance",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardPointsExpireSoon: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/points-expire-soon/{inNexMonths}",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardPointsExpired: () => ({
    method: "GET",
    url: "/api/v1/points/expired",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardRanking: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/ranking",
    baseURL: baseUrlReward,
  }),

  dashPointsSummary: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/points-summary",
    baseURL: baseUrlReward,
    noPersist: true,
  }),

  rewardPointsRange: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/points-per-day",
    baseURL: baseUrlReward,
  }),

  rewardLayout: () => ({
    method: "GET",
    url: "/api/v1/partner-dash/layout",
    baseURL: baseUrlReward,
  }),

  getVisitors: (data) => ({
    method: "POST",
    url: "/v2/dashboard/totals/visitors",
    data: data,
    baseURL: baseUrlOMS,
  }),

  getDocsPendingDash: () => ({
    method: "GET",
    url: "/api/v1/partner/data-check",
  }),

  getCompaniesList: () => ({
    method: "GET",
    url: "/api/v1/partner/list-parent-to-select",
    noPersist: true,
  }),

  getCompaniesRegisteredList: () => ({
    method: "GET",
    url: "/api/v1/partner/list-parent-registered",
    noPersist: true,
  }),

  getAlcadas: () => ({
    method: "GET",
    url: "/api/v1/alcadas",
  }),

  subscribePartner: () => ({
    method: "POST",
    url: "api/v1/partner/copy-partner",
    noPersist: true,
  }),

  subscribeWithoutValidation: () => ({
    method: "POST",
    url: "/api/v1/partner/account-association",
  }),

  subscribeWithValidation: () => ({
    method: "POST",
    url: "/api/v1/partner/confirm-account-association",
  }),

  getAllLinks: () => ({
    method: "GET",
    url: "/api/v1/dash/all-link-stores",
    noPersist: true,
  }),

  getAllVouchers: () => ({
    method: "GET",
    url: "/api/v1/dash/myvouchers",
    noPersist: true,
  }),

  getTotalOrders: (data) => ({
    method: "POST",
    url: "/v2/dashboard/totals/orders",
    data: data,
    baseURL: baseUrlOMS,
  }),

  getClustersAndShare: () => ({
    method: "GET",
    url: "/v1/reaching",
    baseURL: baseUrlOMS,
  }),

  getClusterPartnerInfo: () => ({
    method: "GET",
    url: "/v1/reaching/cluster-partner-info",
    baseURL: baseUrlOMS,
  }),

  notAssociatedAccService: () => ({
    method: "GET",
    url: "/api/v1/partner/not-associated-account",
    noPersist: true,
  }),
};
