import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  [types.SET_MISSIONS](state, payload) {
    state.missions = payload;
  },

  [types.SET_SUB_LAST](state, payload) {
    state.subs_last = payload;
  },

  [types.SET_NOT_SUB_MISSIONS](state, payload) {
    state.not_subs_missions = payload;
  },

  [types.SET_FINISHED_MISSIONS](state, payload) {
    state.finished_missions = payload;
  },

  [types.SET_NOT_SUB_LAST](state, payload) {
    state.not_subs_last = payload;
  },

  [types.SET_SHOW_MISSION](state, payload) {
    state.mission = payload;
  },

  [types.SET_SUBS_QUESTIONS](state, payload) {
    state.subs_questions = payload;
  },

  [types.SET_FINISHED_DETAILS](state, payload) {
    state.finished_details = payload;
  },

  [types.SET_PUBLIC_MISSIONS](state, payload) {
    state.public_missions = payload;
  },

  [types.SET_PUBLIC_FINISHED](state, payload) {
    state.public_finished = payload;
  },

  [types.SET_SHOW_PUBLIC](state, payload) {
    state.show_public_mission = payload;
  },

  [types.SET_PUBLIC_HEADER](state, payload) {
    state.public_header = payload;
  },

  [types.SET_MISSION_TERMS](state, payload) {
    state.mission_terms = payload;
  },

  [types.SET_AVAIBLE_MISSIONS](state, payload) {
    state.avaible_missions = payload;
  },

  [types.SET_TEMPORARY_UPLOAD_LINKS](state, payload) {
    state.temporary_upload_links = payload;
  },

  [types.SET_FINISHED_LAST](state, payload) {
    state.finished_last = payload;
  }
};
