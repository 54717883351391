import * as types from './mutation-types'

/**
 * Export all mutations
 */
export default {
    /**
     * @param {Object} state
     * @param {Object} payload
     */
    [types.SET_UNFILLED_DOC] (state, payload) {
        state.unfilledDocs = payload
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     */
    [types.SET_OPENED] (state, payload) {
        state.opened = payload
    },
    /**
     * @param {Object} state
     * @param {Boolean} inRequest
     */
    [types.SET_OPENED_IN_REQUEST] (state, inRequest){
        state.openedInRequest = inRequest
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     */
    [types.SET_CLOSED] (state, payload) {
        state.closed = payload
    },
    /**
     * @param {Object} state
     * @param {Boolean} inRequest
     */
    [types.SET_CLOSED_IN_REQUEST] (state, inRequest){
        state.closedInRequest = inRequest
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     */
    [types.SET_HISTORY] (state, payload) {
        state.history = payload
    },
    /**
     * @param {Object} state
     * @param {Boolean} inRequest
     */
    [types.SET_HISTORY_IN_REQUEST] (state, inRequest){
        state.historyInRequest = inRequest
    },
    [types.SET_PDF_LINK] (state, payload) {
        state.pdfLink = payload
    },
}