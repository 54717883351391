import { baseUrlLearning } from "@/shared/http/api";

/**
 * @sales-services
 */

export default {
  /**
   * enrolled index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  course: () => ({
    method: "GET",
    url: "/api/v1/class/content/{enroll_id}",
    baseURL: baseUrlLearning,
  }),

  /**
   * question index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  questions: () => ({
    method: "GET",
    url: "/api/v1/questionaries/{questionnaire_id}",
    baseURL: baseUrlLearning,
  }),

  /**
   * start class
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  startClass: () => ({
    method: "POST",
    url: "/api/v1/class/start",
    baseURL: baseUrlLearning,
  }),

  /**
   * set as finished
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  finishClass: () => ({
    method: "POST",
    url: "/api/v1/class/{class_id}/finish",
    baseURL: baseUrlLearning,
  }),

  /**
   * set as finished
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  classScoreTest: () => ({
    method: "POST",
    url: "api/v1/class/{class_id}/score",
    baseURL: baseUrlLearning,
  }),

  /**
   * set as finished
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  classScoreSave: () => ({
    method: "POST",
    url: "api/v1/class/{class_id}/score/save",
    baseURL: baseUrlLearning,
  }),

  /**
   * certificate
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  certificate: () => ({
    method: "GET",
    url: "api/v1/certificate/{slug}",
    baseURL: baseUrlLearning,
  }),
  /**
   * certificate
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  attachment: () => ({
    method: "GET",
    url: "api/v1/attachments/{id}/download",
    baseURL: baseUrlLearning,
  }),
};
