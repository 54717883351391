/**
 * @sales-services
 */
import { baseUrlLearning } from "@/shared/http/api";

export default {
  coursesList: () => ({
    method: "GET",
    url: `api/v1/courses?`,
    baseURL: baseUrlLearning,
  }),

  /**
   * enrolled index
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  notEnrolled: () => ({
    method: "GET",
    url: "api/v1/enroll/courses/notEnrolled",
    baseURL: baseUrlLearning,
  }),

  /**
   * enrolled index
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  inProgress: () => ({
    method: "GET",
    url: "api/v1/enroll/courses/inProgress",
    baseURL: baseUrlLearning,
  }),

  /**
   * enrolled index
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  finished: () => ({
    method: "GET",
    url: "api/v1/enroll/courses/finished",
    baseURL: baseUrlLearning,
  }),

  /**
   * categories
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  categories: () => ({
    method: "GET",
    url: "api/v1/category/",
    baseURL: baseUrlLearning,
  }),

  /**
   * categories
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  courses_show: () => ({
    method: "GET",
    url: "/api/v1/courses/{slug}",
    baseURL: baseUrlLearning,
  }),

  /**
   * categories
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  enroll: () => ({
    method: "POST",
    url: "/api/v1/enroll",
    baseURL: baseUrlLearning,
  }),
};
