<template>
  <q-dialog id="dialog-pwa" :position="position ? `${position}` : undefined" ref="dialogRef" 
  @hide="dialogClose" persistent>
    <q-card class="q-dialog-plugin">
      <dialogHeader :isAndroidOrWindows="isAndroidOrWindows" :dialogClose="dialogClose"/>
      <dialogContent :isAndroidOrWindows="isAndroidOrWindows" />
      <dialogFooter 
      :dialogCancel="onDialogCancel" 
      :isAndroidOrWindows="isAndroidOrWindows"
      :installPWA="installPWA"
      :statusDialogInLocalStorage="statusDialogInLocalStorage"
      />
    </q-card>
  </q-dialog>
</template>
<script setup>
import { useDialogPluginComponent } from 'quasar'
import { defineEmits, defineProps } from 'vue'
// import flipLogo from '@/assets/flip-icon.png';
// import appleConfigs from '@/assets/icons/apple-configs.png';
import dialogFooter from '@/pwa/components/dialogFooter.vue';
import dialogHeader from '@/pwa/components/dialogHeader.vue';
import dialogContent from '@/pwa/components/dialogContent.vue';

const props = defineProps({
  position: {
    type: String,
    required: true,
    default: undefined
  },
  isAndroidOrWindows: {
    type: Boolean,
    required: true
  },
  install: {
    type: Function,
    required: true
  },
})

defineEmits([
  ...useDialogPluginComponent.emits
])

const { dialogRef, onDialogCancel } = useDialogPluginComponent();

const statusDialogInLocalStorage = () => {
  const status = localStorage.getItem('not-display-pwa-icon')

  return status && status == 'true'
}

const dialogClose = () => {
  if ( statusDialogInLocalStorage() ) {
    const elementIconPwa = document.getElementById('floating-button-pwa')

    if ( elementIconPwa ) elementIconPwa.remove();
  }

  onDialogCancel()
}

const installPWA = () => {
  props.install()
  onDialogCancel()
}

</script>
<style>
  body.platform-ios #dialog-pwa .q-dialog__inner {
    margin: 1.5rem !important;
  }

  body.platform-ios #dialog-pwa .q-dialog__inner::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #fff;
    transform: translateX(-50%);
  }

  body.platform-ios #dialog-pwa .q-dialog__inner .q-card {
    border-radius: var(--flip-border-radius);
    box-shadow: none;
  }

  body.desktop #dialog-pwa .q-dialog__inner .q-card__actions,
  body.platform-android #dialog-pwa .q-dialog__inner .q-card__actions {
    padding: 16px;
  }
</style>