<template>
    <q-card-section class="row justify-center" v-if="!statusDialogInLocalStorage()">
        <q-checkbox v-model="pwaCheckbox" dense label="Não exibir novamente" color="dark" />
    </q-card-section>
    <q-card-actions align="right" v-if="isAndroidOrWindows">
        <q-btn no-caps unelevated class="no-shadow" label="Cancelar" @click="dialogCancel" />
        <q-btn no-caps @click="installPWA" :ripple="{ center: true }" class="flip-bg-secondary q-px-md text-white"
            label="Instalar" rounded />
    </q-card-actions>
</template>
<script setup>
import { defineProps, ref, watch } from 'vue'

const pwaCheckbox = ref(false);

watch(pwaCheckbox, (newValue) => {
    localStorage.setItem('not-display-pwa-icon', newValue)
})

defineProps({
    dialogCancel: {
        type: Function,
        required: true
    },
    isAndroidOrWindows: {
        type: Boolean,
        required: true
    },
    installPWA: {
        type: Function,
        required: true
    },
    statusDialogInLocalStorage: {
        type: Function,
        required: true
    }
})
</script>
<style scoped>
.q-card__actions button:last-child {
    padding: 0px 24px !important;
}
</style>