export default {
  notEnrolled: "Oportunidades",
  applications: "Inscrições",
  inProgress: "Em progresso",
  finished: "Finalizadas",
  return_site: "site Flip.net",

  send: "Enviar",
  next: "Próximo",
  back: "Voltar",

  content_feedback: "Seu último envio possui um feedback!",
  no_content: "Não há missões disponíveis no momento",
  no_content_sent: "Aguardando conteúdo",
  show_more: "Continuar lendo",
  show_more_mission: "detalhes da missão",
  period_subs_label: "Período de inscrição",
  period_label: "Período de vigência",
  period: "{d1} à {d2}",
  credit_if_complete: "{value} créditos + prêmios",
  credit_if_complete_progress: "Você poderá ganhar até {value} créditos",
  details: "Detalhes",
  paid_value_label: "Valor pago:",
  paid_value: "{value} créditos",
  reward_side_label: "Premiação",
  paid_value_ok: "Efetuado",
  paid_value_waiting: "Aguardando",
  rewards_ok: "Concluído",
  rewards_waiting: "Aguardando envio",
  shipping_details: "Detalhes do envio:",
  show_rewards: "Ver prêmios",

  credit_if_complete_show:
    " Você poderá ganhar até <b>{ value } créditos</b> durante essa missão!",
  credit_if_complete_with_reward:
    " Você poderá ganhar <b>{ value } créditos + recebidos</b> durante essa missão!",
  credit_if_complete_only_reward:
    " Você poderá ganhar <b>recebidos</b> durante esta missão!",
  only_reward: " Ganhe bonificação por suas vendas!",

  statuses: {
    wait_content: "Aguardando conteúdo",
  },

  progress_actions: {
    progress_tooltip: "Meu progresso",
    content_tooltip: "Enviar Conteúdo",
    exit_tooltip: "Sair da missão",
    download_signed_term: "Baixar termo assinado",
  },

  go_to_mission: "Logar e ir para a missão",
  exit_mission: "Abandonar missão",
  exit_mission_confirm:
    "Essa ação fará com que todo o seu progresso na missão seja excluído permanentemente<br/><br/>(a) Qualquer crédito ou prêmio que você eventualmente tenha direito serão cancelados (bonificações de vendas são mantidas)<br/>(b) Você não poderá se inscrever novamente nesta missão <br/>(c) Esta ação é irreversível <br/><br/>Você está certo(a) que deseja continuar?",
  show_finished_info:
    "Veja abaixo os seus conteúdos aprovados durante essa missão.",

  prerequisites_for_mission: "Pré-requisitos para a missão",
  public_modal:
    "Caso não atenda aos requisitos, sua inscrição não será aprovada.",
  public_modal_no_requirements:
    "A seguinte missão não possui pré requisitos, vá em frente e se inscreva!",

  step: "Passo {value}",
  my_progress: "Meu progresso",
  version: "Versão {value}",
  status: "{value}",
  progress_message:
    "O progresso da missão será atualizado sempre que o seu conteúdo for aprovado pelo administrador.",

  no_contents: "Nenhum conteúdo enviado para este passo.",
  send_content: "Enviar Conteúdo",

  required: "O campo {value} é obrigatório",

  content_info: "Visualizar informações do conteúdo",
  title_content: "<b>Título:</b> {value}",
  description_content: "<b>Descrição:</b> {value}",

  upload_mimes: {
    image: ".jpg, .jpeg, .png, .gif, .svg, .webp",
    video: ".mp4, .webm, .ogg",
  },

  file_rejected: "Arquivo muito grande, o tamanho máximo é de 2MB",

  tabs: {
    link: "Links/Texto",
    upload: "Upload",
    info: "Informações",
  },

  info: {
    title: "titulo",
    description: "descrição",
    hashtags: "hashtags",
    title_ph: "Qual será o titulo do seu conteúdo nas suas redes sociais ?",
    description_ph:
      "Qual será a descrição do seu conteúdo nas suas redes sociais ?",
    hashtags_ph: "Quais hashtags terá seu conteúdo nas suas redes sociais ?",
  },

  targets: {
    flip: "Uploads",
    social: "Drive",
  },

  validations: {
    data_error: "Os campos titulo e descrição são obrigatórios",
    file: "O arquivo é obrigatório",
    links: "Preencha todos os campos de links",
    success: "Conteúdo enviado com sucesso",
    error: "Preencha todos os campos correntamente.",
  },

  select_file: "Selecione um arquivo",
  upload_title: "Seu arquivo ficará em análise até que seja aprovado.",
  upload_text:
    "Você deve ser o proprietário dos direitos autorais do conteúdo enviado. Tenha cuidado para não violar os direitos autorais e a privacidade de terceiros.",

  hasnt_briefing: "Não há briefing disponível para essa missão.",
  has_requirements: "Pré requisitos",
  hasnt_requirements: "Sem pré requisitos",
  hasnt_level: "Missão não possui nível de dificuldade.",
  behaviors: "Comportamentos",
  hasnt_behaviors: "Sem comportamentos",

  mission_details: "Detalhes da missão",
  briefing: "Briefing",
  description: "Descrição",
  not_description: "Nenhuma descrição disponível.",
  reward: "Premiações",
  requirements: "Requisitos",
  level: "Nível da missão",
  requirement: " {value}",

  enroll: "Quero me inscrever",
  cant_enroll: "Você já está inscrito nessa missão",
  is_unsubscribed: "Você deixou essa missão",
  enroll_modal: "Inscrever-se",
  wanna_enroll: "Tenho interesse",
  enroll_not_started: "Inscrições não iniciadas",
  enroll_ended: "Inscrições encerradas",
  enroll_limit: "Limite de inscrições atingido",
  enroll_no_limit: "Sem limite de inscrições",

  subscribe: "Inscrever-se",
  to_progress: "Prosseguir com a inscrição",
  enroll_msg:
    "Para prosseguir com a inscrição, confirme seus dados abaixo. Em caso de pendências, atualize-os e tente novamente.",
  enroll_questions:
    "Para prosseguir com a inscrição, responda as perguntas abaixo. Essas informações serão usadas para avaliar se você está apto a participar dessa missão.",
  enroll_conditions:
    "Em uma missão poderão ser enviados materiais, produtos ou prêmios para seu endereço. Também poderão ser feitas comunicações por e-mail. Se os seus dados estiverem corretos, digite <b>CONFIRMAR</b>",
  confirm: "confirmar",

  my_data: "Meus dados",
  data_pending: "Dados pendentes",
  required_answer: "Resposta obrigatória",
  register_success: "Inscrição realizada com sucesso!",

  nome: "Nome",
  fone: "Telefone",
  email_principal: "E-mail",
  celular: "Celular",
  end_cep: "CEP",
  end_rua: "Rua",
  end_numero: "Número",
  end_bairro: "Bairro",
  end_complemento: "Complemento",
  end_cidade: "Cidade",
  end_uf: "Estado",

  data: {
    nome: " <b>Nome:</b> {value}",
    pj_fantasia: " <b>Nome fantasia:</b> {value}",
    email_principal: " <b>E-mail:</b> {value}",
    celular: " <b>Celular:</b> {value}",
    end_cep: " <b>CEP:</b> {value}",
    end_rua: " <b>Rua:</b> {value}",
    end_numero: " <b>Número:</b> {value}",
    end_bairro: " <b>Bairro:</b> {value}",
    end_complemento: " <b>Complemento:</b> {value}",
    end_cidade: " <b>Cidade:</b> {value}",
    end_uf: " <b>Estado:</b> {value}",
  },

  participants: "Participantes",
  control_participants: "Vagas disponíveis: {value1} <b>/ {value2}</b>",

  terms: "Li e aceito os <b>termos de uso</b>",
  terms_label: "Termos de uso",
  congrats_finished: "Parabéns, você concluiu essas missões!",

  // badge card
  expired_period: "Período expirado",
  end_today: "Finaliza hoje",
  end_in: "Restam {value} dia(s)",
  finish_date: "Data final para realizar o passo: {value}",

  download_signed_term: "Baixar termo assinado",
};
