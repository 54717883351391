import services from "@/shared/http";
import * as types from "./mutation-types";
import moment from "moment";
import {
  showNotifyFailToResponses,
  showFailMessages,
  showFailMessagesTeste,
} from "@/shared/helpers/notify";

/**
 * set assets action
 *
 * @param commit
 * @param payload
 */
export const setBanner = ({ commit }, payload) => {
  commit(types.SET_BANNER, payload);
};

/**
 * @param commit
 * @param dt
 */
export const setDateRange = ({ commit }, dt) => {
  commit(types.SET_DATE_RANGE, dt);
};

/**
 * @param commit
 * @param on
 */
export const setOnRequest = ({ commit }, on) => {
  commit(types.SET_ON_REQUEST, on);
};
/**
 * @param commit
 * @param ecomm
 */
export const setEcomm = ({ commit }, ecomm) => {
  commit(types.SET_ECOMM, ecomm);
};

/**
 * set show up action
 *
 * @param commit
 * @param payload
 */
export const setShowUp = ({ commit }, payload) => {
  commit(types.SET_SHOW_UP, payload);
};

/**
 * set show up action
 *
 * @param commit
 * @param payload
 */
export const setSpread = ({ commit }, payload) => {
  commit(types.SET_SPREAD, payload);
};

/**
 * set order totals
 *
 * @param commit
 * @param payload
 */
export const setOrderTotals = ({ commit }, payload) => {
  commit(types.SET_ORDER_TOTALS, payload);
};

/**
/**
 * set voucher
 *
 * @param commit
 * @param payload
 */
export const setVoucher = ({ commit }, payload) => {
  commit(types.SET_VOUCHER, payload);
};

/**
 * set commission opened
 *
 * @param commit
 * @param payload
 */
export const setCommissionOpened = ({ commit }, payload) => {
  commit(types.SET_COMMISSION_OPENED, payload);
};

/**
/**
 * set invites
 *
 * @param commit
 * @param payload
 */
export const setInvites = ({ commit }, payload) => {
  commit(types.SET_INVITES, payload);
};
/**
/**
 * set Drives
 *
 * @param commit
 * @param payload
 */
export const setDrives = ({ commit }, payload) => {
  commit(types.SET_DRIVES, payload);
};
/**
 * set partner accepted totals
 *
 * @param commit
 * @param payload
 */
export const setPartnerAccepted = ({ commit }, payload) => {
  commit(types.SET_PARTNER_ACCEPTED, payload);
};
/**
 * set visitor graph
 *
 * @param commit
 * @param payload
 */
export const setVisitorGraph = ({ commit }, payload) => {
  commit(types.SET_VISITOR_GRAPH, payload);
};

/**
 * set visitors totals
 *
 * @param commit
 * @param payload
 */
export const setVisitorTotals = ({ commit }, payload) => {
  commit(types.SET_VISITOR_TOTALS, payload);
};
/**
 * set show up watched action
 *
 * @param commit
 * @param payload
 */
export const setShowUpWatched = ({ commit }, payload) => {
  commit(types.SET_SHOW_UP_WATCHED, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setVisitorsData = ({ commit }, payload) => {
  commit(types.SET_VISITORS_DATA, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setDocsPending = ({ commit }, payload) => {
  commit(types.SET_DOC_PENDING_DASH, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setCompaniesList = ({ commit }, payload) => {
  commit(types.SET_COMPANIES_LIST, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setCompaniesRegisteredList = ({ commit }, payload) => {
  commit(types.SET_COMPANIES_REGISTERED_LIST, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setFlipThumb = ({ commit }, payload) => {
  commit(types.SET_FLIP_THUMB, payload);
};

export const setDashImage = ({ commit }, payload) => {
  commit(types.SET_DASH_IMAGE, payload);
};

export const setAllLinks = ({ commit }, payload) => {
  commit(types.SET_ALL_LINKS, payload);
};

export const setAllVouchers = ({ commit }, payload) => {
  commit(types.SET_ALL_VOUCHERS, payload);
};

export const setPointsSummary = ({ commit }, payload) => {
  commit(types.SET_POINTS_SUMARY, payload);
};

export const setInRequestDash = ({ commit }, payload) => {
  commit(types.SET_IN_REQUESTS_DASH, payload);
};

export const setClusterAndShare = ({ commit }, payload) => {
  commit(types.SET_CLUSTER_AND_SHARE, payload);
};

export const setClusterPartnerInfo = ({ commit }, payload) => {
  commit(types.SET_CLUSTER_PARTNER_INFO, payload);
};

export const fetchClusterPartnerInfo = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .getClusterPartnerInfo()
      .then((response) => {
        dispatch("setClusterPartnerInfo", response);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const fetchClusterAndShare = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .getClustersAndShare()
      .then((response) => {
        dispatch("setClusterAndShare", response.data);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const setAssociatedAccounts = ({ commit }, payload) => {
  commit(types.SET_NOT_ASSOCIATED_ACCS, payload);
};

/**
 * fetch show up
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchShowUp = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .showUp()
      .then(async (resource) => {
        await dispatch("setShowUp", resource);
        if (!resource.content) {
          await dispatch("setShowUpWatched", true);
        }
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * mark show up as watched
 *
 * @param dispatch
 * @returns {*}
 */
export const showUpWatched = async ({ dispatch }) => {
  return await services.home.showUpWatched().then(async () => {
    await dispatch("setShowUp", null);
    await dispatch("setShowUpWatched", true);
  });
};

/**
 * @param dispatch
 * @param date_range
 * @return {Promise<*>}
 */
export const changeDateRange = async ({ dispatch }, date_range) => {
  console.log("dateRanged", date_range);
  await dispatch("setDateRange", date_range);
  return dispatch("fetchAllWidgets");
};

// eslint-disable-next-line no-empty-pattern
export const fetchCompanyScripts = ({}) => {
  return new Promise((resolve, reject) => {
    services.home
      .companyScripts()
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 */
export const fetchAllWidgets = ({ dispatch, state }) => {
  if (state.date_range === null) return Promise.resolve();

  dispatch("setOnRequest", true);

  return Promise.all([dispatch("fetchRewardTotalSales")]).finally(() =>
    dispatch("setOnRequest", false)
  );
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchTotalOrders = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    let payloadToSend = {
      period: "all",
      status: ["apv", "fat"],
    };

    dispatch("setInRequestDash", {
      totalSales: true,
    });

    if (payload) {
      payloadToSend = {
        ...payloadToSend,
        // O filtro para POST envia o filtro como Objeto
        filter: {
          company_id: Object.values(payload)[0],
        },
      };
    }

    services.home
      .orderTotals(payloadToSend)
      .then(async (resource) => {
        await dispatch("setOrderTotals", resource.data);
        return resolve();
      })
      .catch((err) => reject(err))
      .finally(() => dispatch("setInRequestDash", { totalSales: false }));
  });
};

/**
 * @param dispatch
 * @param state
 * @return {*}
 */
export const fetchTotalVisitors = ({ dispatch, state }) => {
  return new Promise((resolve, reject) => {
    services.home
      .visitorsTotal({
        range: {
          begin: state.date_range.start,
          end: state.date_range.end,
        },
      })
      .then(async (resource) => {
        await dispatch("setVisitorTotals", resource.data);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchGraphVisitor = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .visitorsGraph({
        range: {
          begin: moment().subtract(14, "days").format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
      })
      .then(async (resource) => {
        await dispatch("setVisitorGraph", resource.data);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @param state
 * @return {*}
 */
export const fetchTotalPartnerAccepted = ({ dispatch, state }) => {
  return new Promise((resolve, reject) => {
    services.home
      .visitorsGraph({
        range: {
          begin: state.date_range.start,
          end: state.date_range.end,
        },
      })
      .then(async (resource) => {
        await dispatch("setPartnerAccepted", resource.data);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchCommissionOpened = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .commissionOpened()
      .then(async (resource) => {
        await dispatch("setCommissionOpened", resource.data);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchVoucher = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .voucher()
      .then(async (resource) => {
        await dispatch("setVoucher", resource[0]);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchInvites = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .invites()
      .then(async (resource) => {
        await dispatch("setInvites", resource.data);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchDrives = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .drives(payload)
      .then((output) => {
        return resolve(output);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchEcomm = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .ecomm()
      .then(async (resource) => {
        await dispatch("setEcomm", resource);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchSpread = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .spread()
      .then(async (resource) => {
        await dispatch("setSpread", resource);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};

export const fetchRewardTotalSales = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .rewardTotalSales({ ...payload })
      .then(async (resource) => {
        commit(types.SET_TOTAL_SALES, resource);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchRewardCreditsUnderAnalysis = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .rewardCreditsUnderAnalysis({ ...payload })
      .then(async (resource) => {
        commit(types.SET_CREDITS_UNDER_ANALYSIS, resource);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchWidgetsServices = ({ dispatch }, payload) => {
  const payloadGet = payload ? payload.getServices : {};
  const payloadPost = payload ? payload.postServices : {};

  return Promise.all([
    dispatch(
      "fetchPointsSummary",
      {
        payload: payloadGet || {},
        visibility: false,
      } || {}
    ),
    dispatch("fetchRewardTotalSales", payloadPost || {}),
  ]);
};

export const fetchRewardBalance = ({ commit }) => {
  return new Promise((resolve, reject) => {
    services.home
      .rewardBalance()
      .then(async (resource) => {
        commit(types.SET_POINTS_BALANCE, resource);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchRewardRanking = ({ commit }) => {
  return new Promise((resolve, reject) => {
    services.home
      .rewardRanking()
      .then(async (resource) => {
        commit(types.SET_POINTS_RANKING, resource);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchRewardPointsRange = ({ commit }) => {
  return new Promise((resolve, reject) => {
    let range = {
      d1: "2022-01-01",
      d2: "2023-01-31",
    };
    services.home
      .rewardPointsRange(range)
      .then(async (resource) => {
        commit(types.SET_POINTS_RANGE, resource);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchRewardLayout = ({ commit }) => {
  return new Promise((resolve, reject) => {
    services.home
      .rewardLayout()
      .then(async (resource) => {
        commit(types.SET_DASHBOARD_LAYOUT, resource);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * Para o dashboard centralizado visibility pode alterar a visibilidade, mas para o dashboard das marcas deve
 * sempre vir com visibility = false, para que sempre chame o serviço
 */
export const fetchPointsSummary = ({ dispatch }, { payload, visibility }) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequestDash", {
      pointsSummary: true,
    });

    if (!visibility)
      services.home
        .dashPointsSummary({ ...payload })
        .then((data) => {
          dispatch("setPointsSummary", data);
          resolve();
        })
        .catch((error) => {
          showNotifyFailToResponses(error);
          reject(error);
        })
        .finally(() => {
          dispatch("setInRequestDash", {
            pointsSummary: false,
          });
        });
    else {
      resolve();
    }
  });
};

/**
 * @param {*} param0
 * @param {*} payload
 * @returns
 */
export const fetchVisitors = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .getVisitors(payload)
      .then(async (resource) => {
        dispatch("setVisitorsData", resource.data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchBanner = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .getAssetsByPartnerStore(payload)
      .then(({ data }) => {
        dispatch("setBanner", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchDashImage = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .getAssetsByPartnerStore(payload)
      .then(({ data }) => {
        dispatch("setDashImage", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchFlipThumb = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .showPublicHeader(payload)
      .then(({ data }) => {
        dispatch("setFlipThumb", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchDocsPendingDash = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.home
      .getDocsPendingDash()
      .then((data) => {
        dispatch("setDocsPending", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchCompaniesRegisteredList = async ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequestDash", {
      companies: true,
    });

    services.home
      .getCompaniesRegisteredList()
      .then((data) => {
        dispatch("setCompaniesRegisteredList", data);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      })
      .finally(() => {
        dispatch("setInRequestDash", {
          companies: false,
        });
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchSubscribePartner = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .subscribePartner(payload)
      .then(() => resolve())
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchAssociateWithoutValidation = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .subscribeWithoutValidation(payload)
      .then(() => resolve())
      .catch((error) => {
        showFailMessagesTeste(error);
        reject(error);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchAssociateWithValidation = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .subscribeWithValidation(payload)
      .then(() => resolve())
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

/**
 * UNIFIED SERVICES - dashboard centralizado
 */

export const handleDashboardServices = ({ dispatch }, filter) => {
  dispatch("fetchOverview", filter);
  dispatch("fetchTotalOrders", filter);
};

/**
 * UNIFIED SERVICES
 */
export const setOverview = ({ commit }, payload) => {
  commit(types.SET_OVERVIEW, payload);
};

export const fetchOverview = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.home
      .overview(payload ? payload : {})
      .then((response) => {
        dispatch("setOverview", response);
        resolve(response);
        dispatch("setInRequestDash", {
          companies: false,
          points: false,
        });
      })
      .catch((error) => reject(error));
  });
};
