<template>
  <q-card-section class="q-pt-none" v-if="!isAndroidOrWindows">
    <p>
      <q-list bordered padding>
        <q-item-label header>Como instalar?</q-item-label>

        <q-item>
          <q-item-section avatar>
            1º
          </q-item-section>
          <q-item-section>
            <div>{{ $t("pwa.modal.apple.installation_steps.one") }}</div>
            <img :src="appleConfigs" width="24px" />
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            2º
          </q-item-section>
          <q-item-section>
            <div>{{ $t("pwa.modal.apple.installation_steps.two") }}</div>
          </q-item-section>
        </q-item>
      </q-list>
    </p>
  </q-card-section>
</template>
<script setup>
import { defineProps } from 'vue'

import appleConfigs from '@/assets/icons/apple-configs.png';

defineProps({
  isAndroidOrWindows: {
    type: Boolean,
    required: true
  }
})
</script>
<style scoped>
.q-list .q-item .q-item__section:first-child {
  min-width: fit-content;
}

.q-list .q-item .q-item__section:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .4rem;
}

.q-list .q-item .q-item__section--side {
  padding-right: 8px;
}
</style>