export default {
  social: {
    telegram_group: "fab fa-telegram",
    whatsapp_group: "fab fa-whatsapp",
  },

  qrcode_my_store: "Qrcode da minha loja",
  open_on_new_window: "Abrir em nova guia",
  share: "Compartilhar em",
};
