/**
 * all states from store
 */
export default {
  courses_list: [],
  courses: {},
  courses_in_process: {},
  courses_finished: {},
  categories: [],
  in_request: false,
  badges_changed: 1,
  tab_mode: "notEnrolled",
  filter: {
    q: null,
    value: null,
    categories: null,
  },
};
