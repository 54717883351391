import { removeCharsCpf } from "@/shared/helpers/stringManipulations";
import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

/**
 * get component name by stepper value
 * @param {Number} stepperValue
 * @returns {String}
 */
export const getComponentName = (stepperValue) => {
  const formSteps = [
    "FormStepOne",
    "FormStepTwo",
    "FormStepThree",
    "FormStepFour",
    "FormStepFive",
  ];

  return formSteps[stepperValue - 1];
};

/**
 * inputs to use on sign up step one by company type
 * @param {*} type
 */
export const signUpStepOneConfig = (type /* isSNRequired*/) => {
  const conf =
    type === "pf"
      ? [
          "complete_name",
          "document_cpf",
          //isSNRequired == "0" ? "instagram" : "instagram_req",
          "celular",
        ]
      : [
          "fantasy_name",
          "name_default_contact",
          "document_pj",
          "document_cpf_contact",
          "celular_pj",
          // isSNRequired == "0" ? "instagram" : "instagram_req",
        ];

  return conf;
};

/**
 * initial values to use on sign up step one by company type
 * @param {*} route
 */
export const getInitialValues = (route, oldData = null) => {
  if (oldData) return oldData;

  return {
    celular: "",
    instagram: "",
    document_pj: "",
    fantasy_name: "",
    document_cpf: "",
    document_cpf_contact: "",
    complete_name: route || "",
    name_default_contact: route || "",
    instagram_req: "",
  };
};

export const removeAllOldData = () => {
  localStorage.removeItem("signUpStepOne");
  localStorage.removeItem("signUpStepTwo");
  localStorage.removeItem("signUpStepThree");
  localStorage.removeItem("signUpStepFour");
};

export const getCelularField = (formData) => {
  const celularField =
    formData.type_person === "pf" ? formData.celular : formData.celular_pj;

  return celularField.length ? celularField : null;
};

export const verifyIfIsLink = (value) => {
  const regex = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+[/#?]?.*$/;
  return regex.test(value);
};

export const verifyIfIsInstagram = (value) => {
  const regex = /^[a-zA-Z0-9_.]*$/;
  return regex.test(value);
};

export const validateMainNetwork = (value, type) => {
  if (!value) return !!value || t("forms.validation.required");
  if (type.value === "instagram")
    return verifyIfIsInstagram(value) || t("forms.validation.is_not_instagram");
  if (type.value === "site")
    return verifyIfIsLink(value) || t("forms.validation.is_not_link");
};

export const getNetworksConfig = (network, type) => {
  const prefix = {
    facebook: "facebook.com/",
    instagram: "@",
    linkedin: "linkedin.com/in/",
    twitter: "twitter.com/",
    youtube: "youtube.com/",
    whatsapp: "+55 ",
    tiktok: "tiktok.com/@",
  };

  const masks = {
    whatsapp: "(##) #####-####",
  };

  const typeField = {
    whatsapp: "text",
    site: "url",
  };

  if (type == "mask") return network ? masks[network.value] : undefined;

  if (type == "type") return network ? typeField[network.value] : undefined;

  return network ? prefix[network.value] : undefined;
};

/**
 * generate payload to send on sign up service
 * @param {*} recaptcha
 * @param {*} formData
 * @param {*} socialNetworks
 * @param {*} parentSlug
 * @param {*} indicated_by
 * @returns
 */
export const generateSignUpPayload = (
  recaptcha,
  formData,
  socialNetworks,
  parentSlug,
  indicated_by
) => {
  return {
    recaptcha_response: recaptcha.token,
    slug_parent: parentSlug,
    slug: formData.store_name,
    email: formData.email,
    password: formData.password,
    pf_pj: formData.type_person,
    celular: getCelularField(formData),
    facebook_id: formData.facebook_id,
    opt_in: formData.opt_in,
    categories: formData.categories,
    partner_type_id: formData.partner_type_id,
    ...socialNetworks,
    ...(formData.type_as ? { partner_type: formData.type_as } : {}),
    ...(formData.type_person === "pf"
      ? {
          pf_cpf: removeCharsCpf(formData.document_cpf),
          nome: formData.complete_name,
        }
      : {
          pj_nome_contato: formData.name_default_contact,
          pj_fantasia: formData.fantasy_name,
          pj_cnpj: formData.document_pj,
          pj_cpf_contato: removeCharsCpf(formData.document_cpf_contact),
          pj_cnaes: formData.pj_cnaes,
        }),
    ...(indicated_by ? { slug_indicated_by: indicated_by } : {}),
  };
};
