/**
 * Hierarchy routes
 */
export default [
  {
    path: "hierarchy",
    name: "hierarchy",
    component: () => import("./index"),
    meta: {
      visible: false,
    },
  },
];
