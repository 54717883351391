import { baseUrlCC } from "@/shared/http/api";

/**
 * @Commission-service
 */
export default {
  /**
   * commission opened index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  fetchCommissionOpened: () => ({
    method: "GET",
    url: "api/v3/reports/partner/openCommissions",
    baseURL: baseUrlCC,
  }),

  /**
   * commission closed index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  fetchCommissionClosed: () => ({
    method: "GET",
    url: "api/v3/reports/partner/lastClosure",
    baseURL: baseUrlCC,
  }),

  /**
   * docs unfilled
   *
   * @returns {{method: string, url: string}}
   */
  fetchDocsUnfilled: () => ({
    method: "GET",
    url: "api/v3/partners/unfilled",
    baseURL: baseUrlCC,
  }),

};

