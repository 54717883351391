/**
 * all states from store
 */
export default {
  banks: [],
  bank_filter_need: "",
  documents: [],
  doc_types: [],
  params: [],
  partner_challenges: [],
  challenge_hash: "",
  challenge_ext_code: "",
  challenge_ext_secret: "",

  parameterized_registration_param: {},
};
