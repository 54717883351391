/**
 * @Home-services
 */
import { baseUrlReward } from "@/shared/http/api";

export default {
  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  hierarchyByPartner: () => ({
    method: "GET",
    url: "/api/v1/hierarchy/by-partner",
    baseURL: baseUrlReward,
  }),
};
