import { setBearerToken } from "@/shared/http";

/**
 * Putting the token in the header for authentic calls
 *
 * @param token
 */
export const setHeaderToken = (token) => setBearerToken(token);

/**
 * @returns {string}
 */
export const getLocalToken = () => localStorage.getItem("token");

/**
 * Deleting token from localStorage
 */
export const deleteLocalToken = () => localStorage.removeItem("token");

/**
 * putting token in localStorage
 * @param token
 */
export const setLocalToken = (token) => localStorage.setItem("token", token);


/**
 * this function is used to log out the user when the token is expired or is invalid
 * @param message
 */
export const LogOutUnauthenticated = (message) => {
  const normalizeMessage = message.toLocaleLowerCase().replace(/[^\w\s]/gi, "");
  if (
    normalizeMessage === "unauthorized" ||
    normalizeMessage === "unauthenticated"
  ) {
    deleteLocalToken();
    window.location.reload();
  }
};
