import services from "@/shared/http";
import * as types from "./mutation-types";
import { setLocalSlug } from "@/modules/partner/storage";

/**
 * update partner email
 *
 * @param state
 * @param commit
 * @param email
 * @returns {*}
 */
export const updatePartnerEmail = ({ state, commit }, { email }) => {
  return new Promise((resolve, reject) => {
    services.partner
      .updatePartner({ email_principal: email })
      .then(() => {
        commit(
          types.SET_PARTNER,
          Object.assign(state.partner, { email_principal: email })
        );
        resolve();
      })
      .catch(() => reject());
  });
};

/**
 * set partner action
 *
 * @param commit
 * @param payload
 */
export const setPartnerAction = ({ commit }, payload) => {
  commit(types.SET_PARTNER, payload);
  setLocalSlug(payload.slug);
};
/**
 * set partner action
 *
 * @param commit
 * @param payload
 */
export const setCustomerServiceAction = ({ commit }, payload) => {
  commit(types.SET_CUSTOMER_SERVICE, payload);
};
/**
/**
 * set partner action
 *
 * @param commit
 * @param payload
 */
export const setParamsAction = ({ commit }, payload) => {
  commit(types.SET_PARAMETERS, payload);
};

/**
 * action to fetch partner data from session
 *
 * @param dispatch
 * @param payload
 * @returns {Promise<unknown>}
 */
export const fetchPartnerBySlugAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.partner
      .parentBySlug(payload)
      .then(async (response) => {
        await dispatch("setPartnerAction", response);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

import {
  cacheHandler,
  // setCache,
} from "@/shared/helpers/cache/sessionCacheHelper";

/**
 * Action to fetch partner data from session.
 *
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchPartnerAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    const prefix = "parent"; // Prefixo que será usado para o cache

    cacheHandler(prefix, {
      onCacheHit: async (cachedData) => {
        try {
          // Despachar a ação setPartnerAction com os dados do cache
          await dispatch("setPartnerAction", cachedData);
          // Despachar a ação fetchParametersToPartnerAction com o partnerSlug do cache
          await dispatch("fetchParametersToPartnerAction", {
            partnerSlug: cachedData.slug,
          });
          resolve(); // Resolver a Promise, pois os dados foram carregados do cache
        } catch (error) {
          reject(error); // Rejeitar a Promise em caso de erro
        }
      },
    })
      .then((cacheResult) => {
        if (!cacheResult.isCacheHit) {
          // Caso não haja cache, fazer a requisição à API
          services.partner
            .parent()
            .then(async (response) => {
              try {
                // Armazenar o resultado no cache
                // setCache(prefix, response);
                // Despachar as ações com os dados obtidos da API
                await dispatch("setPartnerAction", response);
                await dispatch("fetchParametersToPartnerAction", {
                  partnerSlug: response.slug,
                });
                resolve(); // Resolver a Promise com sucesso
              } catch (error) {
                reject(error); // Rejeitar a Promise em caso de erro
              }
            })
            .catch((error) => {
              reject(error); // Rejeitar a Promise se a requisição falhar
            });
        }
      })
      .catch(reject); // Rejeitar a Promise em caso de erro no cacheHandler
  });
};

/**
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchParametersToPartnerAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.partner
      .params()
      .then((response) => {
        dispatch("setParamsAction", response);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchCustomerServiceAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.partner
      .customerService(payload)
      .then(async (response) => {
        await dispatch("setCustomerServiceAction", response);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
