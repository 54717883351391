import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param checklists
   */
  [types.SET_CHECKLISTS](state, checklists) {
    state.checklists = checklists;
  },
  /**
   * @param state
   * @param show_latter
   */
  [types.SET_SHOW_LATTER_CHECKLIST](state, show_latter) {
    state.show_latter = show_latter;
  },
};
