export default {
  numbers_manipulations: {
    invalid_number_card: "O número do cartão é inválido",
  },
  string_manipulations: {
    required_field: "O campo {field} é obrigatório",
    unmatched_field: "O campo {field} não corresponde",
  },
  invalid_key_pix: "A chave PIX é inválida",
  error_key_pix: "Erro ao validar a chave PIX",
  key_pix_dont_match:
    "A chave pix indicada não pertence ao CPF/CNPJ informado {value}",

  required: "Campo obrigatório",
};
