import { SET_HIERARCHY } from "./mutation-types";
import services from "@/shared/http";

/**
 * @param commit
 * @return {*}
 */
export const setHierarchy = ({ commit }, payload) => {
  commit(SET_HIERARCHY, payload);
};

/**
 * @param dispatch
 * @return {*}
 */
export const fetchHierarchy = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.hierarchy
      .hierarchyByPartner()
      .then(async (resource) => {
        await dispatch("setHierarchy", resource);
        return resolve();
      })
      .catch((err) => reject(err));
  });
};
