export default {
  lading_page: "Meus Favoritos",
  sales: "Minhas Vendas",
  store: "Meus Produtos",
  home: "Homepage",
  faq: "FAQ",
  mag: "Área de Conteúdo",
  me_store: "Meu espaço",
  my_points: "Resgatar Meus Créditos",
  commission: "Minhas Bonificações ",
  extracts: "Extrato de Cŕéditos",
  how_get: "Como Ganhar Créditos",
  courses: "Cursos",
  missions: "Missões",
  notifications: "Minhas notificações",
  my_points_recueds: "Meus Resgates",
  forum: "Atendimento",
  account: {
    index: "Meus Dados",
    children: {
      address: "Endereço",
      "bank-account": "Dados Bancários",
      "change-password": "Alterar Senha",
      "change-email": "Dados de Contato",
      "personal-info": "Meus Dados",
      "change-space": "Meu Espaço",
      "social-account": "Redes Sociais",
      "disable-account": "Desativar Conta",
      "my-documents": "Meus Documentos",
      "company-data": "Dados da Empresa",
    },
    labels: {
      acc_and_security: "Conta&Segurança",
      personal_info: "Informações Pessoais",
      payments: "Contas&Pagamentos",
      shared: "Compartilhados",
      store: "Loja",
      documents: "Documentos",
    },
    descriptions: {
      change_my_space: "Altere o nome de seu espaço",
      my_docs: "Gerencie seus documentos",
      disable_account:
        "Novas vendas na sua loja não irão mais gerar bonicações e você não será mais pago por elas",
      "company-data": "Informe dados da empresa onde trabalha",
    },
  },
};
