/**
 * Map name for mutations
 * @types {string}
 */
export const SET_MISSIONS = "MISSIONS/SET_MISSIONS";
export const SET_SUB_LAST = "MISSIONS/SET_SUB_LAST";
export const SET_NOT_SUB_MISSIONS = "MISSIONS/SET_NOT_SUB_MISSIONS";
export const SET_NOT_SUB_LAST = "MISSIONS/SET_NOT_SUB_LAST";
export const SET_FINISHED_LAST = "MISSIONS/SET_FINISHED_LAST";
export const SET_SHOW_MISSION = "MISSIONS/SET_SHOW_MISSION";
export const SET_SUBS_QUESTIONS = "MISSIONS/SET_SUBS_QUESTIONS";
export const SET_FINISHED_MISSIONS = "MISSIONS/SET_FINISHED_MISSIONS";
export const SET_FINISHED_DETAILS = "MISSIONS/SET_FINISHED_DETAILS";
export const SET_PUBLIC_MISSIONS = "MISSIONS/SET_PUBLIC_MISSIONS";
export const SET_PUBLIC_FINISHED = "MISSIONS/SET_PUBLIC_FINISHED";
export const SET_SHOW_PUBLIC = "MISSIONS/SET_SHOW_PUBLIC";
export const SET_PUBLIC_HEADER = "MISSIONS/SET_PUBLIC_HEADER";
export const SET_MISSION_TERMS = "MISSIONS/SET_MISSION_TERMS";
export const SET_AVAIBLE_MISSIONS = "MISSIONS/SET_AVAIBLE_MISSIONS";
export const SET_TEMPORARY_UPLOAD_LINKS = "MISSIONS/SET_TEMPORARY_UPLOAD_LINKS";
