/**
 Export all getters from state
*/

/**
 * @param menu_param
 */
export const getMenuParam = ({ menu_param }) => menu_param;

/**
 * @param links
 */
export const getCardLink = ({ links }) => {
  const linksValues = Object.values(links);

  return linksValues.map((link) => ({
    value: link.value,
    description: link.func,
    icon: link.var,
  }));
};

/**
 * @param alcadas
 */
export const getAlcadas = ({ alcadas }) => alcadas;
