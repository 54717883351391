export default {
  success: "Ação concluida com sucesso!",
  error: "Operação não concluida, algo inesperado aconteceu",
  unprocessed_error_on_created:
    "Algo inesperado aconteceu, ao tentar criar o recurso",
  unprocessed_error_on_save:
    "Algo inesperado aconteceu, ao tentar salvar o recurso",
  unprocessed_error_on_delete:
    "Algo inesperado aconteceu, ao tentar deletar o recurso",
  terms_refreshed_as_success: "Termos atualizados com sucesso!",
  terms_unprocessed: "Não foi possivel aceitar os termos de uso",
};
