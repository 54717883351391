import * as types from "../mutation-types";
import services from "@/shared/http";


/** 
 * Define apenas as empresas com produtos selecionados pelo INFLU
 * ( Empresas que o influ selecionou produtos no child )
* */
export const setOnlyCompaniesWithProductsLPCentralized = ({ commit }, payload) => {
    commit(types.SET_ONLY_COMPANIES_WITH_PRODUCTS_LP_CENTRALIZED, payload);
}


/* Define os links do ecommerce das empresas parceiras ( Com o parâmetro do influ ) */
export const setCompaniesLinksLPCentralized = ({ commit }, payload) => {
    commit(types.SET_COMPANIES_LINKS_LP_CENTRALIZED, payload?.linkStores);
}


/* Define os vouchers disponíveis para o INFLU */
export const setInfluencerVoucherLPCentralized = ({ commit }, payload) => {
    commit(types.SET_INFLUENCER_VOUCHERS_LP_CENTRALIZED, payload?.vouchers);
}


/* Define se é ou não a LP Publica */
export const setLPCentralized = ({ commit }, payload) => {
    commit(types.SET_LP_CENTRALIZED, payload);
};


/* Busca os Links e Vouchers das empresas disponiveis na LP Centralizada  */
export const fetchLinksAndVouchers = async ({ dispatch }, payload) => {
    const output = await services.public_landingpage.getLinksAndVouchers({}, payload)

    if (!output) throw new Error('Houve um erro ao obter links e vouchers')

    dispatch('setCompaniesLinksLPCentralized', output)
    dispatch('setInfluencerVoucherLPCentralized', output)
}