/**
 * Courses routes
 */
export default [
  {
    path: "courses/desc/:slug",
    name: "courses.desc",
    component: () => import("./show"),
    meta: {
      visible: false,
    },
  },
  {
    path: "courses",
    name: "courses",
    component: () => import("./index"),
    meta: {
      sort: 7,
      visible: true,
      isCourseRoute: true,
      label: "menu.courses",
      icon: "collections_bookmark",
    },
  },
];
