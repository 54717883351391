import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param categories
   */
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  /**
   * @param state
   * @param points
   */
  [types.SET_POINTS](state, points) {
    state.points = points;
  },
  /**
   * @param state
   * @param commission
   */
  [types.SET_COMMISSION](state, commission) {
    state.commission = commission;
  },
  /**
   * @param state
   * @param split_exceptions
   */
  [types.SET_SPLIT_EXCEPTIONS](state, split_exceptions) {
    state.split_exceptions = split_exceptions;
  },
  /**
   * @param state
   * @param partner
   */
  [types.SET_PARTNER](state, partner) {
    state.partner = partner;
  },
  /**
   * @param state
   * @param cluster
   */
  [types.SET_SPLIT_CLUSTERS](state, cluster) {
    state.cluster = cluster;
  },
  /**
   * @param state
   * @param total_points
   */
  [types.SET_TOTAL_POINTS](state, total_points) {
    state.total_points = total_points;
  },
  /**
   * @param state
   * @param id
   */
  [types.SET_CATEGORY_FILTER_ID](state, id) {
    state.category_filter_id = id;
  },
  /**
   * @param state
   * @param points_in_request
   */
  [types.SET_POINTS_IN_REQUEST](state, points_in_request) {
    state.points_in_request = points_in_request;
  },
  /**
   * @param state
   * @param faq_questions
   */
  [types.SET_FAQ_QUESTIONS](state, faq_questions) {
    state.faq_questions = faq_questions;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_SPLIT_CLUSTERS_RULES](state, payload) {
    state.split_clusters_rules = payload;
  },
};
