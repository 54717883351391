import * as types from "./mutation-types";
import { notifyBottom } from "@/shared/helpers/notify";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_STORE_PRODUCTS](state, payload) {
    state.store_products = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_FAVORITE_PRODUCTS](state, payload) {
    state.favorites_products = payload;
  },

  /**
   * @param state
   * @param filter
   */
  [types.SET_STORE_SEARCH_FILTER](state, filter) {
    state.store_search_filter = filter;
  },
  /**
   * @param state
   * @param filter
   */
  [types.SET_FAVORITE_SEARCH_FILTER](state, filter) {
    state.favorite_search_filter = filter;
  },
  /**
   * @param state
   * @param link
   */ [types.SET_LANDING_PAGE_LINK](state, link) {
    state.landing_page_link = link;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_CHECKOUT_DATA](state, payload) {
    const hasCheckoutItem = state.checkout_data.find(
      (item) => item.id === payload.id
    );

    if (!hasCheckoutItem) {
      state.checkout_data.push({
        ...payload,
        quantity: 1,
      });
      //  state.checkout_params.rules.maxTotalItems.value--;
      notifyBottom("Produto adicionado ao carrinho");
    } else {
      state.checkout_data = state.checkout_data.filter(
        (item) => item.id !== payload.id
      );
      notifyBottom("Produto removido do carrinho");
    }
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_CHECKOUT_DATA_TO_EMPTY](state) {
    state.checkout_data = [];
  },

  [types.SET_REPLACE_PRODUCT_QUANTITY](state, payload) {
    const itemToReplace = state.checkout_data.find((e) => e.id === payload.id);

    if (itemToReplace) {
      if (payload.quantity === 0) {
        state.checkout_data = state.checkout_data.filter(
          (e) => e.id !== payload.id
        );

        notifyBottom("Produto removido do carrinho");
      } else {
        state.checkout_data = state.checkout_data.map((e) => {
          if (e.id === payload.id) {
            return {
              ...e,
              quantity: payload.quantity,
            };
          }
          return e;
        });
      }
    }
  },

  [types.SET_OPEN_CHECKOUT_SIDE](state, payload) {
    state.open_checkout_side = payload;
  },

  [types.SET_CHECKOUT_LINK](state, payload) {
    state.checkout_link = payload;
  },

  [types.SET_CHECKOUT_PARAMS](state, payload) {
    state.checkout_params = payload;
    state.checkout_rules = Object.values(payload.rules);
  },

  [types.SET_HAS_CHECKOUT](state, payload) {
    state.has_checkout = payload;
  },
};
