/**
 * Missions routes
 */
export default [
  {
    path: "missions/:tab?/:slug?",
    name: "missions",
    component: () => import("./index"),
    meta: {
      sort: 6,
      visible: true,
      label: "menu.missions",
      icon: "menu_book",
    },
  },
  {
    path: "mission/:slug",
    name: "view-mission",
    component: () => import("./ShowMissionPage"),
    meta: {
      visible: false,
    },
  },
  {
    path: "missions/finished/:slug",
    name: "view-finished",
    component: () => import("./ShowMissionPage"),
    meta: {
      visible: false,
    },
  },
];
