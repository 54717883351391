import services from "@/shared/http";
import * as types from "./mutation-types";

/**
 * @param commit
 * @param payload
 */
export const setAvailableBanners = ({ commit }, payload) => {
  commit(types.SET_AVAILABLE_BANNERS, payload);
};

/**
 * @param commit
 * @param payload
 */
export const setSelectedBanners = ({ commit }, payload) => {
  commit(types.SET_SELECTED_BANNERS, payload);
};
/**
 * @param commit
 * @param payload
 */
export const setLpProducts = ({ commit }, payload) => {
  commit(types.SET_LP_PRODUCTS, payload);
};

/**
 * @param dispatch
 * @return {Promise<void>}
 */
export const fetchLpProducts = async ({ dispatch }) => {
  return await services.landing_page.banners().then(async (resource) => {
    await dispatch("setAvailableBanners", resource.carousels_options);
    await dispatch("setSelectedBanners", resource.selected_carrousels);
  });
};

/**
 * @param dispatch
 * @param params
 * @return {Promise<*>}
 */
export const fetchLpProductsPublic = async ({ dispatch }, { params }) => {
  return await services.landing_page
    .lpProducts(null, params)
    .then(async (resource) => {
      await dispatch("setLpProducts", resource);
    });
};

/**
 * @param dispatch
 * @param ids
 * @return {Promise<*>}
 */
export const storeLpBannersSelected = async ({ dispatch }, ids) => {
  return await services.landing_page
    .storeBannersSelected({ ids: ids })
    .then(async (resource) => {
      await dispatch("setSelectedBanners", resource);
    });
};
