import services from "@/shared/http";
import * as auth_types from "@/modules/auth/store/mutation-types.js";
import * as types from "./mutation-types";
import { downloadBlob } from "@/shared/helpers/fileDownloadHelper";
import {
  notifyError,
  showNotifyFailToResponses,
  showFailMessages,
} from "@/shared/helpers/notify";
import axios from "axios";

import i18n from "@/i18n/wrappers/i18n";
import { uploadServices } from "../../../../../components/SendDocuments/setup/uploadWithCustomJwt";
const t = i18n.global.t;

const SET_USER = "auth/" + auth_types.SET_USER;

/**
 * @param commit
 * @param banks
 */
export const setBanksListAction = ({ commit }, banks) =>
  commit(types.SET_BANKS, banks);

/**
 * @param commit
 * @param value
 */
export const setBankFilterKey = ({ commit }, value) =>
  commit(types.SET_BANK_FILTER, value);

/**
 * @param commit
 * @param value
 */
export const setUserDocuments = ({ commit }, value) =>
  commit(types.SET_DOCUMENTS, value);

/**
 * @param commit
 * @param value
 */
export const setDocTypes = ({ commit }, value) =>
  commit(types.SET_DOC_TYPES, value);

/**
 * @param commit
 * @param value
 */
export const setParameters = ({ commit }, value) =>
  commit(types.SET_PARAMS, value);

/**
 * update user email
 *
 * @param commit
 * @param rootState
 * @param email
 */
export const updateUserEmailAction = ({ commit, rootState }, { email }) => {
  return new Promise((resolve, reject) => {
    services.account
      .updateUser({ email })
      .then(() => {
        commit(SET_USER, Object.assign(rootState.auth.user, { email: email }), {
          root: true,
        });
        resolve();
      })
      .catch(() => reject());
  });
};

/**
 * update email
 * @param dispatch
 * @param email
 */
export const updateEmailAction = ({ dispatch }, { email }) => {
  return Promise.all(
    [dispatch("updateUserEmailAction", { email })],
    [dispatch("partner/updatePartnerEmail", { email }, { root: true })]
  );
};

/**
 * @param getters
 * @param rootState
 * @param commit
 * @param payload
 * @returns {*}
 */
export const updateUserPartnerAction = (
  { getters, rootState, commit },
  payload
) => {
  return new Promise((resolve, reject) => {
    services.partner
      .updatePartner(payload)
      .then(() => {
        let user = Object.assign(rootState.auth.user, {
          partner: Object.assign(getters.getPartnerAccount, payload),
        });
        commit(SET_USER, user, { root: true });
        resolve();
      })
      .catch((errors) => reject(errors));
  });
};

/**
 * @param getters
 * @param rootState
 * @param commit
 * @param payload
 * @returns {*}
 */
export const deleteUserPartnerAction = (
  { getters, rootState, commit },
  payload
) => {
  return new Promise((resolve, reject) => {
    services.partner
      .updatePartner(payload)
      .then(() => {
        let user = Object.assign(rootState.auth.user, {
          partner: Object.assign(getters.getPartnerAccount, payload),
        });
        payload.each((model) => {
          if (user.partner[model]) delete user.partner[model];
        });
        commit(SET_USER, user, { root: true });
        resolve();
      })
      .catch((errors) => reject(errors));
  });
};

/**
 * @param dispatch
 * @param payload
 * @return {*}
 */
export const updateUserPartnerBank = ({ dispatch }, payload) =>
  dispatch("updateUserPartnerAction", {
    bank_account: payload,
    pix_account: null,
  });

/**
 * @param dispatch
 * @param payload
 * @return {*}
 */
export const updateUserPartnerPix = ({ dispatch }, payload) =>
  dispatch("updateUserPartnerAction", {
    pix_account: payload,
    bank_account: null,
  });

/**
 * @param getters
 * @param rootState
 * @param commit
 * @param payload
 * @returns {*}
 */
export const updatePartnerNetworksAction = (
  { getters, rootState, commit },
  payload
) => {
  return new Promise((resolve, reject) => {
    let networks = [];
    Object.keys(payload).forEach((network) => {
      let data = { network: network, content: payload[network] };
      Object.values(getters.getPartnerAccount.social_networks).forEach((nt) => {
        if (nt.network === network) data = Object.assign(nt, data);
      });
      networks.push(data);
    });

    services.partner
      .updatePartner({ social_networks: networks })
      .then(() => {
        let user = Object.assign(rootState.auth.user, {
          partner: Object.assign(getters.getPartnerAccount, {
            social_networks: { ...networks },
          }),
        });
        commit(SET_USER, user, { root: true });
        resolve();
      })
      .catch(() => reject());
  });
};

/**
 * fetch banks list sync function
 * @param dispatch
 */
export const fetchBanksListAction = async ({ dispatch }) => {
  await services.account.listBanks().then((resource) => {
    localStorage.setItem("banksList", JSON.stringify(resource));
    dispatch("setBanksListAction", resource);
  });
};

/**
 * @param ctx
 * @param payload
 * @return {*}
 */
export const changePassword = (ctx, payload) =>
  services.account.changePassword(payload);

export const removeAccountPhoto = async ({ dispatch, rootState }) => {
  await services.account.deletePhoto().then(() => {
    let resource = Object.assign({}, rootState.auth.user);
    resource.partner.logo_foto = null;

    dispatch("auth/setUserAction", resource, { root: true });
  });
};

/**
 * @param dispatch
 */
export const disableAccount = async ({ dispatch }) => {
  await services.account.disable().then(async (resource) => {
    await dispatch("auth/signOutAction", resource, { root: true });
  });
};

/**
 *
 * @param {*} dispatch
 * @param {*} payload
 */
export const fetchParametersByKeys = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .params(payload)
      .then((res) => {
        dispatch("setParameters", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * @param dispatch
 * @param payload
 */
export const fetchUserDocs = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.account
      .userDocs()
      .then((payload) => {
        dispatch("setUserDocuments", payload);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * @param dispatch
 * @param payload
 */
export const fetchDocsTypes = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.account
      .docTypes()
      .then((payload) => {
        dispatch("setDocTypes", payload);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchDocDataToCustomJwt = ({ dispatch }) => {
  uploadServices.userDocs().then((payload) => {
    dispatch("setUserDocuments", payload.data);
  });

  uploadServices.docTypes().then((payload) => {
    dispatch("setDocTypes", payload.data);
  });
};

/**
 * @param dispatch
 * @param payload
 */
export const fetchUploadDoc = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .uploadDocs(payload)
      .then(() => {
        dispatch("fetchUserDocs");
        resolve();
      })
      .catch(({ errors }) => {
        notifyError(errors.file[0]);
        reject(errors);
      });
  });
};

/**
 * @param dispatch
 * @param payload
 */
export const fetchDeleteDoc = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .deleteDocs({}, payload)
      .then(() => {
        dispatch("fetchUserDocs");
        resolve();
      })
      .catch(({ errors }) => {
        notifyError(errors.file[0]);
        reject(errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const setInvalidPix = ({}, payload) => {
  console.log(payload);
};

// eslint-disable-next-line no-empty-pattern
export const validatePix = ({}, payload) => {
  const payloadService = {
    key:
      payload.type == "phone_pix"
        ? `+55${payload.content.replace(/[^0-9]/g, "")}`
        : payload.content,
    taxId: localStorage.getItem("partnerCPFOrCNPJ").replace(/[^0-9]/g, ""),
  };

  return services.account
    .validatePIX(payloadService)
    .then((res) => {
      if (res.status) return true;
      else {
        return false || res.message;
      }
    })
    .catch(() => {
      return (
        false ||
        t("validations.key_pix_dont_match", {
          value: localStorage.getItem("partnerCPFOrCNPJ"),
        })
      );
    });
};

/**
 * CHALLENGE ACTIONS
 */

export const setPartnerChallenges = ({ commit }, payload) => {
  commit(types.SET_PARTNER_CHALLENGES, payload);
};

export const setChallengeCode = ({ commit }, payload) => {
  commit(types.SET_CHALLENGE_CODE, payload);
};

export const setChallengeExtSecret = ({ commit }, payload) => {
  commit(types.SET_CHALLENGE_EXT_SECRET, payload.secret_key);
};

export const fetchPartnerChallenges = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.account
      .partnerChallenges()
      .then((res) => {
        dispatch("setPartnerChallenges", res);
        resolve();
      })
      .catch(({ errors }) => {
        reject(errors);
      });
  });
};

/// sendAndUpdateChallenge
export const fetchSendAndUpdateChallenge = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .sendAndUpdateChallenge({}, payload)
      .then((res) => {
        dispatch("setChallengeCode", res.challenge_hash);
        resolve();
      })
      .catch((error) => {
        showFailMessages(error);
        reject(error.errors);
      });
  });
};

export const fetchSendChallenge = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .sendChallenge({}, payload)
      .then((res) => {
        dispatch("setChallengeCode", res.challenge_hash);
        resolve();
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error.errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchValidateChallenge = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .validateChallenge({}, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error.errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchResendChallenge = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .resendChallenge({}, payload)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error.errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchResendChallengeExt = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .resendChallengeExt({}, payload)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error.errors);
      });
  });
};

/**
 * challenge external
 */
// eslint-disable-next-line no-empty-pattern
export const fetchSendChallengeExt = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .sendChallengeExt({}, payload)
      .then((response) => {
        dispatch("setChallengeExtSecret", response);
        resolve(response);
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error.errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchValidateChallengeExt = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .validateChallengeExt({}, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error.errors);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchDownloadLastSignedTerm = ({}, payload) => {
  if (!payload || !payload.conf || !payload.data) {
    throw new Error("Payload, conf, and data are required");
  }

  return new Promise((resolve, reject) => {
    axios({
      ...payload.conf,
      responseType: "blob",
    })
      .then((response) => {
        downloadBlob(
          response.data,
          `parceiro_${payload.data.partner_id}_termo_assinado`,
          "pdf"
        );
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data instanceof Blob) {
          // Convert Blob to JSON
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              showFailMessages(errorData.errors);
              reject(errorData);
            } catch (parseError) {
              notifyError("Failed to parse error response");
              reject(parseError);
            }
          };
          reader.onerror = () => {
            notifyError("Failed to read error response");
            reject(reader.error);
          };
          reader.readAsText(error.response.data);
        } else {
          notifyError("Preencha seus dados");
          reject(error);
        }
      });
  });
};

export const setParameterizedRegistrationParam = ({ commit }, payload) => {
  commit(types.SET_PARAMETERIZED_REGISTRATION_PARAM, payload);
};

export const fetchParameterizedRegistrationParam = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.account
      .parameterizedRegistration({}, payload)
      .then(async (response) => {
        let skl = JSON.parse(response.value);

        // Fetch options for customizable steps with a service
        const customizableSteps = skl.customizable_steps;
        for (const stepKey in customizableSteps) {
          const fieldsWithService = customizableSteps[stepKey].fields.filter(
            (field) => field.service
          );

          for (const field of fieldsWithService) {
            try {
              const options = await fetchOptionsByService(field.service);
              field.options = options.map((option) => ({
                value: option[field.field_value],
                label: option[field.field_label],
              }));
            } catch (error) {
              console.error(`Error fetching options for ${field.name}:`, error);
            }
          }
        }

        dispatch("setParameterizedRegistrationParam", skl);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

// Fetch options from a service by endpoint
export const fetchOptionsByService = async (endpoint) => {
  return fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error(`Error fetching options from ${endpoint}:`, error);
      throw error;
    });
};
