/**
 * public routes
 */
export default [
  {
    path: "/:childSlug/lp/:parentSlug",
    name: "public-landingpage",
    component: () => import("./pages/public-landingpage/index"),
    meta: {
      visible: false,
      noAuth: true,
    },
  },
  {
    path: "/lp/:slug/:user_slug/products",
    name: "lp.products",
    component: () => import("./pages/landing-pages/lpProducts"),
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/blog-lp",
    component: () => import("../../blog-lp/blog.vue"),
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/certificate/course/:slug",
    name: "public.course.certificate",
    component: () => import("./pages/course/public.certificate"),
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/public/missions/:slugCompany",
    name: "public.missions.company",
    component: () => import("./pages/missions/PublicMissionPage"),
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/public/missions",
    name: "public.missions",
    component: () => import("./pages/missions/PublicMissionPage"),
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/public/mission/:slug",
    name: "public.missions-show",
    component: () => import("./pages/missions/PublicShowMissionPage"),
    meta: {
      noAuth: true,
    },
  },
  {
    meta: {
      noAuth: true,
    },
    path: "/404",
    component: () => import("./views/404"),
    name: "404",
  },
  {
    meta: {
      noAuth: true,
    },
    path: "/:catchAll(.*)*",
    component: () => import("./views/404"),
  },
];
