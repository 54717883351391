/**
 * When image fetched not found use this by default
 *
 * @returns {string}
 * @constructor
 */
export const ImgNotFound = () => {
  return "/img404.jpg";
};

/**
 * @return {string}
 */
export const storeNotFound = () => {
  return "/storeNotFound.jpg";
};
/**
 * @return {string}
 */
export const bannerLpDefault = () => {
  return "/bannerLpDefault.png";
};
/**
 * @return {string}
 */
export const storeNotFoundMobile = () => {
  return "/storeNotFoundMobile.jpg";
};
/**
 * @return {string}
 */
export const logo = () => {
  return "/logo.png";
};
