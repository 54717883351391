/**
 * Validates if the user is active in the session with a valid token
 */

import store from "@/store";

/**
 * @returns {Promise<*>}
 */
export default async () => {
  try {
    await store.dispatch("auth/persistTokenAction");
    return store.getters["auth/hasToken"];
  } catch (e) {
    return false;
  }
};
