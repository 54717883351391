/**
 * Routes from auth module
 */
export default [
  {
    redirect: {
      name: "login",
    },
    meta: {
      persistSlug: true,
      noAuth: true,
    },
    path: "/:slug",
    component: () => import("./views/Auth"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("./pages/Login"),
      },
      {
        path: "save/token/:token",
        name: "save.token",
        component: () => null,
      },
      {
        path: "bemvindo",
        redirect: { name: "sign.up" },
      },
      {
        path: "sign-up",
        name: "sign.up",
        component: () => import("./pages/sign-up/signUp"),
      },
      {
        path: "v2/sign-up",
        name: "sign.up-v2",
        component: () => import("./pages/sign-up/v2/signUp"),
      },
      {
        path: "recover",
        name: "recover",
        component: () => import("./pages/Recover/Recover"),
      },
      {
        path: "recover/confirm/code/:email",
        name: "recover.confirm.code",
        component: () => import("./pages/RecoverConfirm"),
      },
      {
        path: "password/change/:code/:email",
        name: "recover.password.change",
        component: () => import("./pages/ChangePassword"),
      },
    ],
  },
  {
    path: "/blog-sign-up",
    name: "sign.blog",
    component: () => import("./pages/signup-blog-flipnet/SignupBlogPage"),
    meta: {
      //persistSlug: true,
      noAuth: true,
    },
  },
  {
    path: "/:slug/redirect/:routeName",
    name: "redirect-login",
    component: () => import("./pages/ConfigRedirectComponent"),
    meta: {
      noAuth: true,
    },
  },
];
