import services from "@/shared/http";


export const changeInstallationStatus = ({ dispatch }, event) => {
    dispatch('setInstallationStatus', event)
}

export const fetchDownloadApp = async ({ dispatch }, payload) => {
    console.log(dispatch)
    return new Promise((resolve, reject) => {
        services.pwa.appDownload(payload)
        .then(success => {
            resolve(success)
        })
        .catch(err => {
            reject(err)
        })
    })
};