import services from "@/shared/http";
import * as types from "./mutation-types";
import { pickBy, filter } from "lodash";

/**
 * set tab mode action
 *
 * @param commit
 * @param payload
 */
export const setTabModeData = ({ commit }, payload) => {
  commit(types.SET_TAB_MODE, payload);
};

/**
 * set courses action
 *
 * @param commit
 * @param payload
 */
export const setCoursesData = ({ commit }, payload) => {
  commit(types.SET_COURSES, payload);
};

/**
 * set courses action
 *
 * @param commit
 * @param payload
 */
export const setCoursesFinishedData = ({ commit }, payload) => {
  commit(types.SET_COURSES_FINISHED, payload);
};

/**
 * set courses action
 *
 * @param commit
 * @param payload
 */
export const setCoursesInProcessData = ({ commit }, payload) => {
  commit(types.SET_COURSES_IN_PROCESS, payload);
};

/**
 * set filter action
 *
 * @param commit
 * @param payload
 */
export const setFilterData = ({ commit, dispatch }, payload) => {
  commit(types.SET_FILTER, payload);
  dispatch("onFilterChanges");
};

/**
 * set categories action
 *
 * @param commit
 * @param payload
 */
export const setCategoriesData = ({ commit }, payload) => {
  commit(types.SET_CATEGORIES, payload);
};

/**
 * set questions action
 *
 * @param commit
 * @param in_request
 */
export const setInRequest = ({ commit }, in_request) => {
  commit(types.SET_IN_REQUEST, in_request);
};

export const setAllCourses = ({ commit }, payload) => {
  commit(types.SET_ALL_COURSES, payload);
};

/**
 * @param {Dispatch} dispatch
 * @param {number} payload
 * @returns {Promise<void>}
 */
export const fetchAllCourses = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.courses
      .coursesList(payload)
      .then(({ data }) => {
        dispatch("setAllCourses", data);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * set questions action
 *
 * @param commit
 * @param in_request
 */
export const setBadgeChanges = ({ state, commit }) => {
  commit(types.SET_BADGE_CHANGES, state.badges_changed + 1);
};

export const onFilterChanges = ({ state, dispatch }) => {
  dispatch("setBadgeChanges");

  switch (state.tab_mode) {
    case "notEnrolled":
      dispatch("fetchCoursesAction");
      break;
  }
};

/**
 * action to fetch question
 *
 * @param dispatch
 * @param payload
 * @returns {Promise<unknown>}
 */
export const fetchCoursesAction = ({ dispatch, getters }, payload) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);

    let params = Object.assign(
      pickBy(getters.getFilter, (f) => f !== null) || {},
      payload
    );
    if (params.categories) {
      params.categories = params.categories.join(",");
    }

    services.courses
      .notEnrolled(params)
      .then((resources) => {
        dispatch("setCoursesData", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setInRequest", false));
  });
};

/**
 * action to fetch question
 *
 * @param dispatch
 * @param payload
 * @returns {Promise<unknown>}
 */
export const fetchCoursesInProgressAction = (
  { dispatch, getters },
  payload
) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);

    let params = Object.assign(
      pickBy(getters.getFilter, (f) => f !== null) || {},
      payload
    );
    if (params.categories) {
      params.categories = params.categories.join(",");
    }

    services.courses
      .inProgress(params)
      .then((resources) => {
        dispatch("setCoursesInProcessData", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setInRequest", false));
  });
};

/**
 * action to fetch question
 *
 * @param dispatch
 * @param payload
 * @returns {Promise<unknown>}
 */
export const fetchCoursesFinishedAction = ({ dispatch, getters }, payload) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);

    let params = Object.assign(
      pickBy(getters.getFilter, (f) => f !== null) || {},
      payload
    );
    if (params.categories) {
      params.categories = params.categories.join(",");
    }

    services.courses
      .finished(params)
      .then((resources) => {
        dispatch("setCoursesFinishedData", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setInRequest", false));
  });
};

/**
 * action to fetch categories
 *
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchCategoriesAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.courses
      .categories()
      .then((resources) => {
        dispatch("setCategoriesData", resources);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * change filter categories action
 *
 * @param dispatch
 * @param getters
 * @param payload
 */
export const setFilterCategoriesAction = ({ dispatch, getters }, payload) => {
  const data = Object.assign(getters.getFilter, {
    categories: payload,
  });

  dispatch("setFilterData", data);
};

/**
 * change filter categories action
 *
 * @param dispatch
 * @param getters
 * @param payload
 */
export const setPriceFilerAction = ({ dispatch, getters }, payload) => {
  const data = Object.assign(getters.getFilter, {
    value: payload,
  });
  dispatch("setFilterData", data);
};

/**
 * change filter categories action
 *
 * @param dispatch
 * @param getters
 * @param payload
 */
export const setSearchFilerAction = ({ dispatch, getters }, payload) => {
  const data = Object.assign(getters.getFilter, {
    q: payload,
  });
  dispatch("setFilterData", data);
};
/**
 * change filter categories action
 *
 * @param dispatch
 * @param getters
 * @param payload
 */
export const resetAllFilterAction = ({ dispatch, getters }) => {
  const data = Object.assign(getters.getFilter, {
    q: null,
    value: null,
    categories: null,
  });
  dispatch("setFilterData", data);
};

export const clearBadgeAction = ({ dispatch, getters }, { type, value }) => {
  const data = Object.assign(getters.getFilter, {});

  if (type === "categories") {
    let filted = filter(data.categories, (category) => category !== value);
    data.categories = filted.length ? filted : null;
  } else {
    data[type] = null;
  }
  dispatch("setFilterData", data);
};

/**
 * action to fetch course description
 *
 * @param context
 * @param slug
 * @returns {Promise<unknown>}
 */
export const fetchCourseBySlug = (context, { slug }) => {
  return new Promise((resolve, reject) => {
    services.courses
      .courses_show(null, { slug })
      .then((resources) => {
        resolve(resources);
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch course description
 *
 * @param context
 * @param id
 * @returns {Promise<unknown>}
 */
export const actionToEnrollCourse = (context, { id }) => {
  return new Promise((resolve, reject) => {
    services.courses
      .enroll({ course_id: id })
      .then((resources) => {
        resolve(resources);
      })
      .catch((error) => reject(error));
  });
};
