/**
 Export all getters from state
*/
import { orderBy } from "lodash";

/**
 * @param notificationsOnRequest
 * @returns {*[]|*}
 */
export const getNotificationsOnRequest = ({ notificationsOnRequest }) => {
  return notificationsOnRequest;
};
/**
 * @param notifications
 * @returns {*[]|*}
 */
export const getAllNotifications = ({ notifications }) =>
  notifications.data ? orderBy(notifications.data, "read_at", "desc") : [];

/**
 * @param notifications
 * @returns {*[]|*}
 */
export const getUnreadNotifications = ({ notifications }) => {
  if (notifications.data) {
    return notifications.data.filter(
      (notification) => notification.read_at === null
    );
  }
  return [];
};
