import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_PARTNER](state, payload) {
    state.partner = payload;
  },
  /**
   * @param state
   * @param payload
   */
  [types.SET_PARAMETERS](state, payload) {
    state.parameters = payload;
  },
  /**
   * @param state
   * @param payload
   */
  [types.SET_CUSTOMER_SERVICE](state, payload) {
    state.customer_service = payload;
  },
};
