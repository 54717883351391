import services from "@/shared/http";
import * as types from "./mutation-types";
import { formatPeriod } from "@/shared/helpers/numbersManipulation";

/**
 * set questions action
 *
 * @param commit
 * @param payload
 */
export const setOrderData = ({ commit }, payload) => {
  commit(types.SET_ORDERS, payload);
};

export const setPeriodValue = ({ commit }, { period }) => {
  commit(types.SET_PERIOD, formatPeriod(period));
};

/**
 * set questions action
 *
 * @param commit
 * @param in_request
 */
export const setInRequest = ({ commit }, in_request) => {
  commit(types.SET_IN_REQUEST, in_request);
};

export const setShowCycle = ({ commit }, payload) => {
  commit(types.SET_ORDER_CYCLE, payload);
};

/**
 * action to fetch question
 */
export const fetchOrdersAction = (
  { dispatch, getters, state, rootGetters },
  payload
) => {
  return new Promise((resolve, reject) => {
    if (!getters.getHasPeriod) dispatch("setPeriodValue", { period: 7 });
    dispatch("setInRequest", true);

    const commonPayload = {
      partner_id: rootGetters["auth/userPartnerId"],
      orderby: "created_at|desc",
      include_cancelled: true,
    };

    let params = payload.payload
      ? {
          ...payload,
          ...commonPayload,
          page: payload.page,
        }
      : {
          ...state.period,
          ...commonPayload,
          page: payload.page,
        };

    if (payload.company_id) params.company_id = payload.company_id;

    services.sales
      .orders(params)
      .then((resources) => {
        dispatch("setOrderData", resources);
        resolve(resources);
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setInRequest", false));
  });
};

export const fetchParametersByKeys = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .params(payload)
      .then((res) => {
        dispatch("setShowCycle", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
