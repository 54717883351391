/**
 * Notifications routes
 */
export default [
  {
    path: "notifications",
    name: "notifications",
    component: () => import("./index"),
    meta: {
      label: "menu.notifications",
      icon: "notifications",
    },
  },
];
