import { baseUrlMissions } from "@/shared/http/api";

/**
 * @param missions
 */
export const getMissions = ({ missions }) => missions;

/**
 * @param subs_last
 */
export const getSubsLast = ({ subs_last }) => subs_last || 1;

/**
 * @param not_subs_missions
 */
export const getNotSubsMissions = ({ not_subs_missions }) =>
  not_subs_missions || [];

/**
 * @param finished_missions
 */
export const getFinishedMissions = ({ finished_missions }) =>
  finished_missions || [];

/**
 * @param not_subs_last
 */
export const getNotSubsLast = ({ not_subs_last }) => not_subs_last || 1;

export const getFinishedLast = ({ finished_last }) => finished_last || 1;

/**
 * @param mission
 */
export const getShowMission = ({ mission }) => mission || {};

/**
 * @param subs_questions
 */
export const getSubsQuestions = ({ subs_questions }) => subs_questions || {};

/**
 * @param finished_details
 */
export const getFinishedDetails = ({ finished_details }) =>
  finished_details || {};

/**
 * @param public_missions
 */
export const getPublicMissions = ({ public_missions }) => public_missions || {};

/**
 * @param public_missions
 */
export const getPublicMissionsFlipDash = ({ public_missions }) => {
  if (Object.keys(public_missions).length > 0)
    return public_missions.data || [];
};

/**
 * @param public_finished
 */
export const getPublicFinished = ({ public_finished }) => public_finished || {};

/**
 * @param show_public_mission
 */
export const getShowPublicMission = ({ show_public_mission }) =>
  show_public_mission || {};

/**
 * @param public_header
 */
export const getPublicHeader = ({ public_header }) => public_header || [];

/**
 * @param mission_terms
 */
export const getMissionTerms = ({ mission_terms }) => mission_terms || {};

/**
 * @param avaible_missions
 */
export const getAvaibleMissions = ({ avaible_missions }) =>
  avaible_missions || [];

/**
 * @param temporary_upload_links
 */
export const getTemporaryUploadLinks = ({ temporary_upload_links }) =>
  temporary_upload_links || {};

export const getDownloadMissionTermConf = (state, getters, rootState) => {
  return {
    headers: {
      Authorization: `Bearer ${rootState.auth.token}`,
      responseType: "blob",
    },
    url: `${baseUrlMissions}/api/v1/terms/download-signed-term`,
    method: "POST",
  };
};
