export default {
  welcome: "Login para {name}",
  submit: "Acessar",
  connect_with: "Conecte-se com: ",
  create_with: "Cadastre-se com: ",
  forgetPassword: "Esqueci Minha Senha",
  problemLoggingAccount: "Problemas para entrar na sua conta?",
  createAccount: "Criar uma conta",
  recover: {
    error: {
      confirmation: "Não foi possível confirmar a sua identidade",
    },
    channel_option: {
      header: "Escolha como quer receber o código de validação.",
      whatsapp: "WhatsApp",
      sms: "SMS",
      on_whatsapp: "No celular terminado em ",
      on_sms: "No celular terminado em ",
      on_email: "No e-mail terminado em ",
      email: "E-mail",
    },
    change_password: {
      orientation_first:
        "Agora, digite e confirme a sua nova senha, Mas lembre-se de memorizar ou salvá-la em um lugar seguro onde apenas você tenha acesso.",
      orientation_second:
        "Memorize a senha digitada abaixo, na próxima tela de login, digite seu e-mail no campo usuário e senha digitada abaixo no campo senha.",
    },
    submit: "Enviar",
    title: "Recuperação de senha",
    titleConfirm: "Confirme o código",
    titleRecovery: "Digite sua nova senha",
    orientation:
      'O código será usado para recuperar a sua conta, foi enviado por e-mail no endereço informado. Verifique a sua "Caixa de Entrada" ou até mesmo a caixa de "Spam".',
    orientation_text:
      "Por gentileza, digite o código recebido, para que seja possível criar uma nova senha.",
    orientation_wpp:
      "Se contato realmente estiver cadastrado em nosso sistema, você receberá um código em seu Whatsapp para continuar com a recuperação",
    orientation_sms:
      "Se contato realmente estiver cadastrado em nosso sistema, você receberá um código em seu celular para continuar com a recuperação",
  },
  signUp: {
    would_like_to_access: "Gostaria de acessa-lo ?",
    back_to_start: "Voltar ao inicio",
    unsuccessfully_title: "Opps, não conseguimos fazer seu cadastro",
    unsuccessfully_description:
      "Algo inesperado aconteceu no nosso serviço, e ainda não sabemos a causa, mas a nossa equipe foi avisada!",
    unsuccessfully_try_latter: "Tente novamente mais tarde, pode ser ?",
    u_have_account: "Você já possui uma conta ?",
    when_create_u_agree: "Ao criar sua conta conta, você concorda com os",
    client_services_terms: "Termos de uso.",
    physical: "Pessoa fisica",
    juridic: "Pessoa juridica",
    create_with_facebook: "Cadastre-se com o facebook",
    create_with_google: "Cadastre-se com o google",
    next: "Proximo",
    return: "Voltar",
    whats_unique_identify: "O que é um identificador único?",

    document_pj:
      "Ei, é preciso que seu CNPJ esteja ativo e regular. As comissões serão pagas através de emissão de nota fiscal. Fique atento! ;)",
    instagram:
      "É preciso que sua rede social esteja de acordo com nossas políticas de comunicação, sem posts ofensivos, discriminatórios ou que incitem a violência. Caso seja identificado algo fora destes critérios, o cadastro da marca se reserva o direito de retirá-lo do programa.",
    instagram_req:
      "É preciso que sua rede social esteja de acordo com nossas políticas de comunicação, sem posts ofensivos, discriminatórios ou que incitem a violência. Caso seja identificado algo fora destes critérios, o cadastro da marca se reserva o direito de retirá-lo do programa.",
  },
};
