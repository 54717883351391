export default {
  card_user: {
    my_account: "Meus dados",
    id: "ID: {id}",
    install_pwa: "Instalar aplicativo",
    how_to_earn: "Como Ganhar Mais Créditos",
    my_points: "Meus Créditos: <strong>{value}</strong>",
    exit: "Sair",
  },
  greeting: "<b>{time}</b>, {name}",
  pending_docs:
    "Seu perfil está incompleto! É de extrema importância que complete seu cadastro para que você possa realizar futuros resgates.",
  pending_docs_message:
    "Complete seu cadastro para que você possa realizar futuros resgates!",
  click_to_Show: "Clique para visualizar",
  title: "Dashboard",
  visitors: "Visitantes",
  voucher_title: "Voucher",
  invite_title: "Você tem { value } convites",
  invite_help: "Últimos 5 lançamentos realizados",
  last_points_released: "Últimos lançamentos",
  order_quantity: "Pedidos",
  commission_opened: "Bonificações",
  share_now_title: "Divulgue agora",
  order_sum: "Total de vendas",
  products_title: "Meus Produtos",
  graph_title: "Gráfico de visitas na sua loja",
  no_orders_in_the_period: "Nenhum pedido no período",
  campaigns_wpp: "Companhas no whatsapp",
  campaigns_insta: "Companhas no Instagram",
  how_to_share: "Como divulgar",
  tooltip_text:
    "Esses são os visitantes recebidos na sua loja comparado com o período anterior.",
  explaining_text:
    "Visitantes recebidos na sua área de loja. Gráfico compara com o período anterior das visitas na sua loja.",
  credits_analysis: "Os créditos em análise são aqueles que aguardam confirmação. Algumas empresas esperam alguns dias para garantir que não haja cancelamento ou outros problemas com o pedido.",
  no_voucher_for_show: "Nenhum cupom para mostrar por enquanto",
  no_voucher_number: "Sem cupom",
  more_info: "(Ver detalhes)",
  tooltip_message: {
    reward_points_received: "Histórico de créditos recebidos (a comissão é calculada sobre o valor dos produtos menos o valor do frete)",
    reward_total_sales:
      "Total de vendas que foram atualizadas como faturadas ou aprovadas na sua loja no período selecionado (O total considera o valor das vendas após deduzir o frete)",
    reward_points_rescue:
      "Total de créditos que você solicitou o resgate por meio de dinheiro, voucher ou vale presente (gift card)",
    reward_points_balance:
      "Total de créditos disponíveis para você resgatar, referente as suas vendas e ações na plataforma",
    reward_points_expired_soon: "Créditos que vão expirar nos próximos meses.",
    reward_points_expired: 'Créditos já expirados ou que podem expirar nos próximos meses',
    approved_sales: "Os créditos podem levar até 7 dias para serem disponibilizados.",
  },
  reward_extract_button: "Ver extrato",
  reward_sales_button: "Ver vendas",
  reward_rescue_button: "Resgatar créditos",
  reward_no_points_message:
    "Você não possui créditos disponíveis para resgate.",
  reward_points_received: "Créditos Recebidos",
  reward_total_sales: "Total de vendas",
  reward_points_rescue: "Créditos Resgatados",
  reward_points_expired_soon: "Créditos a expirar",
  reward_points_expired: "Créditos Expirados",
  reward_points_balance: "Créditos disponíveis para resgate",
  graph_points_title: "Gráfico de créditos diário",

  flip: {
    my_companies: "Marcas",
    opportunities: "Missões ativas",
    last_missions: "Últimas missões",
    missions_msg:
      "Participe de campanhas e seja remunerado com produtos, créditos e até experiências!",
    missions_not_found: "Não há missões disponíveis no momento.",

    not_participate: "Você ainda não se cadastrou em nenhum programa.",
    no_opportunities: "Não há oportunidades disponíveis no momento.",

    subscribe_msg:
      'Você está prestes a inscrever-se no programa "{name}". Após a inscrição, seu perfil será avaliado e, se aprovado, você receberá um e-mail de confirmação. Você pode acompanhar o status da sua inscrição em <b>"Minhas marcas"</b>.',

    subscribe_msg_confirm:
      "Seu cadastro foi realizado com sucesso! Agora você pode acompanhar o status da sua inscrição em <b>Minhas marcas</b>",

    cards: {
      exit: "Sair do programa",
      access: "Acessar",
      details: "Detalhes",
      subscribe: "Inscrever-se",

      approved: "Aprovado",
      pending: "Em análise",
      send_docs: "Enviar <br/> documentos",

      no_biography:
        'Você está prestes a participar do programa "{company}". Após realizar a inscrição, seu perfil passará por um processo de análise e aprovação. Fique tranquilo, pois você poderá acompanhar o status em "Minhas marcas". Se a aprovação NÃO for imediata, você será notificado por e-mail quando a sua participação for liberada.',
    },
    my_companies_tooltip:
      "Encontre marcas para se tornar parceiro. Nesta fase BETA, você poderá inscrever-se em apenas algumas marcas. <br/>Acesse clicando em <b>Minhas marcas</b>.",

    my_companies_help: `Caso você já tenha cadastro em uma das marcas abaixo, clique em "inscrever-se" para que o sistema traga suas informações para esse espaço.`,
  },

  drive: {
    title: "Materiais de divulgação",
    description: `Navegue pelos diretórios dos materiais de divulgações
    da marca e gere vendas e engajamento, os divulgando
    nos seus canais de comunicação e redes sociais.`,
    tooltip_text:
      "Gere mais receita e engajamento divulgando os materiais da marca",
  },

  networks: {
    facebook: "https://www.facebook.com/{value}",
    instagram: "https://www.instagram.com/{value}",
    youtube: "https://www.youtube.com/{value}",
    linkedin: "https://www.linkedin.com/company/{value}",
    tiktok: "https://www.tiktok.com/@{value}",
  },

  img_no_content: "Imagem não disponível",

  cant_subs_alert: `Verificamos que você não está participando do programa <b>{ programa }</b>. Mas não se preocupe! Você poderá prosseguir após a inscrição. Inscreva-se na área <b>"Marcas"</b>.`,
  subs_progress_alert: `Verificamos que seu cadastro está <b>Em análise</b> na marca <b>{ programa }</b>. Fique atento(a), assim que for aprovado(a) poderá visualizar mais informações sobre as missões da marca.`,
  redirect_alert: `Você será redirecionado para a página de detalhes dessa missão, para isso realizaremos seu login no programa na qual ela pertence.`,
};
