import { baseURL } from "@/shared/http/api";
import { formatDate } from "@/shared/helpers/numbersManipulation";

import i18n from "@/i18n/wrappers/i18n";
const t = i18n.global.t;

/**
 * @param state
 * @param getters
 * @param rootState
 * @returns {{}}
 */
export const getUserAccount = (state, getters, rootState) =>
  rootState.auth.user;

/**
 * @param state
 * @param getters
 * @param rootState
 * @returns {{}}
 */
export const getPartnerAccount = (state, getters, rootState) =>
  rootState.auth.user.partner || {};

/**
 *
 * @param state
 * @param getters
 * @param rootState
 * @return {string|*|{}}
 */
export const getPartnerBankAccount = (state, getters, rootState) => {
  let partner = rootState.auth.user.partner || {};
  return partner.bank_account || {};
};
/**
 *
 * @param state
 * @param getters
 * @param rootState
 * @return {string|*|{}}
 */
export const getPartnerPixAccount = (state, getters, rootState) => {
  let partner = rootState.auth.user.partner || {};
  return partner.pix_account || {};
};

export const getPartnerNetworks = (state, getters) => {
  let partner = getters.getPartnerAccount,
    networks = {};

  if (partner && partner.social_networks) {
    Object.keys(partner.social_networks).forEach((key) => {
      let nt = partner.social_networks[key];
      networks[nt.network] = nt.content;
    });
  }

  return networks;
};

export const getBanks = (state) => state.banks;

export const getExtChallengeSecret = (state) =>
  state.challenge_ext_secret || "";

export const getBanksToOptions = (state) => {
  if (state.banks) {
    return state.banks
      .map((bank) => ({
        value: bank.codigo,
        label: `${bank.codigo} - ${bank.nome}`,
      }))
      .filter((bank) => {
        let needle = state.bank_filter_need;
        return (
          state.bank_filter_need === "" ||
          bank.label.toLowerCase().indexOf(needle) > -1
        );
      });
  }

  return [];
};

/**
 * @param state
 * @param getters
 * @param rootState
 * @return {{headers: {Authorization: string}, method: string, url: string}}
 */
export const getImageUploadConf = (state, getters, rootState) => {
  return {
    headers: {
      Authorization: `Bearer ${rootState.auth.token}`,
    },
    url: `${baseURL}/api/v1/partner/upload-photo`,
    method: "POST",
    field: "photo",
  };
};

export const getUserDocuments = (state) => state.documents;

export const getDocTypes = (state) => state.doc_types;

export const getParams = (state) => state.params;

export const getPartnerChallengesHash = ({ partner_challenges }) =>
  partner_challenges.link_challenges
    .filter((e) => e.channel == "email")
    .map((e) => ({ email_hash: e.hash }))[0].email_hash || [];

export const getPartnerChallenges = ({ partner_challenges }) => {
  const cha = partner_challenges.link_challenges;

  let challenges = cha
    ? cha.map((ch) => (ch.channel == "sms" ? "celular" : ch.channel))
    : [];

  const data = [
    {
      label: "Email",
      icon: "email",
      type: "email",
      value: partner_challenges.email_principal,
      isValidate: challenges.includes("email"),
      ...(challenges.includes("email")
        ? {
            validated: partner_challenges.email_validated_at ? true : false,
            validated_at: partner_challenges.email_validated_at
              ? "Verificado em " +
                formatDate(partner_challenges.email_validated_at)
              : "Não verificado",
          }
        : {}),
    },
    {
      label: "Celular",
      type: "celular",
      icon: "smartphone",
      mask: "(##) #####-####",
      placeholder: "(00) 00000-0000",
      value: partner_challenges.celular,
      isValidate: challenges.includes("celular"),
      ...(challenges.includes("celular")
        ? {
            validated: partner_challenges.celular_validated_at ? true : false,
            validated_at: partner_challenges.celular_validated_at
              ? "Verificado em " +
                formatDate(partner_challenges.celular_validated_at)
              : "Não verificado",
          }
        : {}),
    },
  ];

  return data;
};

export const getChallengeCode = (state) => state.challenge_hash;

export const getDownloadLastTermConf = (state, getters, rootState) => {
  return {
    headers: {
      Authorization: `Bearer ${rootState.auth.token}`,
      responseType: "blob",
    },
    url: `${baseURL}/api/v1/terms/lastest-signed-term-download`,
    method: "POST",
  };
};

export const getParameterizedRegistrationSteps = (state) => {
  if (!Object.keys(state.parameterized_registration_param).length) return {};

  // remove in_review_step
  delete state.parameterized_registration_param.in_review_step;

  let commonSteps = Object.keys(
    state.parameterized_registration_param.common_steps
  ).filter(
    (step) => state.parameterized_registration_param.common_steps[step] === true
  );

  const customizableSteps = Object.keys(
    state.parameterized_registration_param.customizable_steps
  );

  const AllSteps = commonSteps.concat(customizableSteps);

  // document_step needs to be at the end
  if (AllSteps.includes("document_step")) {
    AllSteps.splice(AllSteps.indexOf("document_step"), 1);
    AllSteps.push("document_step");
  }

  return AllSteps;
};

export const getParameterizedRegistrationInRevStep = (state) => {
  if (!Object.keys(state.parameterized_registration_param).length) return null;
  return state.parameterized_registration_param.in_review_step;
};

export const getParameterizedRegistrationStepsLabels = (
  state,
  getters,
  rootState
) => {
  const pf_pj = rootState.auth.user.partner.pf_pj;

  if (!Object.keys(state.parameterized_registration_param).length) return {};

  let commonSteps = Object.keys(
    state.parameterized_registration_param.common_steps
  )
    .filter(
      (step) =>
        state.parameterized_registration_param.common_steps[step] === true
    ) // Quando for PF, o label vai ser 'Dados PF', quando for PJ, o label vai ser 'Dados PJ'
    .map((step) =>
      t(
        `signup.parameterized_registration.steps.${
          step == "pf_or_pj_step" ? step + "_" + pf_pj : step
        }`
      )
    );

  const customizableSteps = Object.values(
    state.parameterized_registration_param.customizable_steps
  ).map((step) => step.step_title);

  const AllSteps = commonSteps.concat(customizableSteps);

  // document_step needs to be at the end
  if (AllSteps.includes("Documentos")) {
    AllSteps.splice(AllSteps.indexOf("Documentos"), 1);
    AllSteps.push("Documentos");
  }

  return AllSteps;
};

export const getCustomizableSteps = (state) => {
  if (!Object.keys(state.parameterized_registration_param).length) return {};
  return state.parameterized_registration_param.customizable_steps;
};
