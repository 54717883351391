import services from "@/shared/http";
import * as types from "./mutation-types";

/**
 * set notifications action
 *
 * @param commit
 * @param payload
 */
export const setNotificationsAction = ({ commit }, payload) => {
  commit(types.SET_NOTIFICATIONS, payload);
};
/**
 * set notifications on request action
 *
 * @param commit
 * @param on
 */
export const setNotificationsOnRequestAction = ({ commit }, on) => {
  commit(types.SET_NOTIFICATIONS_ON_REQUEST, on);
};

/**
 * action to fetch question
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchNotificationsAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    dispatch("setNotificationsOnRequestAction", true);
    services.notifications
      .fetchNotifications()
      .then((notifications) => {
        dispatch("setNotificationsAction", notifications);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => {
        dispatch("setNotificationsOnRequestAction", false);
      });
  });
};

/**
 * set notify as ready
 *
 * @param dispatch
 * @param state
 * @param notification
 * @return {*}
 */
export const putNotificationAsReadAction = (
  { dispatch, state },
  notification
) => {
  return new Promise((resolve, reject) => {
    const binds = { notifyId: notification.id };

    services.notifications
      .putNotificationAsRead(null, binds)
      .then(() => {
        let notifications = state.notifications;
        notifications.data = notifications.data.map((nt) => {
          if (nt.id === notification.id) {
            nt.read_at = new Date();
          }
          return nt;
        });
        dispatch("setNotificationsAction", notifications);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
