import { baseURL } from "@/shared/http/api";

export default {
  /**
   * @returns {{method: string, url: string}}
   */
  getProductsByCategory: () => ({
    method: "POST",
    url: "/api/v1/p/products/queryProdCat/{parentSlug}/{slug}",
    baseURL: baseURL,
  }),


  getProductsByCompany: () => ({
    method: "POST",
    url: "/api/v1/p/products/queryProdCompany/{parentSlug}/{slug}",
    baseURL: baseURL,
  }),

  /**
   * @returns {{method: string, url: string}}
   */
  getEcommCustomization: () => ({
    method: "GET",
    url: "/api/v2/parceiro/ecomm/customization/{slug}/{parentSlug}",
    baseURL: "https://admin.flip.net.br",
  }),

  getCustomLayout: () => ({
    method: "GET",
    url: "/api/v1/parameters/p/{parentSlug}/custom_layout",
    baseURL: baseURL
  }),

  getLinksAndVouchers: () => ({
    method: "GET",
    url: "/api/v1/dash/p/overview/flipnet/{parentSlug}?filter[fields]=vouchers,linkStores",
    baseURL: baseURL
  }),
};
