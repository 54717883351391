/**
 * By exposing all services used in the module,
 * they are written by axios, responsible for the http control,
 * and are exposed to be used as metadata returning a promise
 */
import { baseUrlAdmin } from "@/shared/http/api";

export default {
  /**
     * check parent endpoint
     *
     * @api-version 1

     * @returns {{method: string, url: string}}
     */
  parentBySlug: () => ({ method: "GET", url: "api/v1/partner/small-info" }),

  /**
   * get partner endpoint
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  parent: () => ({ method: "GET", url: "api/v1/partner/parent" }),

  /**
   * @returns {{method: string, url: string}}
   */
  params: () => ({ method: "GET", url: "api/v1/parameters" }),

  /**
   * @returns {{method: string, url: string}}
   */
  updatePartner: () => ({ method: "PUT", url: "api/v1/partner" }),

  /**
   * @returns {{method: string, url: string}}
   */
  customerService: () => ({
    method: "GET",
    url: "api/v1/customer-service",
    baseURL: baseUrlAdmin,
  }),
};
