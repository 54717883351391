/**
 * all states from store
 */
export default {
  layout: {},
  cluster_and_share: {},
  cluster_partner_info: {},
  show_up: null,
  show_up_watched: false,
  order_totals: null,
  partners_accepted: null,
  visitors_totals: null,
  visitor_graph: null,
  commission_opened: null,
  commission_paid: null,
  voucher: null,
  invites: null,
  drives: null,
  date_range: null,
  ecomm: null,
  spread: null,
  on_request: false,
  reward_total_orders: null,
  reward_total_sales: null,
  reward_credits_under_analysis: null,
  reward_points_received: null,
  reward_points_rescue: null,
  reward_points_expired_soon: null,
  reward_points_expired: null,
  reward_points_balance: null,
  reward_ranking: null,
  reward_points_range: null,
  visitors_data: null,
  banner: [],
  doc_pending_dash: {},
  companies_list: [],
  companies_registered_list: [],
  flip_thumb: [],
  dash_image: [],

  all_links: [],
  all_vouchers: [],

  points_sumary: {},

  in_requests_dash: {
    companies: true,
    totalSales: true,
    pointsSummary: true,
  },
  has_scripts: false,
  not_associated_accs: [],

  // UNIFIED SERVICES
  overview: {},
};
