import { orderBy } from "lodash";
/**
 * @param state
 * @returns {{}}
 */
export const getChecklists = (state) => {
  return orderBy(orderBy(state.checklists, "sequence"), "checked");
};

/**
 * @param show_latter
 * @returns {*}
 */
export const isShowLatter = ({ show_latter }) => {
  return show_latter;
};
