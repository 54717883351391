/**
 * Me store routes
 */
export default [
  {
    path: "me/store",
    name: "me.store",
    component: () => import("./index"),
    meta: {
      sort: -1,
      visible: true,
      label: "menu.me_store",
      icon: "store",
    },
  },
];
