/**
 Export all getters from state
*/

/**
 * @param opened
 * @returns {*}
 */
export const getOpenedSummary = ({ opened }) => {
    return opened.summary || {}
}

/**
 * @param opened
 * @returns {*}
 */
export const getOpenedMovements = ({ opened }) => {
    return opened.details.data || {}
}

/**
 * @param openedInRequest
 * @returns {*}
 */
export const getOpenedInRequest = ({ openedInRequest }) => {
    return openedInRequest || false
}


/**
 * @param opened
 * @returns {*}
 */
export const getOpenedPaginationResource = ( { opened } ) => {
    let pagination = Object.assign({}, opened.details || {})
    delete pagination.data
    return pagination;
}


/**
 * @param opened
 * @returns {*}
 */
export const getClosedSummary = ({ closed }) => {
    return closed.summary || {}
}

/**
 * @param opened
 * @returns {*}
 */
export const getClosedParams = ({ closed }) => {
    return closed.params || {}
}
/**
 * @param opened
 * @returns {*}
 */
export const getClosedClosure = ({ closed }) => {
    return closed.closure || {}
}

/**
 * @param opened
 * @returns {*}
 */
export const getClosedMovements = ({ closed }) => {
    if(closed.closure){
        return closed.closure.commissions || {}
    }

    return []
}

/**
 * @param openedInRequest
 * @returns {*}
 */
export const getClosedInRequest = ({ closedInRequest }) => {
    return closedInRequest || false
}

/**
 * @param opened
 */
export const getClosedDate = (state, getters) => {
    if(getters.getOpenedSummary.due_date){
        return getters.getOpenedSummary.due_date
    }
    else return null
}


export const getPdfLink = ({ pdfLink }) => {
    return pdfLink || ""
}