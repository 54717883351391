import { baseUrlAdmin } from "@/shared/http/api";
import axios from "axios";

const CUSTOM_JWT_CACHE_KEY = "custom_jwt";

export const setCustomJwt = (jwt) =>
  localStorage.setItem(CUSTOM_JWT_CACHE_KEY, jwt);

export const getCustomJwt = () => localStorage.getItem(CUSTOM_JWT_CACHE_KEY);

export const removeCustomJwt = () =>
  localStorage.removeItem(CUSTOM_JWT_CACHE_KEY);

export const fetchConfig = (method, endpoint, payload = {}) => {
  const jwt = getCustomJwt();

  let conf = {
    method,
    url: `${baseUrlAdmin}/${endpoint}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  if (method !== "GET" && Object.keys(payload).length) conf.data = payload;

  return axios(conf);
};

export const uploadServices = {
  userDocs: () => fetchConfig("GET", "api/v1/partner-doc/list"),
  docTypes: () => fetchConfig("GET", "api/v1/partner-doc-type/list"),
  fetchTempUploadUrl: (payload) =>
    fetchConfig("POST", "api/v1/generate-temporary-upload-url", payload),
  fetchCustomUploadDocs: (payload) => {
    return fetchConfig("POST", "api/v1/partner-doc", payload);
  },
  fetchDeleteDoc: (id) => fetchConfig("DELETE", `api/v1/partner-doc/${id}`),
};
