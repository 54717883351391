import services from "@/shared/http";
import * as types from "@/modules/main/pages/course/store/mutation-types";
import {
  findNextContentAvailable,
  filterSection,
} from "@/modules/main/pages/course/store/helpers";

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const setOpenedCourse = ({ commit, dispatch, state }, payload) => {
  dispatch("startClassAction", {
    enrollment_id: state.course.id,
    content_id: payload.id,
  });
  commit(types.SET_COURSE_OPENED, payload);
};

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const setSchoolClassToCourseContent = ({ commit, state }, payload) => {
  let newCourse = state.course;

  newCourse.sections.forEach((section, sectionKey) => {
    section.contents.forEach((content, keyContent) => {
      if (content.id === payload.content_id) {
        newCourse.sections[sectionKey].contents[
          keyContent
        ].schoolclass = payload;
      }
    });
  });

  commit(types.SET_COURSE, newCourse);
};

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const setOpenedCourseSchoolClass = ({ commit, state }, payload) => {
  if (state.course_opened.schoolclass === null) {
    commit(
      types.SET_COURSE_OPENED,
      Object.assign(state.course_opened, { schoolclass: payload })
    );
  }
};

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const setCourse = ({ commit }, payload) => {
  commit(types.SET_COURSE, payload);
};

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const setCourseFinished = ({ commit }, payload) => {
  commit(types.SET_FINISH_COURSE, payload);
};

/**
 * set course opened action
 *
 * @param commit
 * @param payload
 */
export const nextCourseAction = ({ commit, state, dispatch }) => {
  let content = findNextContentAvailable(filterSection(state.course.sections));
  if (content) {
    dispatch("setOpenedCourse", content);
    commit(types.SET_NEXT_COURSE_COUNT, state.count_course_next + 1);
  } else dispatch("setCourseFinished", true);
};

/**
 * action to fetch course resource
 *
 * @param context
 * @param payload
 * @returns {*}
 */
export const fetchCourseItems = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.course
      .course({}, payload)
      .then((response) => {
        let dt = { ...response.course, ...response };
        delete dt.course;
        dispatch("setCourse", dt);
        resolve(dt);
      })
      .catch((error) => reject(error));
  });
};
/**
 * action to fetch course resource
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const startClassAction = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.course
      .startClass(payload)
      .then((response) => {
        dispatch("setOpenedCourseSchoolClass", response);
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch course resource
 *
 * @param context
 * @param payload
 * @returns {*}
 */
export const finishClassAction = ({ state, dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    let schoolclass = state.course_opened.schoolclass,
      action = () => {
        let data = { state: { status: payload ? payload.status || 100 : 100 } },
          bindUrl = { class_id: schoolclass.id };
        services.course
          .finishClass(data, bindUrl)
          .then(async (response) => {
            await dispatch("setSchoolClassToCourseContent", {
              ...schoolclass,
              finished_at: true,
            });
            await dispatch("nextCourseAction");
            resolve(response);
          })
          .catch((error) => reject(error));
      };

    if (!schoolclass) {
      return dispatch("startClassAction", {
        enrollment_id: state.course.id,
        content_id: state.course_opened.id,
      })
        .then((resource) => {
          schoolclass = resource;
          dispatch("setSchoolClassToCourseContent", schoolclass);
          return action();
        })
        .catch((error) => reject(error));
    } else {
      return action();
    }
  });
};

/**
 * action to fetch Questionnaire resource
 *
 * @param context
 * @param payload
 * @returns {*}
 */
export const fetchQuestionnaireItems = (context, payload) => {
  return new Promise((resolve, reject) => {
    services.course
      .questions({}, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

/**
 * @param state
 * @param payload
 * @returns {*}
 */
export const classScoreTestAction = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    let schoolclass = state.course_opened.schoolclass;
    services.course
      .classScoreTest(
        { question_answers: payload },
        { class_id: schoolclass.id }
      )
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

/**
 * @param state
 * @param payload
 * @returns {*}
 */
export const classScoreSaveAction = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    let schoolclass = state.course_opened.schoolclass;
    services.course
      .classScoreSave(
        { question_answers: payload },
        { class_id: schoolclass.id }
      )
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch course description
 *
 * @param context
 * @param id
 * @returns {Promise<unknown>}
 */
export const fetchCertificate = (context, { slug }) => {
  return new Promise((resolve, reject) => {
    services.course
      .certificate(null, { slug })
      .then((resources) => {
        resolve(resources);
      })
      .catch((error) => reject(error));
  });
};

/**
 * action to fetch course description
 *
 * @param context
 * @param id
 * @returns {Promise<unknown>}
 */
export const fetchAttachment = (context, { id }) => {
  return new Promise((resolve, reject) => {
    services.course
      .attachment(null, { id })
      .then((resources) => {
        resolve(resources);
      })
      .catch((error) => reject(error));
  });
};
