import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_ORDERS](state, payload) {
    state.orders = payload;
  },

  /**
   * @param state
   * @param in_request
   */
  [types.SET_IN_REQUEST](state, in_request) {
    state.in_request = in_request;
  },

  /**
   * set period start and end date
   *
   * @param state
   * @param created_at_start
   * @param created_at_end
   */
  [types.SET_PERIOD](state, { created_at_begin, created_at_end }) {
    state.period = { created_at_begin, created_at_end };
  },

  [types.SET_ORDER_CYCLE](state, payload) {
    state.order_cycle = payload;
  },
};
