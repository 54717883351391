/**
 * all states from store
 */
export default {
  reward_info: [],
  in_request: false,
  trade_on_request: false,
  all_rewards: [],
  last_rewards_opened: [],
  rewards_closed: [],
  rewards_opened: [],
  rewards_statuses: [],
  gift_cards: [],
  gift_card_suppliers: [],
  gift_card_categories: [],
  gift_card_departments: [],
  pre_paid_card_info: {},
  reward_elegible: {},
};
