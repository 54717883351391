import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param reward
   */
  [types.SET_REWARD_INFO](state, reward) {
    state.reward_info = reward;
  },
  /**
   * @param state
   * @param in_request
   */
  [types.SET_IN_REQUEST](state, in_request) {
    state.in_request = in_request;
  },
  /**
   * @param state
   * @param all_rewards
   */
  [types.SET_ALL_REWARDS](state, all_rewards) {
    state.all_rewards = all_rewards;
  },
  /**
   * @param state
   * @param all_rewards
   */
  [types.SET_LAST_OPENED_REWARDS](state, last_rewards_opened) {
    state.last_rewards_opened = last_rewards_opened;
  },
  /**
   * @param state
   * @param in_request
   */
  [types.SET_TRADE_IN_REQUEST](state, in_request) {
    state.trade_on_request = in_request;
  },
  /**
   * @param state
   * @param rewards_closed
   */
  [types.SET_CLOSED_REWARDS](state, rewards_closed) {
    state.rewards_closed = rewards_closed;
  },
  /**
   * @param state
   * @param rewards_statuses
   */
  [types.SET_REWARDS_STATUSES](state, rewards_statuses) {
    state.rewards_statuses = rewards_statuses;
  },
  /**
   * @param state
   * @param rewards_opened
   */
  [types.SET_OPENED_REWARDS](state, rewards_opened) {
    state.rewards_opened = rewards_opened;
  },
  /**
   * @param state
   * @param gift_cards
   */
  [types.SET_GIFT_CARDS](state, gift_cards) {
    state.gift_cards = gift_cards;
  },
  /**
   * @param state
   * @param gift_card_suppliers
   */
  [types.SET_GIFT_CARD_SUPPLIERS](state, gift_card_suppliers) {
    state.gift_card_suppliers = gift_card_suppliers;
  },
  /**
   * @param state
   * @param gift_card_categories
   */
  [types.SET_GIFT_CARD_CATEGORIES](state, gift_card_categories) {
    state.gift_card_categories = gift_card_categories;
  },
  /**
   * @param state
   * @param gift_card_departments
   */
  [types.SET_GIFT_CARD_DEPARTMENTS](state, gift_card_departments) {
    state.gift_card_departments = gift_card_departments;
  },

  [types.SET_PRE_PAID_CARD_INFO](state, pre_paid_card_info) {
    state.pre_paid_card_info = pre_paid_card_info;
  },

  [types.SET_REWARD_ELEGIBLE](state, reward_elegible) {
    state.reward_elegible = reward_elegible;
  },
};
