import services from "@/shared/http";
import * as types from "./mutation-types";
import { filter } from "lodash";

/**
 * set products action
 *
 * @param commit
 * @param payload
 */
export const setEcommData = ({ commit }, payload) => {
  commit(types.SET_ECOMM, payload);
};

/**
 * set questions action
 *
 * @param commit
 * @param in_request
 */
export const setInRequest = ({ commit }, in_request) => {
  commit(types.SET_IN_REQUEST, in_request);
};

/**
 * set products action
 *
 * @param commit
 * @param payload
 */
export const setMeStoreParams = ({ commit }, payload) => {
  commit(types.SET_ME_STORE_PARAMS, payload);
};

/**
 * set assets action
 *
 * @param commit
 * @param payload
 */
export const setPartnerAssets = ({ commit }, payload) => {
  commit(types.SET_PARTNER_ASSETS, payload);
};

/**
 * action to fetch question
 *
 * @param dispatch
 * @returns {*}
 */
export const fetchEcomm = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    dispatch("setInRequest", true);

    services.me_store
      .getEcomm()
      .then((resources) => {
        dispatch("setEcommData", resources);
        resolve();
      })
      .catch((error) => reject(error))
      .finally(() => dispatch("setInRequest", false));
  });
};

export const storeEcomm = ({ dispatch, state }, payload) => {
  return new Promise((resolve, reject) => {
    let ecomm = Object.assign(state.ecomm, payload);

    services.me_store
      .putEcomm(ecomm)
      .then(() => {
        dispatch("setEcommData", ecomm);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const pushNewImage = ({ dispatch, state }, payload) => {
  const ecomm = state.ecomm;
  ecomm.photos.push(payload);
  dispatch("setEcommData", ecomm);
};

export const deleteEcommPhoto = ({ dispatch, state }, { id }) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .deleteEcommPhoto({ id })
      .then(() => {
        const ecomm = state.ecomm;
        ecomm.photos = filter(ecomm.photos, (photo) => photo.id !== id);
        dispatch("setEcommData", ecomm);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchParametersByKeys = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .params(payload)
      .then((res) => {
        dispatch("setMeStoreParams", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchPartnerStoreAssets = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .getAssetsByPartnerStore(payload)
      .then((res) => {
        dispatch("setPartnerAssets", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchSelectLibraryImage = ({ dispatch }, payload) => {
  return services.me_store.uploadEcommPhoto(payload).then(() => {
    dispatch("fetchPartnerStoreAssets");
  });
};
