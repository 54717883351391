export default [
  {
    path: "my-lists",
    name: "my-lists",
    component: () => import("./index"),
    meta: {
      sort: -1,
      visible: true,
      label: "menu.lading_page",
      icon: "thumb_up",
    },
  },
];
