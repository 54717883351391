/**
 * Faq routes
 */
export default [
  {
    path: "faq",
    name: "faq",
    component: () => import("./index"),
    meta: {
      sort: 9,
      visible: true,
      label: "menu.faq",
      icon: "help",
    },
  },
];
