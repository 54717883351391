/**
 Export all getters from state
*/
/**
 * @param course_opened
 */
export const getCourse = ({ course }) => course;
export const getCourseOpened = ({ course_opened }) => course_opened;
export const getNextCourseCount = ({ count_course_next }) => count_course_next;
export const isCourseFinished = ({ finished }) => finished;
