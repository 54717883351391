export default {
  title: "Minha Bonificação",
  tab_opened: "Em aberto",
  tab_closed: "Fechado",
  unfilledTitle: "Os Seguintes campos do seu cadastro não foram preenchidos",
  unfilledDsc:
    " Clique aqui e corriga até o dia {date} para que seja possivel prosseguir com o pagamento.",
  drives: "movimentações",
  amount_to_receiver: "Saldo a receber",
  amount_to_received: "Total de bônus",
  the_sale: "A venda",
  released_from: "Liberado a partir de",
  released_at: "Data prevista de pagto",
  gross_value: "Valor Bruto",
  pay_at: "Pago em",
  value_bonus: "Valor Bônus",
  order_value: "Valor dos pedidos",
  finance_taxes: "Taixa Financeira",
  minimum_value_reached:
    "Você atingiu o valor mínimo de {label}, você será contemplado na data prevista para o pagamento",
  minimum_value_no_reached:
    "Seu saldo ainda não atingiu o valor mínimo de {label}, mas não se preocupe, o valor é acomulado para o proximo mês",
  to_receiver: "A receber",
  protocol: "Protocolo de Solicitação",
  helper:
    "Essa é uma commisão gerada a partir de uma venda feita por você, aqui está o valor da venda, e o saldo a receber dela",
  upload_nf_fail: "Falha ao enviar a nota fiscal",
  max_file_size:
    "Falha! Arquivo selecionado não é suportado. Selecione um arquivo até 700kb",
    nf_instructions_file_name:"Instuções NF",
    nf_instructions:"Instruções para submissão da NF"
};
