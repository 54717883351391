export default {
    filters: [],
    products: [],
    payloadProducts: {},
    text_search: '',
    is_fetching_filters: false, // É pra buscar novamente os filtros no endpoint?
    is_adding_product_to_favorites: false, // Está adicionando produto aos favoritos?
    parent_ids: [],
    influencer_id: [],
    filter_likes: {},
    filter_keys: {},
    filter_price_range: {},
    order_by: {},
    selectedFilters: {},
    select_all_products: [],
    current_page: 1,
    drawer: false,
    researching_product: false,
    loading_adding_one_product: {
        status: false,
        activeOperationsCount: 0
    },
    loading_searching: false,
    partner_landing_link: null,
    parent_id_to_fetch_filters: null,
    dashboard_centralized: { // Obj usado apenas no Dash Centralizado
        all_companies: [],
        only_companies_ids: [], // ID das empresas do Dash Centralizado
        is_dashboard_centralized: false, // Está no dash centralizado?
        companies_filter: [] // Filtro para filtrar por empresas
    },
    selected_favorites: { // Obj usado apenas nos Favoritos já selecionados
        products: null,
        current_page: 1,
        pagination: null,
        is_loading: false,
        endpoint_type: 'all_products', // all_products, company ou category
        parent_ids: [],
    },
}