const ALCADAS_PREFIX = "cache_alcadas_",
  CHECKLIST_PREFIX = "cache_checklist_",
  CUSTOMER_SERVICE_PREFIX = "cache_customer_service_",
  NOTIFICATION_PREFIX = "cache_notification_",
  PARENT_PREFIX = "cache_parent_",
  USER_PREFIX = "cache_user_",
  MAIN_BANNER = "cache_main_banner_desktop_",
  MAIN_BANNER_MOBILE = "cache_main_banner_mobile_",
  DASH_BANNER = "cache_dash_banner_",
  DASH_BANNER_MOBILE = "cache_dash_banner_mobile_",
  SUMMARY_FLIP = "cache_summary_flip_",
  PUBLIC_BANNER = "cache_company_public_banner_",
  TOTAL_SALES = "cache_total_sales_",
  COMPANY_SCRIPTS = "cache_company_scripts_",
  VOUCHER = "cache_voucher_",
  SPREAD = "cache_spred_",
  UNIFIED_OVERVIEW = "cache_unified_overview_";

export const prefixes = {
  user: USER_PREFIX,
  parent: PARENT_PREFIX,
  alcadas: ALCADAS_PREFIX,
  checklist: CHECKLIST_PREFIX,
  customerService: CUSTOMER_SERVICE_PREFIX,
  notification: NOTIFICATION_PREFIX,
  main_banner_desktop: MAIN_BANNER,
  main_banner_mobile: MAIN_BANNER_MOBILE,
  dash_banner_desktop: DASH_BANNER,
  dash_banner_mobile: DASH_BANNER_MOBILE,
  company_public_banner: PUBLIC_BANNER,
  company_scripts: COMPANY_SCRIPTS,
  voucher: VOUCHER,
  spread: SPREAD,
  total_sales: TOTAL_SALES,
  summary_flip: SUMMARY_FLIP,
  unified_overview: UNIFIED_OVERVIEW,
};
