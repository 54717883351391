/**
 * Map name for mutations
 * @types {string}
 */

export const SET_REWARD_INFO = "REWARD/SET_REWARD_INFO";
export const SET_IN_REQUEST = "REWARD/SET_IN_REQUEST";
export const SET_TRADE_IN_REQUEST = "REWARD/SET_TRADE_IN_REQUEST";
export const SET_ALL_REWARDS = "REWARD/SET_ALL_REWARDS";
export const SET_CLOSED_REWARDS = "REWARD/SET_CLOSED_REWARDS";
export const SET_OPENED_REWARDS = "REWARD/SET_OPENED_REWARDS";
export const SET_LAST_OPENED_REWARDS = "REWARD/SET_LAST_OPENED_REWARDS";
export const SET_REWARDS_STATUSES = "REWARD/SET_REWARDS_STATUSES";
export const SET_GIFT_CARDS = "REWARD/SET_GIFT_CARDS";
export const SET_GIFT_CARD_SUPPLIERS = "REWARD/SET_GIFT_CARD_SUPPLIERS";
export const SET_GIFT_CARD_CATEGORIES = "REWARD/SET_GIFT_CARD_CATEGORIES";
export const SET_GIFT_CARD_DEPARTMENTS = "REWARD/SET_GIFT_CARD_DEPARTMENTS";
export const SET_PRE_PAID_CARD_INFO = "REWARD/SET_GIFT_CARD_DEPARTMENTS";
export const SET_REWARD_ELEGIBLE = "REWARD/SET_REWARD_ELEGIBLE";
