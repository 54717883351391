import { isEmptyObject } from "./isEmptyObject"

export const sanitizedPayloadProducts = (payload) => {
    if (isEmptyObject(payload.filter_keys)) {
      /* eslint-disable-next-line no-unused-vars */ 
      const { filter_keys, ...rest } = payload;
      return rest;
    }
    
    return payload;
};