import auth from "@/modules/auth/store/index";
import partner from "@/modules/partner/store/index";
import request from "./requests-store";
import pwa from '../pwa/store/index';
import assents from "./assents";
import { stores as mainModules } from "@/modules/main";

export default {
  auth,
  request,
  partner,
  pwa,
  assents,
  ...mainModules,
};
