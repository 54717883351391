import script from "./dialog.vue?vue&type=script&setup=true&lang=js"
export * from "./dialog.vue?vue&type=script&setup=true&lang=js"

import "./dialog.vue?vue&type=style&index=0&id=61218531&lang=css"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard});
