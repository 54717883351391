/**
 * Home routes
 */
export default [
  {
    path: "dashboard",
    name: "dashboard",
    component: () => import("./Home"),
    meta: {
      sort: -2,
      visible: true,
      label: "menu.home",
      icon: "home",
    },
  },
  {
    path: "",
    name: "forum",
    component: () => import("./Home"),
    meta: {
      sort: 10,
      visible: true,
      label: "menu.forum",
      icon: "forum",
    },
  },
  {
    path: "",
    name: "exit",
    component: () => import("./Home"),
    meta: {
      sort: 11,
      isRouteExit: true,
      visible: true,
      label: "dashboard.card_user.exit",
      icon: "logout",
    },
  },
];
