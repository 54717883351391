/**
 * import min routes
 */
import { default as notificationsRoutes } from "./pages/notifications/routes";
import { default as homeRoutes } from "./pages/home/routes";
import { default as salesRoutes } from "./pages/sales/routes";
import { default as productsRoutes } from "./pages/products/routes";
import { default as myListsRoutes } from './pages/my-lists/routes';
import { accountRoutes, pendingRoutes } from "./pages/account/routes";
import { default as hierarchyRoutes } from "./pages/hierarchy/routes";
import { default as commissionRoutes } from "./pages/commission/routes";
import { default as rescueAwardRoutes } from "./pages/rescue-award/routes";
import { default as meStoreRoutes } from "./pages/me-store/routes";
import { default as missionsRoutes } from "./pages/missions/routes";
import { default as courseRoutes } from "./pages/course/routes";
import { default as coursesRoutes } from "./pages/courses/routes";
import { default as myPointsRoutes } from "./pages/my-points/routes";
import { default as faqRoutes } from "./pages/faq/routes";

/**
 * public routes
 */
import { default as publicRoutes } from "./publicRoutes";

export default [
  ...publicRoutes,
  {
    path: "/",
    redirect: { name: "dashboard" },
    component: () => import("./layouts/MainLayout"),
    children: [
      ...meStoreRoutes,
      ...productsRoutes,
      ...myListsRoutes,
      ...accountRoutes,
      ...hierarchyRoutes,
      ...commissionRoutes,
      ...notificationsRoutes,
      ...homeRoutes,
      ...myPointsRoutes,
      ...rescueAwardRoutes,
      ...missionsRoutes,
      ...courseRoutes,
      ...coursesRoutes,
      ...salesRoutes,
      ...faqRoutes,
      {
        path: "/mag",
        name: "mag",
        beforeEnter() {
          window.open("https://mag.flip.net.br/", "_blank");
        },
        meta: {
          sort: 8,
          visible: true,
          label: "menu.mag",
          icon: "import_contacts",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("./layouts/BlockLayout"),
    children: [...pendingRoutes],
  },
];
