/**
 * Map name for mutations
 * @types {string}
 */

export const SET_SHOW_UP = "DASHBOARD/SET_SHOW_UP";
export const SET_SHOW_UP_WATCHED = "DASHBOARD/SET_SHOW_UP_WATCHED";

export const SET_DATE_RANGE = "DASHBOARD/SET_DATE_RANGE";
export const SET_ON_REQUEST = "DASHBOARD/SET_ON_REQUEST";

export const SET_ORDER_TOTALS = "DASHBOARD/SET_ORDER_TOTALS";
export const SET_VISITOR_TOTALS = "DASHBOARD/SET_VISITOR_TOTALS";
export const SET_PARTNER_ACCEPTED = "DASHBOARD/SET_PARTNER_ACCEPTED";
export const SET_COMMISSION_OPENED = "DASHBOARD/SET_COMMISSION_OPENED";

export const SET_COMMISSION_PAID = "DASHBOARD/SET_COMMISSION_PAID";
export const SET_VISITOR_GRAPH = "DASHBOARD/SET_VISITOR_GRAPH";

export const SET_VOUCHER = "DASHBOARD/SET_VOUCHER";
export const SET_INVITES = "DASHBOARD/SET_INVITES";
export const SET_ECOMM = "DASHBOARD/SET_ECOMM";
export const SET_SPREAD = "DASHBOARD/SET_SPREAD";

export const SET_TOTAL_SALES = "DASHBOARD/SET_TOTAL_SALES";
export const SET_CREDITS_UNDER_ANALYSIS = "DASHBOARD/SET_CREDITS_UNDER_ANALYSIS";
export const SET_POINTS_RECEIVED = "DASHBOARD/SET_POINTS_RECEIVED";
export const SET_POINTS_RESCUE = "DASHBOARD/SET_POINTS_RESCUE";
export const SET_POINTS_EXPIRED_SOON = "DASHBOARD/SET_POINTS_EXPIRED_SOON";
export const SET_POINTS_EXPIRED = "DASHBOARD/SET_POINTS_EXPIRED";
export const SET_POINTS_BALANCE = "DASHBOARD/SET_POINTS_BALANCE";
export const SET_POINTS_RANKING = "DASHBOARD/SET_POINTS_RANKING";
export const SET_POINTS_RANGE = "DASHBOARD/SET_POINTS_RANGE";
export const SET_DASHBOARD_LAYOUT = "DASHBOARD/SET_POINTS_LAYOUT";

export const SET_VISITORS_DATA = "DASHBOARD/SET_VISITORS_DATA";

export const SET_BANNER = "DASHBOARD/SET_BANNER";
export const SET_DOC_PENDING_DASH = "DASHBOARD/SET_DOC_PENDING_DASH";

export const SET_COMPANIES_LIST = "DASHBOARD/SET_COMPANIES_LIST";
export const SET_COMPANIES_REGISTERED_LIST =
  "DASHBOARD/SET_COMPANIES_REGISTERED_LIST";

export const SET_FLIP_THUMB = "DASHBOARD/SET_FLIP_THUMB";

export const SET_DASH_IMAGE = "DASHBOARD/SET_DASH_IMAGE";

export const SET_ALL_LINKS = "DASHBOARD/SET_ALL_LINKS";

export const SET_ALL_VOUCHERS = "DASHBOARD/SET_ALL_VOUCHERS";

export const SET_POINTS_SUMARY = "DASHBOARD/SET_POINTS_SUMARY";

export const SET_IN_REQUESTS_DASH = "DASHBOARD/SET_IN_REQUESTS_DASH";

export const SET_CLUSTER_AND_SHARE = "DASHBOARD/SET_CLUSTER_AND_SHARE";

export const SET_CLUSTER_PARTNER_INFO = "DASHBOARD/SET_CLUSTER_PARTNER_INFO";

export const SET_HAS_SCRIPTS = "DASHBOARD/SET_HAS_SCRIPTS";
export const SET_NOT_ASSOCIATED_ACCS = "DASHBOARD/SET_NOT_ASSOCIATED_ACCS"

export const SET_DRIVES = "DASHBOARD/SET_DRIVES"

export const SET_OVERVIEW = "DASHBOARD/SET_OVERVIEW"
