<template>
    <q-btn class="q-dialog-close-button q-ma-xs text-capitalize" flat v-if="!isAndroidOrWindows" 
    @click="dialogClose">
      Fechar
    </q-btn>
    <q-card-section>
        <img :src="flipLogo" width="70px" class="flex q-my-0 q-mx-auto">
        <h6 class="text-h6 text-center q-my-sm">
          <span class="text-weight-bolder">
            {{  $t("pwa.modal.title") }}
          </span>
        </h6>
        <h4 class="text-subtitle1 text-weight-light text-center q-mt-none q-mb-sm">
            {{ $t("pwa.modal.message") }}
          </h4>
      </q-card-section>
</template>
<script setup>
    import flipLogo from '@/assets/flip-icon.png';
    import { defineProps } from 'vue'

    defineProps({
        isAndroidOrWindows: {
            type: Boolean,
            required: true
        },
        dialogClose: {
            type: Function,
            required: true
        }
    })
</script>
<style scoped>
.q-dialog-close-button {
  display: flex;
  margin-left: auto;
}
</style>