/**
 * By exposing all services used in the module,
 * they are written by axios, responsible for the http control,
 * and are exposed to be used as metadata returning a promise
 */
export default {
  login: () => ({ method: "POST", url: "api/v1/auth/login" }),

  loginWithoutPassword: () => ({
    method: "POST",
    url: "api/v1/auth/login-without-password",
  }),

  loginSocial: () => ({
    method: "GET",
    url: "api/v1/auth/social/login/{slug_parent}",
    baseURL: "http://localhost:8090/",
  }),

  sendRecoverCode: () => ({ method: "POST", url: "api/v1/recover/send-code" }),

  changePassword: () => ({
    method: "POST",
    url: "api/v1/recover/change-password",
  }),

  validateCode: () => ({ method: "POST", url: "api/v1/recover/validate-code" }),

  me: () => ({
    method: "GET",
    url: "api/v1/user/me",
    noPersist: true,
  }),

  signUpSkeleton: () => ({ method: "GET", url: "api/v1/signup/{slug}" }),

  testStoreSlug: () => ({
    method: "GET",
    url: "api/v1/signup/slug/{partnerSlug}/{newSlug}",
  }),

  signUpStore: () => ({ method: "POST", url: "api/v1/signup/create" }),

  validateSignUpService: () => ({
    method: "POST",
    url: "api/v1/signup/validate",
  }),

  terms: () => ({ method: "GET", url: "api/v1/terms/{partnerSlug}/{type}" }),

  userTerms: () => ({ method: "GET", url: "api/v1/terms" }),

  acceptUserTerms: () => ({ method: "PATCH", url: "api/v1/terms/" }),

  confirmYou: () => ({
    method: "GET",
    url: "api/v1/recover/confirm-person/{slug_parent}/{confirmation}",
  }),
};
