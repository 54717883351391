import { orderBy, pickBy, map, flatten, find } from "lodash";

export const filterSection = (sections) =>
  orderBy(
    pickBy(
      sections,
      (s) => s.is_active === 1 && s.contents && s.contents.length
    ),
    "sequence"
  );

export const flattenSectionToContent = (sections) =>
  flatten(map(sections, ({ contents }) => contents));

export const contentHasFinished = (content) => {
  if (
    content.schoolclass &&
    content.schoolclass &&
    content.schoolclass.finished_at !== undefined
  ) {
    return !!content.schoolclass.finished_at;
  } else {
    return false;
  }
};

export const findNextContentAvailable = (sections) => {
  return find(
    flattenSectionToContent(sections),
    (content) => !contentHasFinished(content)
  );
};

export const summaryScore = (summary, py, compare = null) => {
  if (summary && summary.score_summary) {
    let s = summary.score_summary;
    if (Object.prototype.hasOwnProperty.call(s, py)) {
      if (py === "status" && compare) {
        return s[py].toLowerCase() === compare.toLowerCase();
      } else if (compare) {
        return s[py] === compare;
      } else {
        return s[py];
      }
    }
  }
  return false;
};
