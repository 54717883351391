import { sanitizedPayloadProducts } from "../helpers/sanitizedPayloadProducts";
import * as types from "./mutations-types";
import services from "@/shared/http";


/* ==== INICIO: Essas actions são usadas apenas no dash centralizado ==== */

export const setDashboardCentralized = ({ commit }, payload) => {
    commit(types.SET_DASHBOARD_CENTRALIZED, payload);
};

export const setCompaniesIDsInCentralized = ({ commit }, payload) => {
    commit(types.SET_COMPANIES_IDS_IN_CENTRALIZED, payload);
    commit(types.SET_COMPANIES_IDS_IN_SELECTED_FAVORITES_LIST, payload)
};

export const setCompaniesFilterInCentralized = ({ commit }, payload) => {
    commit(types.SET_COMPANIES_FILTER_IN_CENTRALIZED, payload);
};

export const setAllCompaniesInCentralized = ({ commit, dispatch }, payload) => {
    commit(types.SET_ALL_COMPANIES_IN_CENTRALIZED, payload);

    const onlyCompaniesIDs = payload
    .map(companie => companie.id)

    const companies = {
        companies: {
            label: 'Empresas',
            data: payload
            .filter(company => company.slug !== "flipnet")
            .map(company => {
                return {
                    id: company.id,
                    name: company.friendly_name
                }
            })
        }
    }
    
    
    dispatch("setCompaniesFilterInCentralized", companies);

    dispatch("setFilters", companies);

    dispatch("setCompaniesIDsInCentralized", onlyCompaniesIDs)

    dispatch("setParentsIDs", onlyCompaniesIDs);
};

/* ==== FIM: Essas actions são usadas apenas no dash centralizado ==== */



/* ==== INICIO: Essas actions são usadas apenas na Lista de favoritos selecionados ==== */

export const setRequestConfigSelectedFavoritesList = ({ commit }, payload) => {
    commit(types.SET_REQUEST_CONFIG_IN_CENTRALIZED, payload);
};

export const setLoadingInSelectedFavoritesList = ({ commit }, payload) => {
    commit(types.SET_LOADING_IN_SELECTED_FAVORITES_LIST, payload);
};

/* ==== FIM: Essas actions são usadas apenas na Lista de favoritos selecionados ==== */



export const setIsAddingProductToFavorites = ({ commit }, payload) => {
    commit(types.SET_IS_ADDING_PRODUCT_TO_FAVORITES, payload);
};

export const setParentIDToFetchFilters = ({ commit }, payload) => {
    commit(types.SET_PARENT_ID_FETCH_FILTERS, payload);
};

export const setLoading = ({ commit }, payload) => {
    commit(types.SET_LOADING, payload);
};

export const setLoadingAddingOneProduct = ({ commit }, payload) => {
    commit(types.SET_LOADING_ADDING_ONE_PRODUCT, payload);
};

export const setResearchingProduct = ({ commit }, payload) => {
    commit(types.SET_RESEARCHING_PRODUCT, payload);
};

export const setDrawer = ({ commit }, payload) => {
    commit(types.SET_DRAWER, payload);
};

export const setFetchingFilters = ({ commit }, payload) => {
    commit(types.SET_FETCHING_FILTERS, payload);
};

export const setCurrentPage = ({ commit }, payload) => {
    commit(types.SET_CURRENT_PAGE, payload);
};

export const setSelectedFavoritesList = ({ commit }, payload) => {
    const { data } = payload;

    commit(types.SET_SELECTED_FAVORITOS_LIST, data);
};

export const setSelectedFavoriteProductsPagination = ({ commit }, payload) => {
    let pagination = Object.assign({ current_page: 1 }, payload);
    delete pagination.data;

    commit(types.SET_SELECTED_FAVORITE_PRODUCTS_PAGINATION, pagination);
};

export const setCurrentPageFavoriteProductsSelected = ({ commit }, payload) => {
    commit(types.SET_CURRENT_PAGE_FAVORITE_PRODUCTS_SELECTED, payload);
};

export const setProducts = ({ commit }, payload) => {
    commit(types.SET_PRODUCTS, payload);
};

export const setSelectedAllProducts = ({ commit }, payload) => {
    commit(types.SET_SELECTED_ALL_PRODUCTS, payload);
}

export const setFilters = ({ commit, state }, payload) => {
    const { filters } = state;

      const updatedFilters = {
        ...filters,
        ...payload
    };

    commit(types.SET_FILTERS, updatedFilters);
};

export const setPriceRange = ({ commit }, payload) => {
    commit(types.SET_PRICE_RANGE, payload);
};

export const setPartnerLandingLink = ({ commit }, payload) => {
    commit(types.SET_PARTNER_LANDING_LINK, payload);
};

export const setParentsIDs = ({ commit }, payload) => {
    commit(types.SET_PARENTS_ID, payload);
};

export const setInfluencerID = ({ commit }, payload) => {
    commit(types.SET_INFLUENCER_ID, payload);
};

export const setFilterLikes = ({ commit }, payload) => {
    commit(types.SET_FILTER_LIKES, payload);
};

export const setFilterKeys = ({ commit }, payload) => {

    const updatedPayload = Object.assign({}, payload);

    // Verifica se 'age' tem um valor e copia para 'age_group'
    if (payload.age) {
        updatedPayload.age_group = payload.age;
    }

    if (payload.brands) {
        updatedPayload.brand_id = payload.brands
    }

    delete updatedPayload.brands
    delete updatedPayload.categories
    delete updatedPayload.age;

    if (!updatedPayload.gender || updatedPayload.gender.length === 0) {
        delete updatedPayload.gender;
    }

    if (!updatedPayload.brand_id || updatedPayload.brand_id.length === 0) {
        delete updatedPayload.brand_id;
    }

    if (!updatedPayload.age_group || updatedPayload.age_group?.length === 0) {
        delete updatedPayload.age_group
    }

    if ( updatedPayload.companies ) {
        delete updatedPayload.companies
    }

    // Realiza o commit com o payload atualizado
    commit(types.SET_FILTER_KEYS, updatedPayload);
};


export const setIndividualFilters = ({ commit }, payload) => {

    const updatedPayload = Object.assign({}, payload);

    Object.keys(updatedPayload).forEach((key) => {
        // Se a chave não tem valor ou está vazia, remove a chave
        if (!updatedPayload[key] || updatedPayload[key].length === 0) {
            delete updatedPayload[key];
        }
    });

    delete updatedPayload.companies
    delete updatedPayload.brands;

    commit(types.SET_SELECTED_FILTERS, updatedPayload);
}


export const setOrderBy = ({ commit }, payload) => {
    commit(types.SET_ORDER_BY, payload);
};

export const setPayloadProducts = ({ commit }, payload) => {
    commit(types.SET_PAYLOAD_PRODUCTS, payload);
}

export const setTextSearch = ({ commit }, payload) => {
    commit(types.SET_SEARCH_TEXT, payload);
}

export const resetFiltersPayloads = ({ commit }) => {
    commit(types.SET_SELECTED_FILTERS, {});
    commit(types.SET_FILTER_KEYS, {});
    commit(types.SET_ORDER_BY, {});
    commit(types.SET_CURRENT_PAGE, 1);
    commit(types.SET_SELECTED_ALL_PRODUCTS, []);
    commit(types.SET_LOADING, false);
    commit(types.SET_RESEARCHING_PRODUCT, false);
    commit(types.SET_PRICE_RANGE, {});
    commit(types.SET_FILTERS, {});
}

export const resetAll = ({ commit, state }) => {
    const { dashboard_centralized } = state;
    const { is_dashboard_centralized } = dashboard_centralized;


    commit(types.SET_SEARCH_TEXT, '');
    commit(types.SET_SELECTED_FILTERS, {});
    commit(types.SET_FILTER_LIKES, {});
    commit(types.SET_FILTER_KEYS, {});
    commit(types.SET_ORDER_BY, {});
    commit(types.SET_CURRENT_PAGE, 1);
    commit(types.SET_SELECTED_ALL_PRODUCTS, []);
    commit(types.SET_LOADING, false);
    commit(types.SET_RESEARCHING_PRODUCT, false);
    commit(types.SET_PRICE_RANGE, {});
    
    // Só reseta os filtros se não estiver no Dash Centralizado
    if ( !is_dashboard_centralized ) {
        commit(types.SET_FILTERS, {});
    }
}

export const fetchFilters = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        services.my_lists
            .getFilters({}, payload)
            .then((data) => {
                dispatch("setFilters", data);
                resolve();
            })
            .catch((error) => reject(error));
    });
};

export const fetchProductsAndFilters = async ({ state, dispatch }) => {
    const { 
        text_search, selectedFilters, parent_ids, filter_likes, order_by, current_page,
        influencer_id, filter_keys, researching_product, filter_price_range, is_fetching_filters,
        parent_id_to_fetch_filters
    } = state;

    
    const updatedPayloadProducts = {
        ...selectedFilters,
        parent_ids: parent_ids,
        partner_ids: influencer_id,
        filter_likes: filter_likes,
        filter_keys: filter_keys,
        price_range: filter_price_range,
        order_by: order_by,
        page: current_page,
        favorites: 0,
    };

    const payload = sanitizedPayloadProducts(updatedPayloadProducts)

    await fetchProducts({ dispatch }, payload)

    // Só busca os filtros quando o usuário usar o input 'Busque por produtos' ou quando força a busca dos filtros ( is_fetching_filters )
    // researching_product: é o loading (true) enquanto busca os produtos após usar 'Busque por produtos'
    if ( researching_product || is_fetching_filters ) {
        const payloadGetFilters = {
            parent_id: parent_id_to_fetch_filters,
            params: `?prod=${text_search}`
        }

        await fetchFilters({ dispatch }, payloadGetFilters)

        dispatch('setFetchingFilters', false)
    }
}

export const fetchProducts = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        services.my_lists
            .getProducts(payload)
            .then((data) => {
                dispatch("setProducts", data);
                resolve();
            })
            .catch((error) => reject(error));
    });
};

export const fetchSelectedFavoritesList = ({ state, dispatch }) => {
    const { selected_favorites, dashboard_centralized } = state;
    const { is_dashboard_centralized } = dashboard_centralized;
    const { current_page, endpoint_type, parent_ids } = selected_favorites;

    const page = endpoint_type == 'all_products' ? current_page : 1

    const payload = {
        favorites: 1,
        page: page,
        parent_ids: is_dashboard_centralized ? parent_ids : state.parent_ids,
    };


    /** 
     * Define as configurações da requisição de acordo com a forma de Agrupar selecionada em ( actionsButtons.vue )
     * Ou do padrão default do Dash Centralizado ( all_products ) e no Dash da Marca ( category ), confira no arquivo index.vue
    */
    const requestConfig = 
        endpoint_type === 'company' ? services.my_lists.getProductsByCompany :
        endpoint_type === 'category' ? services.my_lists.getProductsByCategory :
        endpoint_type === 'all_products' ? services.my_lists.getProducts :
        services.my_lists.getProducts


    return requestConfig(payload)
    .then((output) => {
        dispatch("setSelectedFavoriteProductsPagination", output);
        dispatch("setSelectedFavoritesList", output);
    })
    .catch((error) => {
        throw error; // ou `return Promise.reject(error);` se preferir
     });
};

export const fetchPartnerLandingLink = async ({ dispatch }) => {
    const output = await services.my_lists.getPartnerLandingLink()

    dispatch("setPartnerLandingLink", output);
}

export const fechAllCompanies = async () => {
    return await services.my_lists.getAllCompanies()
}