export default {
  title: "Notificações",
  empty: "Você não tem notificações, por enquanto",
  show_all: "Mostrar Todas",
  readySuccess: "Notificação lida!",
  readyError: "Ops, algo está errado com as notificações",

  alert_not_found: "Ops, ainda não encontramos nenhuma prêmio resgatado",
  keep_trying: "Continue tentando",
};
