/**
 Export all getters from state
*/

/**
 * @param orders
 * @returns {*[]|*}
 */
export const getOrders = ({ orders }) => (orders.data ? orders.data : []);

/**
 * @param categories
 * @returns {*}
 */
export const getPaginationResource = ({ orders }) => {
  let pagination = Object.assign({ current_page: 1 }, orders);
  delete pagination.data;
  return pagination;
};

/**
 * @param in_request
 */
export const getInRequest = ({ in_request }) => in_request;

/**
 * @param period
 * @returns {boolean}
 */
export const getHasPeriod = ({ period }) => !!period;

/**
 * @param order_cycle
 */
export const getOrderCycle = ({ order_cycle }) =>
  "show_order_cycle" in order_cycle ? order_cycle.show_order_cycle.value : [];
