/**
 * Map name for mutations
 * @types {string}
 */

export const SET_UNFILLED_DOC = 'COMMISSION/SET_UNFILLED_DOC'

export const SET_OPENED = 'COMMISSION/SET_OPENED'
export const SET_OPENED_IN_REQUEST = 'COMMISSION/SET_OPENED_IN_REQUEST'

export const SET_CLOSED = 'COMMISSION/SET_CLOSED'
export const SET_CLOSED_IN_REQUEST = 'COMMISSION/SET_CLOSED_IN_REQUEST'

export const SET_HISTORY = 'COMMISSION/SET_HISTORY'
export const SET_HISTORY_IN_REQUEST = 'COMMISSION/SET_HISTORY_IN_REQUEST'
export const SET_PDF_LINK = 'COMMISSION/SET_PDF_LINK'