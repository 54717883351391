import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  [types.SET_PARAM](state, payload) {
    state.links = payload;
  },

  [types.SET_MISSION_MENU](state, payload) {
    state.menu_param = payload.mission_menu.value;
  },

  [types.SET_ALCADAS](state, payload) {
    state.alcadas = payload;
  },
};
