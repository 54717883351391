/**
 * Map name for mutations
 * @types {string}
 */

export const SET_CATEGORIES = "POINTS/SET_CATEGORIES";
export const SET_POINTS = "POINTS/SET_POINTS";
export const SET_COMMISSION = "POINTS/SET_COMMISSION";
export const SET_SPLIT_EXCEPTIONS = "POINTS/SET_SPLIT_EXCEPTIONS";
export const SET_SPLIT_CLUSTERS = "POINTS/SET_SPLIT_CLUSTERS";
export const SET_PARTNER = "POINTS/SET_PARTNER";
export const SET_TOTAL_POINTS = "POINTS/SET_TOTAL_POINTS";
export const SET_CATEGORY_FILTER_ID = "POINTS/SET_CATEGORY_FILTER_ID";
export const SET_POINTS_IN_REQUEST = "POINTS/SET_POINTS_IN_REQUEST";
export const SET_FAQ_QUESTIONS = "POINTS/SET_FAQ_QUESTIONS";
export const SET_SPLIT_CLUSTERS_RULES = "POINTS/SET_SPLIT_CLUSTERS_RULES";
