  /* Retorna true se o usuario estiver na LP Centralizada, false caso contrário */
  export const isLPCentralized = ({ lp_centralized }) => 
    lp_centralized?.is_lp_centralized;
  

  /* Retorna todos os vouchers disponíveis para o INFLU */
  export const getInfluencerVoucherLPCentralized = ({ lp_centralized }) => 
    lp_centralized?.vouchers
  

  /* Retorna todos os links do ecommerce das empresas parceiras ( Com parâmetros do influ ) */
  export const getCompaniesLinksLPCentralized = ({ lp_centralized }) => 
    lp_centralized?.companies_links
  

  /* Retorna apenas as empresas com produtos selecionados pelo INFLU ( Selecionados no CHILD ) */
  export const getOnlyCompaniesWithProductsLPCentralized = ({ lp_centralized }) => 
    lp_centralized?.only_companies_with_products