export default {
  title: "Personalize sua loja",
  change_social_networks: "Alterar redes sociais",
  highlight_text: "Titulo da sua loja",
  highlight_description: "Descrição da sua loja",
  shared: "Compartilhados",
  authorize_social_networks:
    "Autorizo compartilhar minhas redes sociais cadastradas",
  maybe_u_want_to_change_your_networks:
    "Talvez você queira alterar suas redes sociais",
  add_favorites: "Adicionar favoritos",
};
