/**
 * Map name for mutations
 * @types {string}
 */

export const SET_STORE_PRODUCTS = "PRODUCTS/SET_STORE_PRODUCTS";

export const SET_FAVORITE_PRODUCTS = "PRODUCTS/SET_FAVORITE_PRODUCTS";

export const SET_STORE_SEARCH_FILTER = "PRODUCTS/SET_STORE_SEARCH_FILTER";

export const SET_FAVORITE_SEARCH_FILTER = "PRODUCTS/SET_FAVORITE_SEARCH_FILTER";

export const SET_LANDING_PAGE_LINK = "PRODUCTS/SET_LANDING_PAGE_LINK";

export const SET_CHECKOUT_DATA = "PRODUCTS/SET_CHECKOUT_DATA";

export const SET_CHECKOUT_DATA_TO_EMPTY = "PRODUCTS/SET_CHECKOUT_DATA_TO_EMPTY";

export const SET_REPLACE_PRODUCT_QUANTITY =
  "PRODUCTS/SET_REPLACE_PRODUCT_QUANTITY";

export const SET_OPEN_CHECKOUT_SIDE = "PRODUCTS/SET_OPEN_CHECKOUT_SIDE";

export const SET_CHECKOUT_LINK = "PRODUCTS/SET_CHECKOUT_LINK";

export const SET_CHECKOUT_PARAMS = "PRODUCTS/SET_CHECKOUT_PARAMS";

export const SET_HAS_CHECKOUT = "PRODUCTS/SET_HAS_CHECKOUT";
