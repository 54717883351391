import { default as faq } from "./faq";
import { default as _404 } from "./404";
import { default as menu } from "./menu";
import { default as sales } from "./sales";
import { default as forms } from "./forms";
import { default as login } from "./login";
import { default as notify } from "./notify";
import { default as course } from "./course";
import { default as global } from "./global";
import { default as actions } from "./actions";
import { default as partner } from "./partner";
import { default as account } from "./account";
import { default as missions } from "./missions";
import { default as products } from "./products";
import { default as me_store } from "./me_store";
import { default as dashboard } from "./dashboard";
import { default as my_points } from "./my_points";
import { default as commission } from "./commission";
import { default as components } from "./components";
import { default as image_links } from "./image_links";
import { default as landing_page } from "./landing_page";
import { default as notifications } from "./notifications";
import { default as library_assets } from "./library_assets";
import { default as card_store_menu } from "./card_store_menu";
import { default as validations } from "./validations";
import { default as pwa } from "./pwa";
import { default as signup } from "./signup";
import { default as new_dashboard } from "./new_dashboard";
import { default as my_lists } from "./my_lists";

export default {
  new_dashboard,
  pwa,
  signup,
  validations,
  faq,
  _404,
  menu,
  sales,
  forms,
  login,
  notify,
  course,
  global,
  actions,
  partner,
  account,
  missions,
  products,
  me_store,
  my_lists,
  dashboard,
  my_points,
  commission,
  components,
  image_links,
  landing_page,
  notifications,
  library_assets,
  card_store_menu,
};
