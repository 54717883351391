// Export all getters from state

/**
 *
 * @param {*} reward_info
 * @returns
 */
export const getTotalPointWidget = ({ reward_info }) => {
  if (reward_info && reward_info.total_points) {
    return reward_info.total_points;
  }
  return 0;
};

/**
 *
 * @param {*} state
 * @param {*} getTotalPointWidget
 * @returns
 */
export const hasPointToRescue = (state, { getTotalPointWidget }) =>
  getTotalPointWidget > 0;

/**
 *
 * @param {*} reward_info
 * @returns
 */
export const getConversionWidget = ({ reward_info }) => {
  if (reward_info && reward_info.default_conversion_string) {
    return reward_info.default_conversion_string;
  }
  return "---";
};

/**
 * @param {*} reward_info
 * @returns
 */
export const getBonusWidget = ({ reward_info }) => {
  if (reward_info && reward_info.bonificacao) {
    return reward_info.bonificacao;
  }
  return 0;
};

/**
 *
 * @param {*} in_request
 * @returns
 */
export const isInRequest = ({ in_request }) => in_request;

/**
 *
 * @param {*} trade_on_request
 * @returns
 */
export const tradeInRequest = ({ trade_on_request }) => trade_on_request;

/**
 *
 * @param {*} all_rewards
 * @returns
 */
export const getAllRewards = ({ all_rewards }) => all_rewards.data || [];

/**
 * @param {*} all_rewards
 * @returns
 */
export const getRewardsTotalPages = ({ all_rewards }) => {
  return all_rewards || 1;
};

/**
 * @param {*} reward_info
 * @returns
 */
export const getRewardOptions = ({ reward_info }) => reward_info.options || [];

/**
 * @param {*} rewards_closed
 * @returns
 */
export const getRewardClosed = ({ rewards_closed }) => rewards_closed || [];

/**
 * @param {*} rewards_statuses
 * @returns
 */
export const getRewardsStatuses = ({ rewards_statuses }) => {
  let status = [
    {
      label: "Sem filtro",
      value: "",
    },
  ];
  rewards_statuses.forEach((element) => {
    status.push({
      label: element.description,
      value: element.type,
    });
  });
  return status || [];
};

/**
 * @param {*} rewards_opened
 * @returns
 */
export const getRewardOpened = ({ rewards_opened }) => rewards_opened || [];

/**
 *
 * @param {*} last_rewards_opened
 * @returns
 */
export const getLastRewardOpened = ({ last_rewards_opened }) =>
  last_rewards_opened || [];

/**
 * @param {*} gift_cards
 * @returns
 */
export const getGiftCards = ({ gift_cards }) => {
  return gift_cards || [];
};

/**
 * @param {*} gift_card_suppliers
 * @returns
 */
export const getGiftCardSuppliers = ({ gift_card_suppliers }) =>
  gift_card_suppliers || [];

/**
 * @param {*} gift_card_categories
 * @returns
 */
export const getGiftCardCategories = ({ gift_card_categories }) =>
  gift_card_categories || [];

/**
 * @param {*} gift_card_departments
 * @returns
 */
export const getGiftCardDepartments = ({ gift_card_departments }) =>
  gift_card_departments || [];

/**
 * @param {*} pre_paid_card_info
 * @returns
 */
export const getPrePaidCardInfo = ({ pre_paid_card_info }) =>
  pre_paid_card_info || {};

/**
 * @param {*} pre_paid_card_info
 * @returns
 */
export const getPrePaidCardElegible = ({ reward_elegible }) =>
  reward_elegible || {};
