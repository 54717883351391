/**
 * @checklist-services
 */
export default {
  /**
   * list of checklists
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  list: () => ({ method: "GET", url: "api/v1/checklists", noPersist: true }),

  /**
   * verify a checklist
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  verify: () => ({ method: "POST", url: "api/v1/checklists/verify" }),

  /**
   * undo verify a checklist
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  undoVerify: () => ({ method: "DELETE", url: "api/v1/checklists/verify" }),

  /**
   * seen a checklist
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  seen: () => ({ method: "POST", url: "api/v1/checklists/seen" }),
};
