/**
 * Account routes
 */
export const childRoutes = (routeName) => {
  const paths = [
    "change-password",
    "contact-details",
    "personal-info",
    "disable-account",
    "address",
    "bank-account",
    "social-account",
    "my-documents",
    "change-space",
    "company-data",
  ];

  const routes = paths.map((path) => {
    return {
      path: path,
      name: `${routeName}.${path}`,
      component: () => import(`./children/${path}`),
      meta: {
        visible: false,
        label: `menu.account.children.${path}`,
        icon: "home",
      },
    };
  });

  return routes;
};

export const accountRoutes = [
  {
    path: "account/edit",
    name: "account.edit",
    redirect: { name: "account.personal-info" },
    component: () => import("./edit"),
    meta: {
      sort: 0,
      visible: true,
      label: "menu.account.index",
      icon: "person",
    },
    children: childRoutes("account"),
  },
];

export const pendingRoutes = [
  {
    path: "/pending/docs",
    name: "pending-docs",
    redirect: { name: "pending.my-documents" },
    component: () => import("./EditPendingPage"),
    meta: {
      visible: false,
      noAuth: false,
    },
    children: childRoutes("pending"),
  },

  {
    path: "/in-review",
    name: "partner-in-review",
    component: () => import("./PartnerInReviewPage"),
    meta: {
      visible: false,
      noAuth: false,
    },
  },
  {
    path: "/complete-your-registration",
    name: "complete-your-registration",
    component: () => import("./ParameterizedRegistration"),
    meta: {
      visible: false,
      noAuth: false,
    },
  },
];
