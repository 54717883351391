import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param course
   */
  [types.SET_COURSE_OPENED](state, course) {
    state.course_opened = course;
  },
  /**
   * @param state
   * @param course
   */
  [types.SET_FINISH_COURSE](state, isFinished) {
    state.finished = isFinished;
  },
  /**
   * @param state
   * @param course
   */
  [types.SET_COURSE](state, course) {
    state.course = course;
  },
  /**
   * @param state
   * @param count
   */
  [types.SET_NEXT_COURSE_COUNT](state, count) {
    state.count_course_next = count;
  },
};
