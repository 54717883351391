export default {
  date_rage_picker: {
    customization: "Personalizar",
    last_day: "Ultimo dia",
    last_15_days: "últimos 15 dias",
    last_30_days: "últimos 30 dias",
    last_60_days: "últimos 60 dias",
    last_90_days: "últimos 90 dias",
  },
  validation: {
    custom: {
      onlyLetters:
        "Desculpe, mas esse campo não pode conter caracteres especiais, nem espaços, pontos ou acentos",
      isCep: "CEP inválido",
      passwordIncorrect: "A senha não é valida",
      storeSlugInvalid: "Desculpe, esse nome não pode ser usado",
      phoneIncorrect: "Desculpe, esse número não pode ser usado",
      emailAlreadyExists: "Esse e-mail é inválido ou já foi cadastrado",
      cpfIncorrect: "CPF inválido",
      cnpjIncorrect: "CNPJ inválido",
      emailIncorrect: "O e-mail informado não é valido",
      minAge: "Desculpe mas a idade minima é de 18 anos",
      maxAge: "Desculpe mas a idade maxima é de 100 anos",
    },
    required: "O campo {label} é requerido",
    invalid: "O campo {label} é requerido",
    oneOf: "O campo {path} precisa ser igual a {ref}",
    is_not_link: "Não é um link válido",
    is_not_instagram: "Não é um instagram válido",
    only_5: "Selecione entre 1 e 5 categorias",
  },
  labels: {
    pix_types: "Tipo de Chave",
    pix_key: "Digite a Chave Pix",
    email: "E-mail (usuário)",
    store_name: "Identificador",
    complete_name: "Nome Completo",
    fantasy_name: "Nome Fantasia",
    social_name: "Razão Social",
    document_ie: "Inscrição Estadual",
    type_as: "Como você se classifica",
    name_default_contact: "Nome do contato principal",
    document_rg: "RG",
    document_cpf: "CPF",
    main_contact_document_cpf: "CPF do contato principal",
    document_pj: "CNPJ",
    nome: "Nome do contato principal",
    document_cpf_contact: "CPF do contato principal",
    email_contact: "E-mail do contato",
    phone: "Telefone",
    cel: "Celular",
    celular: "Celular",
    celular_pj: "Celular do contato principal",
    random_key: "Chave Aleatoria",
    pf_pis_pasep: "PIS/PASEP",
    sex: "Sexo",
    ethnicity: "Etnia",
    ethnicity_help:
      "Conforme Portaria do Ministério do Trabalho <b>nº 3784/2023</b>, em cumprimento da <b>Lei 14.553/2023</b>, torna-se obrigatório o preenchimento étnico - racial.",
    birthdate: "Data de Nascimento",
    office: "Cargo",
    parent_name: "Nome do Pai",
    mother_name: "Nome da Mãe",
    uf_birth: "UF Nascimento",
    city_birth: "Cidade Nascimento",
    education: "Escolaridade",
    zip_code: "Cep",
    number: "Número",
    address: "Endereço",
    district: "Bairro",
    city: "Cidade",
    state: "Estado",
    complement: "Complemento",
    bank: "Banco",
    agency: "Agencia",
    bank_type: "Conta",
    bank_number: "Número",
    bank_number_dv: "Digito",
    facebook: "Facebook",
    whatsapp: "Whatsapp",
    instagram: "Instagram (opcional)",
    instagram_req: "Instagram",
    youtube: "Youtube",
    linkedin: "Linkedin",
    tiktok: "Tiktok",
    twitter: "Twitter",
    site: "Site",
    Refpassword: "Senha",
    passwordConfirmation: "Confirmação de senha",
    code: "Código",
    password: "Senha",
    currentPassword: "Senha atual",
    newPassword: "Nova Senha",
    newPasswordConfirmation: "Nova senha, confirmação",
  },
  placeholders: {
    email: "seuemail{'@'}email.com",
    passwordConfirmation: "Confirme a senha",
  },
  tips: {
    store_name:
      "Seu identificador será utilizado para trackear (mensurar) os impactos da suas ações (vendas, cliques, visitantes).<br /> Escolha uma palavra que identifique sua empresa ou você. <br />O identificador irá compor o link que você vai divulgar e possíveis cupons. Exemplos: <br/>&rsaquo; www.lojaparceira.com.br/<b>anamaria</b><br/>&rsaquo; XPTO-<b>ANAMARIA</b>",
    how_do_you_identify: "Qual opção você mais se identifica?",
    cnaes_tip:
      "É necessário ter um dos CNAES a seguir para conseguir atuar como PJ.",
    cnaes_tip_parameterized:
      "É necessário selecionar pelo menos uma das atividades a seguir para conseguir atuar como PJ.",
    we_want_to_know_more_about_you: "Queremos saber mais sobre você!",
    select_categories: "Selecione as categorias que você se encaixa",
    select_categories_tip:
      "Selecione de 1 a 5 categorias. Se você não se encaixa em nenhuma, basta selecionar 'Outros'",
    type_to_add: "Digite para pesquisar e adicionar",
    whats_ur_main_social_network: "Qual a sua principal rede social?",
    network_link: "Link do perfil na rede ou site",
    select_network: "Selecione a rede social",
    case_site: "Caso você tenha um site, selecione a opção 'Site'",
  },
};
