export default {
  social_changed_success: "Redes sociais alteradas com sucesso",
  social_on_changed_error: "Não foi possivel alterar as redes sociais",
  data_changed_success: "Dados alterados com sucesso",
  data_on_changed_error: "Não foi possivel alterar seus dados",
  address_changed_success: "Endereço alterado com sucesso",
  address_on_changed_error: "não foi possivel alterar o endereço",
  bank_account_changed_success: "Dados Bancários alterados com sucesso",
  bank_account_on_changed_error: "Erro ao tentar alterar os dados bancários",
  email_changed_success: "E-mail alterado com sucess!",
  email_on_changed_error: "Erro na alteração de e-mail!",
  store_name_on_changed_error: "Erro ao tentar alterar o nome do seu espaço",
  store_name_changed_success: "Nome do seu espaço foi alterado com sucesso",
};
