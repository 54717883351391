

// =========== INICIO: Esses Getters sao usados apenas no DASH CENTRALIZADO =====================

/* Retorna true se o usuario estiver no Dash Centralizado, false caso contrário */
export const isDashboardCentralized = ({ dashboard_centralized }) => 
    dashboard_centralized?.is_dashboard_centralized;


export const getCompaniesIDsInCentralized = ({ dashboard_centralized }) => 
    dashboard_centralized?.only_companies_ids;


/* Retorna o filtro de empreas ( que vai criar os checkbox para selecionar por empresa ) */
export const getCompaniesFilterInCentralized = ({ dashboard_centralized }) => 
    dashboard_centralized?.companies_filter;


export const getRequestConfigInCentralized = ({ selected_favorites }) => 
    selected_favorites?.endpoint_type


// ======== FIM: Esses Getters sao usados apenas no DASH CENTRALIZADO =====================




// ======= INICIO: Esses Getters sao usados apenas no componente de Favoritos Selecionados =================


/* Retorna a lista dos produtos favoritos selecionados */
export const getSelectedFavoritesList = ({ selected_favorites }) => selected_favorites?.products


/* Retorna os dados de páginação ( Necessários para o componente pagination.vue ) */
export const getPaginationFavoriteProductsSelected = ({ selected_favorites }) => 
    selected_favorites?.pagination

/* Verifica se a página de favoritos selecionados está carregando ( buscando dados nos serviços ) */
export const isLoadingInSelectedFavoritesList = ({ selected_favorites }) => 
    selected_favorites?.is_loading


// ========== FIM: Esses Getters sao usados apenas no componente de Favoritos Selecionados ===================




export const getFilters = ({ filters }) => {

    const priceRangeHasValues = filters?.price_range?.data?.min_price && 
    filters?.price_range?.data?.max_price;
    
    if ( !priceRangeHasValues ) {
        delete filters.price_range
    }

    return filters
};

export const getParentIDToFetchFilters = ({ parent_id_to_fetch_filters }) => parent_id_to_fetch_filters;

export const getResearchingProduct = ({ researching_product }) => researching_product;

export const getPartnerLandingLink = ({ partner_landing_link }) => partner_landing_link;

export const getDrawer = ({ drawer }) => drawer;

export const getProducts = ({ products }) => products;

export const getSelectedAllProducts = ({ select_all_products }) => select_all_products;

export const getPayloadProducts = ({ payloadProducts }) => payloadProducts;

export const getSearchText = ({ text_search }) => text_search;

export const getIndividualFilters = ({ selectedFilters }) => selectedFilters;

export const getParentsID = ({ parent_ids }) => parent_ids;

export const getInfluencerID = ({ influencer_id }) => influencer_id;

export const getFilterLikes = ({ filter_likes }) => filter_likes

export const getFilterKeys = ({ filter_keys }) => filter_keys;

export const getPriceRange = ({ filter_price_range }) => filter_price_range;

export const getOrderBy = ({ order_by }) => order_by

export const getLoading = ({ loading_searching }) => loading_searching

export const getLoadingAddingOneProduct = ({ loading_adding_one_product }) => loading_adding_one_product

export const getCurrentPage = ({ current_page }) => current_page

export const getPagination = ({ products }) => {
    let pagination = Object.assign({ current_page: 1 }, products);
    
    delete pagination.data;
    return pagination;
};

export const getIsAddingProductToFavorites = ({ is_adding_product_to_favorites }) => 
    is_adding_product_to_favorites;


/**
 * Verifica se há filtros ativos no payload de estado.
 * 
 * Esta função recebe o estado como parâmetro e verifica se há algum filtro
 * ativo ou se a condição de comparação entre os `parent_ids` e os
 * `dashboard_centralized.only_companies_ids` é satisfeita.
 * 
 * @returns {boolean} Retorna `true` se houver algum filtro ativo, caso contrário, retorna `false`.
 */
export const hasFiltersInPayload = (state) => {
    const { 
        filter_price_range, filter_keys, selectedFilters, parent_ids,
        dashboard_centralized
     } = state;

    if (
        !filter_price_range && !filter_keys && !selectedFilters &&
        !parent_ids.length
    ) {
        return false
    }


    /**
     * Verifica se o número de empresas selecionadas (parent_ids) é maior ou igual ao 
     * número de empresas salvas (only_companies_ids) SERVE APENAS PARA O DASH CENTRALIZADO
    */
    const hasMoreSelectedCompanies = parent_ids.length < dashboard_centralized?.only_companies_ids.length

    if ( dashboard_centralized?.is_dashboard_centralized && !hasMoreSelectedCompanies) {
        return false 
    } else if (
        !dashboard_centralized?.is_dashboard_centralized &&
        !Object.entries(filter_price_range).length &&
        !Object.entries(filter_keys).length &&
        !Object.entries(selectedFilters).length
    ) {
        return false;
    }

    return true
}

