import { baseURL } from "@/shared/http/api";

/**
 * @sales-services
 */
export default {
  /**
   * store products
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  storeProducts: () => ({ method: "GET", url: "api/v1/products" }),

  /**
   * store products
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  favoriteProducts: () => ({ method: "GET", url: "api/v1/products/favorites" }),

  /**
   * save product id on favorites
   *
   * @return {{method: string, url: string}}
   */
  saveFavorite: () => ({ method: "PATCH", url: "api/v1/products/favorite" }),

  /**
   * remove product from favorites
   *
   * @return {{method: string, url: string}}
   */
  removeFavorite: () => ({ method: "DELETE", url: "api/v1/products/favorite" }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  landingPageLink: () => ({
    method: "GET",
    url: "api/v1/parameters/byKeys",
    baseURL: baseURL,
  }),

  generateCheckoutLink: () => ({
    method: "POST",
    url: "api/v1/cart-link/generate",
    baseURL: baseURL,
  }),
};
