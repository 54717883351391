/**
 * Map name for mutations
 * @types {string}
 */

export const SET_COURSES = "COURSES/SET_COURSES";
export const SET_COURSES_IN_PROCESS = "COURSES/SET_COURSES_IN_PROCESS";
export const SET_COURSES_FINISHED = "COURSES/SET_COURSES_FINISHED";
export const SET_CATEGORIES = "COURSES/SET_CATEGORIES";
export const SET_FILTER = "COURSES/SET_FILTER";
export const SET_BADGE_CHANGES = "COURSES/SET_BADGE_CHANGES";
export const SET_TAB_MODE = "COURSES/SET_TAB_MODE";
export const SET_ALL_COURSES = "COURSES/SET_ALL_COURSES";
export const SET_IN_REQUEST = "COURSES/SET_IN_REQUEST";
