import faq from "./pages/faq/store";
import sales from "./pages/sales/store";
import commission from "./pages/commission/store";
import account from "./pages/account/store";
import notifications from "./pages/notifications/store";
import home from "./pages/home/store";
import me_store from "./pages/me-store/store";
import products from "./pages/products/store";
import landing_page from "./pages/landing-pages/store";
import my_points from "./pages/my-points/store";
import components from "./components/store";
import rescue_award from "./pages/rescue-award/store";
import hierarchy from "./pages/hierarchy/store";
import courses from "./pages/courses/store";
import course from "./pages/course/store";
import missions from "./pages/missions/store";
import views from "./views/store";
import my_lists from "./pages/my-lists/store";
import public_landingpage from "./pages/public-landingpage/store";

/**
 * export all the store modules
 */
export default {
  faq,
  sales,
  landing_page,
  commission,
  account,
  hierarchy,
  me_store,
  my_points,
  products,
  home,
  notifications,
  rescue_award,
  components,
  courses,
  course,
  missions,
  views,
  my_lists,
  public_landingpage,
};
