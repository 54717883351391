export default {
    tooltip_message: "Crie e organize suas lojas de produtos favoritos para diferentes ocasiões, como Dia dos Pais, Natal e muito mais. Compartilhe suas recomendações com seus clientes e aumente suas vendas.",
    tooltip_message_selected_products: `
        Estes são os produtos que você selecionou como favoritos e que agora estão sendo exibidos na sua página pública. 
        Para adicionar mais produtos, clique em <strong>'Adicionar favoritos'</strong>
    `,
    navbar: {
        title: 'Minhas Lojas'
    },
    action_buttons: {
        add_to_favorites: 'Adicionar favoritos',
        view_page: 'Ver minha página',
    },
    favorites_already_selected: {
        title: 'Produtos selecionados',
        group_by: {
            title: 'Agupar por:',
            category: 'Categoria',
            company: 'Empresa',
            product: 'Produto'
        },
        product: {
            remove_favorite: 'Remover dos favoritos',
            share: 'Compartilhar'
        }
    }
}