/**
 * @sales-services
 */
export default {
  /**
   * @return {{method: string, url: string}}
   */
  banners: () => ({ method: "GET", url: "api/v1/lp/products" }),

  /**
   * @return {{method: string, url: string}}
   */
  storeBannersSelected: () => ({ method: "PATCH", url: "api/v1/lp/products" }),

  /**
   * @return {{method: string, url: string}}
   */
  lpProducts: () => ({
    method: "GET",
    url: "api/v1/lp/{slug}/{user_slug}/products",
  }),
};
