import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  },
  /**
   * @param state
   * @param on
   */
  [types.SET_NOTIFICATIONS_ON_REQUEST](state, on) {
    state.notificationsOnRequest = on;
  },
};
