/**
 * @Notifications-services
 */
export default {
  /**
   * notifications index
   *
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  fetchNotifications: () => ({
    method: "GET",
    url: "api/v1/notification/",
    noPersist: true,
  }),

  putNotificationAsRead: () => ({
    method: "PATCH",
    url: "api/v1/notification/{notifyId}",
    noPersist: true,
  }),
};
