/**
 * Commission routes
 */
export default [
  {
    path: "commission",
    name: "commission",
    component: () => import("./index"),
    meta: {
      sort: 6,
      visible: true,
      label: "menu.commission",
      icon: "paid",
    },
  },
];
