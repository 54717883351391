import * as types from "@/modules/main/pages/missions/store/mutation-types";
import {
  notifyError,
  showNotifyFailToResponses,
  showFailMessages,
} from "@/shared/helpers/notify";
import services from "@/shared/http";
import { downloadBlob } from "@/shared/helpers/fileDownloadHelper";
import axios from "axios";

export const setMissions = ({ commit }, payload) => {
  commit(types.SET_MISSIONS, payload);
};

export const setSubLast = ({ commit }, payload) => {
  commit(types.SET_SUB_LAST, payload);
};

export const setNotSubMissions = ({ commit }, payload) => {
  commit(types.SET_NOT_SUB_MISSIONS, payload);
};

export const setFinishedMissions = ({ commit }, payload) => {
  commit(types.SET_FINISHED_MISSIONS, payload);
};

export const setNotSubLast = ({ commit }, payload) => {
  commit(types.SET_NOT_SUB_LAST, payload);
};

export const setFinishedLast = ({ commit }, payload) => {
  commit(types.SET_FINISHED_LAST, payload);
};

export const setShowMission = ({ commit }, payload) => {
  commit(types.SET_SHOW_MISSION, payload);
};

export const setSubsQuestions = ({ commit }, payload) => {
  commit(types.SET_SUBS_QUESTIONS, payload);
};

export const setFinishedDetails = ({ commit }, payload) => {
  commit(types.SET_FINISHED_DETAILS, payload);
};

export const setPublicMissions = ({ commit }, payload) => {
  commit(types.SET_PUBLIC_MISSIONS, payload);
};

export const setPublicFinished = ({ commit }, payload) => {
  commit(types.SET_PUBLIC_FINISHED, payload);
};

export const setShowPublic = ({ commit }, payload) => {
  commit(types.SET_SHOW_PUBLIC, payload);
};

export const setPublicHeader = ({ commit }, payload) => {
  commit(types.SET_PUBLIC_HEADER, payload);
};

export const setMissionTerms = ({ commit }, payload) => {
  commit(types.SET_MISSION_TERMS, payload);
};

export const setAvaibleMissions = ({ commit }, payload) => {
  commit(types.SET_AVAIBLE_MISSIONS, payload);
};

export const setTemporaryUploadLinks = ({ commit }, payload) => {
  commit(types.SET_TEMPORARY_UPLOAD_LINKS, payload);
};

export const fetchMissionsServices = ({ dispatch }) => {
  dispatch("fetchMissions", { vigente: true });
  dispatch("fetchNotSubMissions");
  dispatch("fetchFinishedMissions", {
    status: ["finished", "paid", "awarded", "disapproved"],
  });
};

export const fetchMissions = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .getMissions(payload)
      .then((res) => {
        dispatch("setMissions", res.data);
        dispatch("setSubLast", res.last_page);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchNotSubMissions = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .getNotSubMissions(payload)
      .then((res) => {
        dispatch("setNotSubMissions", res.data);
        dispatch("setNotSubLast", res.last_page);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchFinishedMissions = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .getMissionsFinished(payload)
      .then((res) => {
        dispatch("setFinishedMissions", res.data);
        dispatch("setFinishedLast", res.last_page);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchCreateContent = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .createContent(payload)
      .then(() => {
        dispatch("fetchMissions");
        resolve();
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error);
      });
  });
};

export const fetchShowMission = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .showMission({}, payload)
      .then((res) => {
        dispatch("setShowMission", res);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchSubscribe = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .subscribe({}, payload)
      .then((res) => {
        dispatch("setSubsQuestions", res);
        resolve();
      })
      .catch((error) => {
        showFailMessages(error.errors);
        reject(error);
      });
  });
};

export const fetchUnsubscribe = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .unsubscribe({ reason: "unsubscribe" }, payload)
      .then((res) => {
        dispatch("setSubsQuestions", res);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchSendAwnswers = ({}, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .sendAnswers(payload)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchFinishedDetails = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .finishedDetails({}, payload)
      .then((res) => {
        dispatch("setFinishedDetails", res);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchPublicMissions = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .publicMissions(payload, {})
      .then((res) => {
        dispatch("setPublicMissions", res);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchPublicFinished = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .finishedMissions(payload)
      .then((res) => {
        dispatch("setPublicFinished", res);
        resolve();
      })
      .catch((error) => {
        showNotifyFailToResponses(error);
        reject(error);
      });
  });
};

export const fetchShowPublic = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .showPublicMission({}, payload)
      .then((res) => {
        dispatch("setShowPublic", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchPublicHeader = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .showPublicHeader(payload)
      .then(({ data }) => {
        dispatch("setPublicHeader", data);
        resolve();
      })
      .catch((error) => {
        dispatch("setPublicHeader", []);
        reject(error);
      });
  });
};

export const fetchPublicHeaderCompany = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .showPublicHeaderCompany({}, payload)
      .then(({ data }) => {
        dispatch("setPublicHeader", data);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchTermByMission = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .getTermsByMission({}, payload)
      .then((data) => {
        dispatch("setMissionTerms", data);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchAvaibleMissions = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.missions
      .avaibleMissions()
      .then(({ data }) => {
        dispatch("setAvaibleMissions", data);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchTemporaryUploadLinks = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.missions
      .getTemporaryUploadLinks(payload)
      .then((response) => {
        dispatch("setTemporaryUploadLinks", response);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

// eslint-disable-next-line no-empty-pattern
export const fetchDownloadSignedTerm = ({}, payload) => {
  if (!payload || !payload.conf || !payload.data)
    throw new Error("Payload, conf, and data are required");
  return new Promise((resolve, reject) => {
    axios({
      ...payload.conf,
      data: payload.data.payload,
      responseType: "blob",
    })
      .then((response) => {
        downloadBlob(
          response.data,
          `parceiro_${payload.data.partner_id}_termo_da_missao`,
          "pdf"
        );
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data instanceof Blob) {
          // Convert Blob to JSON
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              showFailMessages(errorData.errors);
              reject(errorData);
            } catch (parseError) {
              notifyError("Failed to parse error response");
              reject(parseError);
            }
          };
          reader.onerror = () => {
            notifyError("Failed to read error response");
            reject(reader.error);
          };
          reader.readAsText(error.response.data);
        } else {
          notifyError("Preencha seus dados");
          reject(error);
        }
      });
  });
};
