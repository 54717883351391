/**
 * Map name for mutations
 * @types {string}
 */

export const SET_ECOMM = "SALES/SET_ECOMM";

export const SET_IN_REQUEST = "SALES/SET_IN_REQUEST";

export const SET_ME_STORE_PARAMS = "SALES/SET_ME_STORE_PARAMS";

export const SET_PARTNER_ASSETS = "SALES/SET_PARTNER_ASSETS";
