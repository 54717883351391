import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_USER](state, payload) {
    state.user = Object.assign({}, payload);
  },
  /**
   * @param state
   * @param payload
   */
  [types.SET_TOKEN](state, payload) {
    state.token = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_SIGN_UP_SKELETON](state, payload) {
    state.sign_up_skeleton = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_SIGN_UP_FLIP_PARTNER_TYPE](state, payload) {
    state.partner_type_flip_signup = payload;
  },
};
