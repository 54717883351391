export default {
  teste:
    "Identificamos que você possui já possui uma conta no programa <b>{ name }</b>. Deseja <b>sincronizar</b> seus dados para seguir sua canditatura?",
  u_already_have_an_account:
    "Identificamos que você possui uma conta na marca <b>{ name }</b>. Vamos vincular este acesso à conta já existente. Ao confirmar,  apresentaremos neste Dashboard os dados da sua conta <b>{ name }</b>, como por exemplo: vendas, cliques no seu link  e créditos a receber.",
  code_will_be_sent: "Um código de validação será enviado no email:",
  code_was_sent:
    "Digite o código que foi enviado em seu email <b>{ email }</b> para concluirmos a sincronização de sua conta.",

  groups: {
    whatsapp_group: "Acesse nosso canal do WhatsApp para receber atualizações!",
  },
};
