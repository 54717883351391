import { sum, filter, map } from "lodash";
/**
 Export all getters from state
 */
/**
 * @param categories
 * @returns {*[]|*}
 */
export const getCategories = ({ categories }) => {
  return categories;
};

/**
 * @param points
 * @returns {*[]|*}
 */
export const getPoints = ({ points }) => {
  return points;
};

/**
 * @param points
 * @returns {*[]|*}
 */
export const getPointsTypePoint = ({ points }) =>
  (points || [])
    .filter((p) => p.e_type === "point")
    .map((p) => ({
      action: p.category.description,
      amount: p.amount,
      created_at: p.created_at,
    }));

/**
 * @param total_points
 * @returns {*[]|*}
 */
export const getTotalPoints = ({ total_points }) => {
  return total_points || 0;
};

export const getTotalCounted = ({ points }) => {
  let filtered = filter(points, (point) => point.e_type === "point"),
    total =
      sum(map(filtered, (p) => p.amount)) -
      sum(map(filtered, (p) => p.points_rescue));
  return total || 0;
};

/**
 * @param points_in_request
 * @returns {*[]|*}
 */
export const isPointerInRequest = ({ points_in_request }) => {
  return points_in_request || false;
};

/**
 * @param faq_questions
 * @returns
 */
export const getFaqQuestions = ({ faq_questions }) => {
  return faq_questions || [];
};

/**
 * @param commission
 * @returns {*[]|*}
 */
export const getCommission = ({ commission }) => {
  return commission || [];
};

/**
 * @param split_exceptions
 * @returns {*[]|*}
 */
export const getSplitExceptions = ({ split_exceptions }) => {
  return split_exceptions || [];
};

/**
 * @param partner
 * @returns {*[]|*}
 */
export const getPartner = ({ partner }) => {
  return partner || [];
};

/**
 * @param cluster
 * @returns {*[]|*}
 */
export const getCluster = ({ cluster }) => {
  return cluster || [];
};

/**
 * @param split_clusters_rules
 * @returns {*[]|*}
 */
export const getSplitClustersRules = ({ split_clusters_rules }) => {
  return split_clusters_rules || [];
};
