import services from '@/shared/http'
import * as types from './mutation-types'

/**
 * set opened data action
 *
 * @param commit
 * @param payload
 */
export const setPdfLink = ({ commit }, payload) => {
    commit(types.SET_PDF_LINK, payload)
}

/**
 * set opened data action
 *
 * @param commit
 * @param payload
 */
export const setOpenedAction = ({ commit }, payload) => {
    commit(types.SET_OPENED, payload)
}

/**
 * set closed data action
 *
 * @param commit
 * @param payload
 */
export const setClosedAction = ({ commit }, payload) => {
    commit(types.SET_CLOSED, payload)
}

/**
 * set closed data action
 *
 * @param commit
 * @param payload
 */
export const setClosedDocUploaded = ({ commit, state }) => {

    state.closed.summary.require_doc = null

    commit(types.SET_CLOSED, state.closed)
}

/**
 * set opened data in request action
 *
 * @param commit
 * @param in_request
 */
export const setOpenedInRequestAction = ({ commit }, in_request) => {
    commit(types.SET_OPENED_IN_REQUEST, in_request)
}
/**
 * set closed data in request action
 *
 * @param commit
 * @param in_request
 */
export const setClosedInRequestAction = ({ commit }, in_request) => {
    commit(types.SET_CLOSED_IN_REQUEST, in_request)
}

export const setUnfilledDoc = ({ commit }, payload) => {
    commit(types.SET_UNFILLED_DOC, payload)
}


/**
 * action to fetch commission opened
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchOpenedAction = ({ dispatch, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {

        dispatch('setOpenedInRequestAction', true)

        let params = Object.assign({
            slug_parent: rootGetters['partner/getPartnerSlug'],
            slug: rootGetters['auth/userSlug']
        }, payload)

        services.commission.fetchCommissionOpened(params).then( (resource) => {
            dispatch('setOpenedAction', resource.data)
            resolve()
        }).catch( (error) => reject(error) )
            .finally( () => dispatch('setOpenedInRequestAction', false) )
    })
}

/**
 * action to fetch commission opened
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchClosedAction = ({ dispatch, rootGetters }) => {
    return new Promise((resolve, reject) => {

        dispatch('setClosedInRequestAction', true)

        let params = Object.assign({
            slug_parent: rootGetters['partner/getPartnerSlug'],
            slug: rootGetters['auth/userSlug']
        })

        services.commission.fetchCommissionClosed(params).then( (resource) => {
            dispatch('setClosedAction', resource.data)
            resolve()
        }).catch( (error) => reject(error) )
            .finally( () => {
                dispatch('setClosedInRequestAction', false)
            })
    })
}

/**
 * action to fetch commission opened
 *
 * @param dispatch
 * @param payload
 * @returns {*}
 */
export const fetchDocsUnfilled = ({ dispatch, rootGetters }) => {
    return new Promise((resolve, reject) => {

        let params = Object.assign({
            slug_parent: rootGetters['partner/getPartnerSlug'],
            slug: rootGetters['auth/userSlug']
        })

        services.commission.fetchDocsUnfilled(params).then( (resource) => {
            dispatch('setUnfilledDoc', resource.data)
            resolve()
        }).catch( (error) => reject(error) )
    })
}

export const fetchParametersByKeys = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      services.me_store
        .params(payload)
        .then((res) => {
          dispatch("setPdfLink", res);
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

