/**
 * all states from store
 */
export default {
  productsByCategories: [],
  influ_data: {},
  company_details: {},
  sidebar: false,
  all_products: [],
  lp_centralized: {
    is_lp_centralized: false, // Está no dash centralizado?
    companies_links: [], // Todos os links do ecommerce dos parceiros ( com parâmetros do influ )
    vouchers: [], // Todos os cupons disponiveis para o INFLU
    only_companies_with_products: [] // Apenas empresas que tenham produtos
  }
};
