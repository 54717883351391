/**
 * @me_store-services
 */
import { baseUrlReward, baseURL } from "@/shared/http/api";

export default {
  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getCategories: () => ({
    method: "GET",
    url: "api/v1/categories",
    baseURL: baseUrlReward,
  }),
  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getPoints: () => ({
    method: "GET",
    url: "api/v1/points",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getTotalPoint: () => ({
    method: "GET",
    url: "api/v1/points/total",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getFaqQuestions: () => ({
    method: "GET",
    url: "api/v1/infos/get-more-points",
    baseURL: baseUrlReward,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getCommission: () => ({
    method: "GET",
    url: "api/v1/splits/ruleDetailsPRISEC",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getSplitExceptions: () => ({
    method: "GET",
    url: "api/v1/splits/exceptions",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getSplitClusters: () => ({
    method: "GET",
    url: "api/v1/splits/clusters",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getRewardPageLayout: () => ({
    method: "GET",
    url: "api/v1/rewards/page-layout",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getPartner: () => ({
    method: "GET",
    url: "api/v1/partner/",
    baseURL: baseURL,
  }),

  /**
   * @api-version 1
   *
   * @returns {{method: string, url: string}}
   */
  getClusterInfos: () => ({
    method: "GET",
    url: "api/v1/parameters/byKeys",
    baseURL: baseURL,
  }),
};
