import axios from "axios";
import store from "@/store";

import urls from "./urls";

const /**
   * base url from admin api
   * @type {string}
   */
  baseUrlAdmin = urls.baseUrlAdmin,
  /**
   * base url from oms api
   * @type {string}
   */
  baseUrlOMS = urls.baseUrlOMS,
  /**
   * base url from cc api
   * @type {string}
   */
  baseUrlCC = urls.baseUrlCC,
  /**
   * base url from reward api
   * @type {string}
   */
  baseUrlReward = urls.baseUrlReward,
  /**
   * base url from learning api
   * @type {string}
   */
  baseUrlLearning = urls.baseUrlLearning,
  /**
   * base url from missions api
   * @type {string}
   */
  baseUrlMissions = urls.baseUrlMissions,
  /**
   * base url from xmailing api
   * @type {string}
   */
  baseUrlXmailing = urls.baseUrlXmailing,
  /**
   * base url from default
   * @type {string}
   */
  baseURL = urls.baseURL,
  /**
   * base url from front
   */
  baseFrontUrl = urls.baseFrontUrl;

/**
 * create api resource
 *
 * @type {AxiosInstance}
 */
const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch("auth/signOutAction").then(() => {
        window.location.reload();
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);

export {
  baseURL,
  baseUrlAdmin,
  baseUrlOMS,
  baseUrlCC,
  baseFrontUrl,
  baseUrlXmailing,
  baseUrlReward,
  baseUrlLearning,
  baseUrlMissions,
};

export default api;
