/**
 * My points routes
 */
export default [
  {
    path: "my-points/how-get",
    name: "my-points.how-get",
    component: () => import("./faq"),
    meta: {
      sort: 1,
      label: "menu.how_get",
      icon: "timeline",
      visible: true,
      //isNew: true,
    },
  },
  {
    path: "my-points/extracts",
    name: "my-points",
    component: () => import("./index"),
    meta: {
      sort: 2,
      visible: true,
      label: "menu.extracts",
      icon: "receipt_long",
      //isNew: true,
    },
  },
];
