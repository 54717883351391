import * as types from "./mutation-types";

export default {
  [types.SET_SHOW_UP](state, payload) {
    state.show_up = payload;
  },

  [types.SET_SHOW_UP_WATCHED](state, payload) {
    state.show_up_watched = payload;
  },

  [types.SET_ORDER_TOTALS](state, payload) {
    state.order_totals = payload;
  },

  [types.SET_DATE_RANGE](state, range) {
    state.date_range = range;
  },

  [types.SET_ON_REQUEST](state, on) {
    state.on_request = on;
  },

  [types.SET_VISITOR_TOTALS](state, payload) {
    state.visitors_totals = payload;
  },

  [types.SET_VISITOR_GRAPH](state, payload) {
    state.visitor_graph = payload;
  },

  [types.SET_PARTNER_ACCEPTED](state, payload) {
    state.partners_accepted = payload;
  },

  [types.SET_VOUCHER](state, payload) {
    state.voucher = payload;
  },

  [types.SET_INVITES](state, payload) {
    state.invites = payload;
  },

  [types.SET_COMMISSION_OPENED](state, payload) {
    state.commission_opened = payload;
  },

  [types.SET_ECOMM](state, payload) {
    state.ecomm = payload;
  },

  [types.SET_SPREAD](state, payload) {
    state.spread = payload;
  },

  [types.SET_TOTAL_SALES](state, payload) {
    const { data } = payload;

    state.reward_total_sales = parseFloat(data.sum_order);
    state.reward_total_orders = data.quantity;
  },

  [types.SET_CREDITS_UNDER_ANALYSIS](state, payload) {
    state.reward_credits_under_analysis = payload;
  },

  [types.SET_POINTS_RECEIVED](state, reward_points_received) {
    state.reward_points_received = reward_points_received;
  },

  [types.SET_POINTS_RESCUE](state, reward_points_rescue) {
    state.reward_points_rescue = reward_points_rescue;
  },

  [types.SET_POINTS_EXPIRED_SOON](state, reward_points_expired_soon) {
    state.reward_points_expired_soon = reward_points_expired_soon;
  },

  [types.SET_POINTS_EXPIRED](state, reward_points_expired) {
    state.reward_points_expired = reward_points_expired;
  },

  [types.SET_POINTS_BALANCE](state, reward_points_balance) {
    state.reward_points_balance = reward_points_balance;
  },

  [types.SET_POINTS_RANKING](state, reward_ranking) {
    state.reward_ranking = reward_ranking;
  },

  [types.SET_POINTS_RANGE](state, reward_points_range) {
    state.reward_points_range = reward_points_range;
  },

  [types.SET_DASHBOARD_LAYOUT](state, layout) {
    state.layout = layout;
  },

  [types.SET_VISITORS_DATA](state, data) {
    state.visitors_data = data;
  },

  [types.SET_BANNER](state, data) {
    state.banner = data;
  },

  [types.SET_DOC_PENDING_DASH](state, data) {
    state.doc_pending_dash = data;
  },

  [types.SET_COMPANIES_LIST](state, data) {
    state.companies_list = data;
  },

  [types.SET_COMPANIES_REGISTERED_LIST](state, data) {
    state.companies_registered_list = data;
  },

  [types.SET_FLIP_THUMB](state, data) {
    state.flip_thumb = data;
  },

  [types.SET_DASH_IMAGE](state, data) {
    state.dash_image = data;
  },

  [types.SET_ALL_VOUCHERS](state, data) {
    state.all_vouchers = data;
  },

  [types.SET_ALL_LINKS](state, data) {
    state.all_links = data;
  },

  [types.SET_POINTS_SUMARY](state, data) {
    state.points_sumary = data;
  },

  [types.SET_IN_REQUESTS_DASH](state, data) {
    let newData = { ...state.in_requests_dash, ...data };
    state.in_requests_dash = newData;
  },

  [types.SET_CLUSTER_AND_SHARE](state, data) {
    state.cluster_and_share = data;
  },

  [types.SET_CLUSTER_PARTNER_INFO](state, data) {
    state.cluster_partner_info = data;
  },

  SET_HAS_SCRIPTS(state, data) {
    state.has_scripts = data;
  },

  [types.SET_NOT_ASSOCIATED_ACCS](state, data) {
    state.not_associated_accs = data;
  },

  [types.SET_OVERVIEW](state, data) {
    state.overview = data;
  },
};
