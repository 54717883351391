import * as types from "@/modules/main/views/store/mutation-types";
import services from "@/shared/http";
import {
  cacheHandler,
  setCache,
} from "@/shared/helpers/cache/sessionCacheHelper";

export const setParam = ({ commit }, payload) => {
  commit(types.SET_PARAM, payload);
};

export const setMissionMenu = ({ commit }, payload) => {
  commit(types.SET_MISSION_MENU, payload);
};

export const setAlcadas = ({ commit }, payload) => {
  commit(types.SET_ALCADAS, payload);
};

export const fetchParametersByKeys = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .params(payload)
      .then((res) => {
        dispatch("setParam", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const fetchMissionMenu = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    services.me_store
      .params(payload)
      .then((res) => {
        dispatch("setMissionMenu", res);
        resolve();
      })
      .catch((error) => reject(error));
  });
};

/**
 * Action to fetch 'alcadas' data.
 *
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchAlcadas = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    const prefix = "alcadas"; // Prefixo que será usado para o cache

    cacheHandler(prefix, {
      onCacheHit: async (cachedData) => {
        try {
          // Despachar a ação setAlcadas com os dados do cache
          await dispatch("setAlcadas", cachedData);
          resolve(); // Resolver a Promise, pois os dados foram carregados do cache
        } catch (error) {
          reject(error); // Rejeitar a Promise em caso de erro
        }
      },
    })
      .then((cacheResult) => {
        if (!cacheResult.isCacheHit) {
          // Caso não haja cache, fazer a requisição à API
          services.home
            .getAlcadas()
            .then((res) => {
              try {
                const alcadasRoutes = res.map((alcada) => alcada.route);
                // Armazenar o resultado no cache
                setCache(prefix, alcadasRoutes);
                // Despachar as ações com os dados obtidos da API
                dispatch("setAlcadas", alcadasRoutes);
                resolve(); // Resolver a Promise com sucesso
              } catch (error) {
                reject(error); // Rejeitar a Promise em caso de erro
              }
            })
            .catch((error) => reject(error)); // Rejeitar a Promise se a requisição falhar
        }
      })
      .catch(reject); // Rejeitar a Promise em caso de erro no cacheHandler
  });
};
