/**
 * all states from store
 */
export default {
  store_products: {},
  favorites_products: {},
  store_search_filter: "",
  favorite_search_filter: "",
  landing_page_link: "",
  checkout_data: [],
  open_checkout_side: false,
  checkout_link: "",
  checkout_params: {},
  checkout_rules: [],
  has_checkout: 0,
  checkout_link_prefix_sufix: [],
};
