import * as types from "./mutation-types";
/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_HIERARCHY](state, payload) {
    state.hierarchy = payload;
  },
};
