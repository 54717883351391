import Notify from 'quasar/src/plugins/notify/Notify.js';;
import i18n from "@/i18n/wrappers/i18n";
import { LogOutUnauthenticated } from "@/modules/auth/storage";
const t = i18n.global.t;

const getMessage = (message, translate) => (translate ? t(message) : message);

export const notifySuccess = (message = "notify.success", translate = true) => {
  Notify.create({
    message: getMessage(message, translate),
    html: true,
    timeout: 8000,
    type: "positive",
    color: "light-blue-8",
    position: "top-right",
  });
};

export const notifyBottom = (message, type = "positive") => {
  const positive = {
      type: "positive",
      color: "light-blue-8",
    },
    negative = {
      type: "negative",
      color: "red",
    };

  Notify.create({
    message,
    html: true,
    position: "bottom",
    ...(type == "positive" ? positive : negative),
  });
};

export const notifyInfo = (message, translate = true) => {
  Notify.create(getMessage(message, translate));
};

export const notifyError = (message = "notify.error", translate = true) => {
  Notify.create({
    message: getMessage(message, translate),
    html: true,
    position: "top-right",
    color: "red",
    icon: "fas fa-exclamation",
  });
};

/**
 * notification to fail responses
 */
export const showNotifyFailToResponses = (errors) => {
  const errorData = errors.response.data;
  if (errorData.errors) {
    const allErrors = errors.response.data.errors;
    Object.values(allErrors).forEach((message) => {
      notifyError(message, false);
    });
  } else notifyError(errorData.message, false);

  LogOutUnauthenticated(errorData.message);
};

export const showFailMessages = (messages) => {
  Object.values(messages).forEach((message) => {
    notifyError(message, false);
  });
};

export const showFailMessagesTeste = (errors) => {
  Object.values(errors).forEach((messagesArray) => {
    Object.values(messagesArray).forEach((message) => {
      notifyError(message[0], false);
    });
  });
};
