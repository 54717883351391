import * as types from "./mutation-types";

/**
 * Export all mutations
 */
export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_COURSES](state, payload) {
    state.courses = payload;
  },
  /**
   * @param state
   * @param payload
   */
  [types.SET_COURSES_IN_PROCESS](state, payload) {
    state.courses_in_process = payload;
  },
  /**
   * @param state
   * @param payload
   */
  [types.SET_COURSES_FINISHED](state, payload) {
    state.courses_finished = payload;
  },
  /**
   * @param state
   * @param in_request
   */
  [types.SET_IN_REQUEST](state, in_request) {
    state.in_request = in_request;
  },
  /**
   * @param state
   * @param in_request
   */
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  /**
   * filter
   *
   * @param state
   * @param filter
   */
  [types.SET_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * badges changes
   *
   * @param state
   * @param value
   */
  [types.SET_BADGE_CHANGES](state, value) {
    state.badges_changed = value;
  },
  /**
   * set tab mode
   *
   * @param state
   * @param value
   */
  [types.SET_TAB_MODE](state, value) {
    state.tab_mode = value;
  },

    /**
   * set tab mode
   *
   * @param state
   * @param value
   */
     [types.SET_ALL_COURSES](state, value) {
      state.courses_list = value;
    },

  
};
