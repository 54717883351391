/**
 * Rescue award routes
 */
export default [
  {
    path: "my-points/awards/all",
    name: "my-recued-points",
    component: () => import("./closed"),
    meta: {
      sort: 3,
      visible: true,
      label: "menu.my_points_recueds",
      icon: "military_tech",
      // isNew: true,
    },
  },
  {
    path: "my-points/award",
    name: "my-points.award",
    component: () => import("./index"),
    meta: {
      sort: 4,
      visible: true,
      label: "menu.my_points",
      icon: "payments",
      // isNew: true,
    },
  },
  {
    path: "my-points/awards/all",
    name: "my-points.awards.closed",
    component: () => import("./closed"),
    meta: {
      visible: false,
    },
  },
];
