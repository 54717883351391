import {
  setSwitchCompanies,
  deleteSwitchCompanies,
} from "@/shared/helpers/switchCompaniesHelper";
import services from "@/shared/http";
import * as storage from "../storage";
import * as types from "./mutation-types";
import { useRoute } from "vue-router";
import { setLocalUserId } from "@/modules/partner/storage";

/**
 * set user action
 *
 * @param commit
 * @param payload
 */
export const setUserAction = ({ commit }, payload) => {
  commit(types.SET_USER, payload);
};

export const setUserPartnerAction = ({ commit, state }, payload) => {
  let newUser = state.user;
  newUser.partner = payload;
  commit(types.SET_USER, newUser);
};

/**
 * set sign up skeleton
 *
 * @param commit
 * @param payload
 */
export const setSignUpSkeleton = ({ commit }, payload) => {
  commit(types.SET_SIGN_UP_SKELETON, payload);
};

export const setFlipSignupPartnerType = ({ commit }, payload) => {
  commit(types.SET_SIGN_UP_FLIP_PARTNER_TYPE, payload);
};

/**
 * action to set token
 *
 * put in state and push to local and header
 *
 * @param commit
 * @param payload
 * @constructor
 */
export const setTokenAction = ({ commit }, payload) => {
  storage.setLocalToken(payload);
  storage.setHeaderToken(payload);
  commit(types.SET_TOKEN, payload);
};

/**
 * set sign up flip partner type
 */
export const signUpFlipPartnerTypeAction = ({ dispatch }, payload) => {
  dispatch("setFlipSignupPartnerType", payload);
};

/**
 * Make login action, persist user data and make push to store
 *
 * @param dispatch
 * @param payload
 * @constructor
 */
export const signInAction = ({ dispatch }, payload) => {
  return services.auth.login(payload).then(async (res) => {
    await dispatch("setTokenAction", res.token);
    await dispatch("fetchUserAction");
  });
};

export const signInByDashboard = ({ dispatch }, payload) => {
  return services.auth
    .loginWithoutPassword({ parent_slug: payload.slug })
    .then(async (res) => {
      await dispatch("setTokenAction", res.token);
      setSwitchCompanies();
      window.location.href = payload.path;
    });
};

// eslint-disable-next-line no-empty-pattern
export const getJWTTokenToCompany = ({}, payload) => {
  console.log(payload);
  return services.auth
    .loginWithoutPassword({ parent_slug: payload.slug })
    .then(async (res) => res.token);
};

/**
/**
 * Send code to recovery password
 *
 * @param context
 * @param payload
 * @constructor
 */
export const sendRecoveryCode = (context, payload) => {
  return services.auth.sendRecoverCode(payload);
};

/**
/**
 * Send email to recovery password
 *
 * @param context
 * @param payload
 * @constructor
 */
export const sendCodeAction = (context, payload) => {
  return services.auth.validateCode(payload);
};

/**
/**
 * Send email to recovery password
 *
 * @param context
 * @param payload
 * @constructor
 */
export const changePassword = (context, payload) => {
  return services.auth.changePassword(payload);
};

/**
 * Logout user from store
 *
 * @param dispatch
 */
export const signOutAction = ({ dispatch }) => {
  storage.setHeaderToken("");
  storage.deleteLocalToken();
  deleteSwitchCompanies();
  dispatch("setUserAction", {});
  dispatch("setTokenAction", "");
};

/**
 * Try retrieve token from store or local data
 *
 * @param dispatch
 * @param state
 * @returns {Promise<never>|Promise<string|CancelToken>|*}
 */
export const persistTokenAction = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }

  const token = storage.getLocalToken();

  if (!token) {
    return Promise.reject(new Error("Invalid Token"));
  }

  dispatch("setTokenAction", token);
  // dispatch("fetchEthnicity");
  return dispatch("fetchUserAction");
};

// eslint-disable-next-line no-empty-pattern
export const fetchEthnicity = ({}) => {
  new Promise(() => {
    services.account.getEthnicity().catch((error) => console.log(error));
  });
};

/**
 * action to fetch user data from session
 *
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchUserAction = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    services.auth
      .me()
      .then(async (response) => {
        setLocalUserId(response.parceiro);
        await dispatch("setUserAction", response);
        await dispatch("partner/fetchPartnerAction", null, { root: true });
        dispatch("notifications/fetchNotificationsAction", null, {
          root: true,
        });

        const partner = response.partner;

        const partnerCPFOrCNPJ =
          partner.pf_pj == "PF" ? partner.pf_cpf : partner.pj_cnpj;

        // Use this to set the partner CPF or CNPJ in the local storage
        localStorage.setItem("partnerCPFOrCNPJ", partnerCPFOrCNPJ);

        const pathName = window.location.pathname;

        /** Esse é o fluxo correto para o redirecionamento */
        if (partner.approved == 1) localStorage.removeItem("hasPendDoc");
        else if (
          partner.has_pend == 1 ||
          partner.is_pending_data == true ||
          partner.is_pending_data == null // Caso o parceiro ainda esteja pendente de preenchimento de dados
        ) {
          localStorage.setItem("hasPendDoc", partner.has_pend);
          if (!pathName.includes("/complete-your-registration"))
            redirectPartner("/complete-your-registration");
        } else if (partner.approved == null)
          if (!pathName.includes("/in-review")) redirectPartner("/in-review");

        resolve();
      })
      .catch((error) => {
        dispatch("signOutAction");
        reject(error);
      });
  });
};

const redirectPartner = (path) => (window.location.href = path);

/**
 * action to fetch sign up skeleton description
 * @param dispatch
 * @returns {Promise<unknown>}
 */
export const fetchSignUpSkeleton = async ({ dispatch }) => {
  const route = useRoute();
  const binds = { slug: route.params.slug };
  await dispatch("fetchSignUpSkeletonAction", binds);
};

export const fetchSignUpSkeletonAction = async ({ dispatch }, payload) => {
  await services.auth.signUpSkeleton(null, payload).then(async (response) => {
    await dispatch("setSignUpSkeleton", response.data || {});
  });
};

export const fetchBlogSignUpSkeleton = async ({ dispatch }, slug) => {
  const binds = { slug };
  await services.auth.signUpSkeleton(null, binds).then(async (response) => {
    await dispatch("setSignUpSkeleton", response.data || {});
  });
};

/**
 * store a new partner action
 * @param context
 * @param payload
 * @return {*}
 */
export const storeSignUp = (context, payload) => {
  return new Promise((resolve, reject) => {
    services.auth
      .signUpStore(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const validateSignUp = (context, payload) => {
  return new Promise((resolve, reject) => {
    services.auth
      .validateSignUpService(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

/**
 * @param type
 * @return {{method: string, url: string}}
 */
// eslint-disable-next-line no-empty-pattern
export const fetchTerms = async ({}, { type, companySlug }) => {
  return new Promise((resolve, reject) => {
    services.auth
      .terms(null, {
        partnerSlug: companySlug,
        type,
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const fetchUserTerms = () => services.auth.userTerms();

export const acceptUserTerms = () => services.auth.acceptUserTerms();

/**
 * @param rootGetters
 * @param payload
 * @returns {{method: string, url: string}}
 */
export const fetchSocialLogins = ({ rootGetters }, payload) =>
  services.auth.loginSocial(payload, {
    slug_parent: rootGetters["partner/getPartnerSlug"],
  });

/**
 * @param rootGetters
 * @param payload
 * @returns {*}
 */
export const confirmYou = ({ rootGetters }, payload) =>
  services.auth.confirmYou(null, {
    slug_parent: rootGetters["partner/getPartnerSlug"],
    confirmation: payload.confirmation,
  });
