/**
 Export all getters from state
*/

/**
 * @param store_products
 * @returns {*[]|*}
 */
export const getStoreProducts = ({ store_products }) => {
  if (store_products.data) return store_products.data.filter((p) => p.price);
  else return [];
};

/**
 * @param store_products
 * @returns {*}
 */
export const getStoreProductsPagination = ({ store_products }) => {
  let pagination = Object.assign({ current_page: 1 }, store_products);
  delete pagination.data;
  return pagination;
};

/**
 * @param store_search_filter
 * @returns {boolean}
 */
export const hasStoreProductFilter = ({ store_search_filter }) =>
  !!store_search_filter && store_search_filter.length;

/**
 * @param favorites_products
 * @returns {*[]|*}
 */
export const getFavoritesProducts = ({ favorites_products }) => {
  if (favorites_products.data)
    return favorites_products.data.filter((p) => p.price);
  else return [];
};

/**
 * @param favorites_products
 * @returns {*}
 */
export const getFavoritesProductsPagination = ({ favorites_products }) => {
  let pagination = Object.assign({ current_page: 1 }, favorites_products);
  delete pagination.data;
  return pagination;
};

/**
 * @param favorite_search_filter
 * @returns {boolean}
 */
export const hasFavoriteProductFilter = ({ favorite_search_filter }) =>
  !!favorite_search_filter && favorite_search_filter.length;

/**
 * @param landing_page_link
 */
export const getLandingPageLink = ({ landing_page_link }) =>
  landing_page_link || "";

/**
 * @param checkout_data
 */
export const getCheckoutData = ({ checkout_data }) => checkout_data || [];

export const getCheckoutDataSubTotal = ({ checkout_data }) => {
  let subtotal = 0;

  // Itera sobre cada item no array
  checkout_data.forEach((item) => {
    // Verifica se há um preço promocional e se é menor que o preço regular
    const itemPrice =
      item.promotion_price && item.promotion_price < item.price
        ? item.promotion_price
        : item.price;

    // Adiciona ao subtotal o preço do item multiplicado pela quantidade
    subtotal += itemPrice * item.quantity;
  });

  return subtotal;
};

export const getIsOpenedCheckoutSide = ({ open_checkout_side }) =>
  open_checkout_side;

export const getCheckoutLink = ({ checkout_link }) => checkout_link;

export const getCheckoutParams = ({ checkout_params }) => checkout_params.rules;

export const getCheckoutUrlData = ({ checkout_params }) => [
  checkout_params.prefixUrl,
  checkout_params.sufixUrl,
];

export const getCheckoutRules = ({ checkout_rules }) => {
  return checkout_rules.filter((e) => e.value !== null);
};

export const getHasCheckout = ({ has_checkout }) => has_checkout;
