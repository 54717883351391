export default {
  me_store: {
    image_library: "Biblioteca de imagens",
    no_images: "Não há imagens disponíveis no momento.",
    expire_in: "Expira em: { date}",
    message:
      "<b>Observação:</b> Algumas imagens podem ter data de expiração, ao fim do periodo de expiração, a imagem não estará mais disponível para uso em sua loja.",
    success: "Imagem adicionada com sucesso!",
    fail: "Falha ao adicionar imagem!",
    total_exceeded: "Limite de imagens excedido!",
  },
};
