/**
 * Products routes
 */
export default [
  {
    path: "landing-page",
    name: "store",
    component: () => import("./index"),
    meta: {
      sort: -1,
      visible: true,
      label: "menu.lading_page",
      icon: "thumb_up",
    },
  },
];
