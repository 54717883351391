/**
 * Downloads a PDF file.
 *
 * @param {Blob} data - The data to be downloaded.
 * @param {string} filename - The name of the file to be saved.
 * @param {string} mimeType - The MIME type of the file.
 */
export function downloadBlob(data, filename, mimeType) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename + "." + mimeType);
  document.body.appendChild(link);
  link.click();
}
