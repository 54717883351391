export default {
  title: "Extrato de Créditos",
  rescueCredits: "Créditos Resgatados",
  title_how_get: "Como Ganhar Créditos",
  how_get: {
    header: "Ações para pontuar",
  },
  point_partially_canceled:
    "{points_canceled} dos {amount} créditos que você receberia foram cancelados",
  point_canceled_after_rescued: "Créditos Cancelados",
  point_canceled_after_rescued_description:
    "Ocorreu um cancelamento de evento na plataforma (o evento que gerou os créditos foi cancelado total ou parcialmente)",
  award_rescue: "Resgatar",
  how_to_rescue: "Resgatar Meus Créditos",
  how_to_earn: "Como Ganhar Créditos",
  points_history: "Histórico de créditos",
  you_accumulated: "Você acumulou",
  last_days: "nos últimos 30 dias",
  point_reward: "Resgate de Créditos",
  id: "ID:",
  order: "Pedido:",
  points:
    "Créditos (Esse valor estará disponível a partir do dia { valid_from })",
  points_rescue: "Créditos",
  points_message:
    "Ocorreu uma venda na plataforma. Nº do pedido: { external_key }",
  you_reward:
    "Você realizou o resgate em <strong>{ type }</strong> utilizando {value} dos seus créditos acumulados.",
  no_points:
    "Você ainda não atingiu uma quantidade de créditos que te permite sacar",
  no_stop: "Mas não desanime, continue tentando!!",
  my_points: {
    title: "Resgatar Meus Créditos",
  },
  reward_pre_paid_card: {
    edit: "Editar",
    taxesAndFees: "Taxas/Impostos",
    calc_taxes1:
      "{value_1} = {value_2} (Valor creditado no cartão)  + {value_3} (Frete + Plástico) + {value_4} (Impostos e Taxa)",
    calc_taxes2:
      "{value_1} (Valor para recarga) - {value_2} (Impostos e Taxas) = {value_3}",
    description: "Descrição",
    disclaimer:
      "Você está prestes a recarregar {value} em seu {option}, confirme o número do seu cartão, para prosseguir com esta ação",
    disclaimer2:
      "O cartão será enviado para o endereço cadastrado. O envio para endereço errado gerará duas cobranças de frete. Favor confirmar antes de seguir.",
    alert:
      "Para realizar a solicação do CARTÃO MASTERCARD, leia antes a  <strong>'DESCRIÇÃO'</strong>.",
    op0label: "Receber um novo cartão pré-pago",
    op1label: "Recarregar cartão pré-pago",

    pf: {
      name: "<b>Nome:</b> {value}",
      cpf: "<b>CPF:</b> {value}",
      gender: "<b>Sexo:</b> {value}",
      celular: "<b>Celular:</b> {value}",
      email: "<b>E-mail:</b> {value}",
    },
    pj: {
      social: "<b>Razão Social:</b> {value}",
      cnpj: "<b>CNPJ:</b> {value}",
      email: "<b>E-mail:</b> {value}",
      celular: "<b>Celular:</b> {value}",
    },
    address: {
      cep: "<b>CEP:</b> {value}",
      street: "<b>Rua:</b> {value}",
      number: "<b>Número:</b> {value}",
      district: "<b>Bairro:</b> {value}",
      complement: "<b>Complemento:</b> {value}",
      stateAndcity: "<b>Cidade/Estado:</b> {value}",
    },
  },
  reward_gift: {
    information: {
      amount: "Total de créditos: <strong>{ value }</strong>",
      text_1: "Já filtramos os vouchers até o limite do valor disponível;",
      text_2:
        "Leia atentamente as regras de cada parceiro antes de confirmar a solicitação do voucher;",
      text_3:
        "Reinicie a operação para solicitar um novo voucher caso o seu limite não seja totalmente usado;",
    },
    alert_to_show:
      "Para realizar a solicação do Gift Card, leia antes as seções <strong>'INFORMAÇÕES ADICIONAIS'</strong> e <strong>'DETALHES DA DESCRIÇÃO'</strong>.",
    alert_to_show_secound:
      "Para realizar a solicação do Gift Card, leia antes a seção <strong>'DETALHES DA DESCRIÇÃO'</strong>.",
    final_confirmation:
      "Essa ação é irreversível tem certeza que deseja continuar com a solicitação?",
    confirm_email: "Confirme seu e-mail cadastral.",
    confirm_check: "Eu li e concordo com as regras estipuladas pelo parceiro.",
    alerts: {
      alert_email: "E-mail não confere!",
      alert_check: "Você precisa confirmar os termos.",
    },
    expansion_item_title: "INFORMAÇÕES ADICIONAIS",
    expansion_item_title2: "DETALHES DA DESCRIÇÃO",
    gift_value: "Gift Card de { value }",
  },
  faq: {
    page_title: "Como eu ganho meus créditos?",
    title_1: "Ganhe vendendo nossos produtos!",
    text_1: "É só vender para juntar créditos e usar como quiser",
    title_2: "Percentual na venda.",
    text_label_1: "Seu percentual é de { value }%",
    text_label_2: "Seu percentual de indicação é de { value }%",
    text_2: "Receba uma porcentagem do valor do pedido a cada venda realizada.",
    text_3:
      "Indique e ganhe uma porcentagem de créditos por cada venda do seu indicado.",
    title_3: "Confira os benefícios de cada nível.",
    text_4: "Quanto mais você vende, maior é o seu percentual de ganho!",
    text_5:
      "(*) Alguns produtos podem ter percentual comissão menores ou zerados devido a condições comerciais dos fabricantes, consulte o FAQ para maiores informações.",
    title_4: "Ganhe mais créditos com ações exclusivas da plataforma",
    text_6: "Preparamos outras ações para você receber ainda mais créditos.",
    percent: "Percentual:",
    initial_value: "Valor inicial:",
    final_value: "Valor final:",
    value_converted: "(1 crédito = R$ 1,00)",
    title_5: "Regras do programa.",
  },
  award: {
    cannotRescue: "Você não possui créditos o suficiente",
    cannotRescueLabel: "Continue tentando",
    title: "Resgate de créditos",
    conversion_widget: "{conversion} créditos = R$ ",
    widget_total_title: "Total de créditos ",
    widget_conversion_title: "Fator de conversão",
    widget_bonus_title: "Bonificação",
    voucher_title: "Voucher",
    gift_card_title: "Giftcard",
    min_rescue_value: "Valor mínimo para retirar:",
    min_rescue_internal:
      "Você ainda não tem o saldo mínimo ({value}) para fazer o resgate;",
    doc_pend_rescue_value: "Seu cadastro está incompleto!",
    doc_pend_rescue_internal:
      "Seu cadastro está incompleto! Complete para que seja possível realizar resgates;",

    doc_pend_rescue_btn: "Visualizar",
    doc_pend_rescue_access: "Ir para Meus Dados",
    doc_pend_rescue_alert: "Clique para visualizar",
    doc_pend_rescue_title: "Cadastro incompleto",
    doc_pend_rescue_message:
      "Não é possível prosseguir com o resgate, pois há pendências de documentos. Corrija os itens a seguir e tente outra vez.",
    money_title: "Dinheiro",
    reward_opened_title: "Última solicitação realizada",
    description: "Escolha uma opção de resgate abaixo",
    items_search: "Pesquise por um produto {type}",
    select_u_option: "Valor máximo para resgate: <strong>{ value }</strong>",
    reward_option: "Resgatar { type }",
    reward_alert_modal_text:
      "Vocẽ está prestes a realizar um resgate em { type }, está certo de que deseja prosseguir com essa ação?",
  },
  awards: {
    title: "Meus Resgates",
    help: "Nesse painel você encontra o seu histórico de prêmios resgatados",
    show_voucher_code: "Visualizar código: ",
    show_card_code: "Nº do cartão: {value}",
    show_rastreamento_code: "Cód rastreamento: {value}",
    bonification_award: "Fique atento à data prevista de pagamento.",
    voucher_award: "Voucher { parent }",
    credit_used: "Créditos utilizados: { value }",
    finantial_value: "Valor de: { value }",
    infos: {
      get_date: "Solicitado em: { date }",
      prev_payment_date: "Data prevista de pagamento: { date }",
      payment_date: "Data de pagamento: { date }",
      free_date: "Liberado em: { date }",
      send_email: "E-mail de envio: { email }",
    },
    card_value: "Valor: { value }",
  },
  award_info: {
    deadline: "Prazo",
    validate: "Validade",
    description: "Descrição",
    use: "Utilização",
  },
  reward_item_more: {
    warning_invited_gift: "Giftcard será enviado para seu e-mail de cadastro",
    warning_invalid_email: "E-mail não confere!",
    warning_action:
      "Você não conseguirá desfazer essa ação, após clicar no Enviar",
    warning_gift_send: "O vale presente será enviado para o e-mail cadastral.",
    warning_voucher_acess:
      "Qualquer um com acesso ao seu e-mail, poderá usar o seu voucher.",
    warning_security_email_postal:
      "Garanta que o e-mail está correto e somente você tem acesso à sua caixa postal",
    warning_security_gift:
      "Mantenha o código do seu vale presente privado, não compartilhe com terceiros",
    type_agree: "Digite 'Aceito'",
    warning_agree_incorrect: "O termo 'Aceito' não está digitado corretamente!",
    value_name: "valor:",
    value: "R$ 30,00",
    fee: "Taxa:",
  },
  filter: "Filtro",
  no_categories: "Não há categorias para exibir.",
  credits_by_category: "Créditos ganhos por categoria",

  card_extract: {
    creadit: "Créditos",
    available: "disponíveis para resgate",
    to_expire: "a expirar",
    in_analysis: "em análise",
    total: "Total de",
  },

  no_gift: "Não há giftcards disponíveis.",
};
