import { default as faq } from "./pages/faq/services";
import { default as sales } from "./pages/sales/services";
import { default as commission } from "./pages/commission/services";
import { default as account } from "./pages/account/services";
import { default as notifications } from "./pages/notifications/services";
import { default as home } from "./pages/home/services";
import { default as me_store } from "./pages/me-store/services";
import { default as landing_page } from "./pages/landing-pages/services";
import { default as products } from "./pages/products/services";
import { default as components } from "./components/services";
import { default as my_points } from "./pages/my-points/services";
import { default as rescue_award } from "./pages/rescue-award/services";
import { default as hierarchy } from "./pages/hierarchy/services";
import { default as courses } from "./pages/courses/services";
import { default as course } from "./pages/course/services";
import { default as missions } from "./pages/missions/services";
import { default as my_lists } from "./pages/my-lists/services";
import { default as public_landingpage } from "./pages/public-landingpage/services";

/**
 * By exposing all services used in the module,
 * they are written by axios, responsible for the http control,
 * and are exposed to be used as metadata returning a promise
 */
export default {
  components,
  rescue_award,
  faq,
  landing_page,
  commission,
  account,
  notifications,
  products,
  me_store,
  my_points,
  home,
  hierarchy,
  sales,
  courses,
  course,
  missions,
  my_lists,
  public_landingpage,
};
