/**
 Export all getters from state
*/

import { baseURL } from "@/shared/http/api";

/**
 * @param products
 * @returns {*[]|*}
 */
export const getEcomm = ({ ecomm }) => {
  let data = Object.assign({}, ecomm);

  delete data.photos;
  delete data.params;

  return data;
};

/**
 * @param ecomm
 * @return {*}
 */
export const getPhotos = ({ ecomm }) => {
  return ecomm.photos || [];
};

export const getPhotoMaxUpload = () => 5;

/**
 * @param in_request
 */
export const isInRequest = ({ in_request }) => in_request;

/**
 * @param search_filter
 * @returns {boolean}
 */
export const isCanAddPhoto = ({ ecomm }) => {
  if (ecomm && ecomm.params && ecomm.params.can_add_photo) {
    return ecomm.params.can_add_photo.value.toString() === "1";
  }

  return false;
};

/**
 * @param ecomm
 * @returns {string|null}
 */
export const getHelpDescription = ({ ecomm }) => {
  if (ecomm && ecomm.params && ecomm.params.store_description_carousel) {
    return ecomm.params.store_description_carousel.value.toString();
  }

  return null;
};

/**
 * @param ecomm
 * @returns {string|null}
 */
export const getTitle = ({ ecomm }) => {
  if (ecomm && ecomm.params && ecomm.params.store_carousel_title) {
    return ecomm.params.store_carousel_title.value.toString();
  }

  return null;
};

/**
 * @param state
 * @param getters
 * @param rootState
 * @return {{headers: {Authorization: string}, method: string, url: string}}
 */
export const getImageUploadConf = (state, getters, rootState) => {
  return {
    headers: {
      Authorization: `Bearer ${rootState.auth.token}`,
    },
    url: `${baseURL}/api/v1/ecomm-store/photo`,
    method: "POST",
  };
};

/**
 * @param state
 */
export const getMeStoreParams = (state) => {
  return state.me_store_params || {};
};

/**
 * @param state
 */
export const getAssets = (state) => {
  return state.assets || {};
};
